import React, { useRef, useState } from "react";
import { Table, InputRef, Space, Input, Spin, Select, Flex } from "antd";
import type { ColumnsType } from "antd/es/table";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { ColumnType, FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { LoadingOutlined } from "@ant-design/icons";
import { IUserSelectorType } from "../../../../interfaces";
import { getInmateWalletsListData } from "../../../../redux/actions";

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
}

interface IWalletDataType {
  firstName: string;
  lastName: string;
  currency: string;
  amountSent: string;
  amountReceivedDetails: any;
  totalAmount: string;
  coupon: any;
  amount: string;
  inmate_code: string;
  first_name: string;
  last_name: string;
}

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const SuperAdminInmateWalletList = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [prisonName, setPrisonName] = useState("");

  const { getInmatesWalletsList, getPublicItem } = userSelector((user) => user);
  const publicItems = getPublicItem?.data?.data;
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns: ColumnsType<DataType> = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Code",
      dataIndex: "inmateCode",
      key: "inmateCode",
      ...getColumnSearchProps("inmateCode"),
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];

  const data: DataType[] = getInmatesWalletsList?.data?.data?.details?.map(
    (item: IWalletDataType) => {
      return {
        firstName: item?.first_name,
        lastName: item?.last_name,
        inmateCode: item?.inmate_code,
        totalAmount: `${item?.amount} ${item?.currency}`,
        coupon: item?.coupon,
      };
    }
  );

  const walletSummary = getInmatesWalletsList?.data?.data;

  const onSearch = (value: string) => {
    return value;
  };

  const onHandleChangeCorrectionalService = (value: string) =>
    setPrisonName(value);

  return (
    <>
      <div className="dashboard-container__box">
        <div className="user-account-tbl">
          <Flex
            align="center"
            justify="space-between"
            style={{
              width: "100%",
              background: "#fff",
              padding: "8px",
              borderBottom: "1px solid #d8d8d8",
            }}
          >
            <span className="bold-text">
              {getInmatesWalletsList?.data?.data?.facility}
            </span>
            <Flex gap="small" align="center">
              <Select
                showSearch
                placeholder="Select a correctional facility"
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) => {
                  const val: any = option?.label ?? "";
                  return val.toLowerCase().includes(input.toLowerCase());
                }}
                onChange={onHandleChangeCorrectionalService}
                options={publicItems?.facilities}
              />
              <Button
                ghost
                type="primary"
                loading={getInmatesWalletsList?.loading}
                icon={<SearchOutlined />}
                onClick={() => {
                  dispatch(
                    getInmateWalletsListData({
                      prison_name: prisonName,
                    })
                  );
                }}
              >
                Search
              </Button>
            </Flex>
          </Flex>
          {getInmatesWalletsList?.data?.length !== 0 ? (
            <>
              {getInmatesWalletsList?.data?.data?.details !== undefined ? (
                <>
                  <div
                    className="tbl-items"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <div className="tbl-items__col">
                      <span style={{ color: "#777677" }}>
                        Total wallets amount
                      </span>
                      <span
                        style={{
                          color: "#262D30",
                          marginTop: "10px",
                          fontSize: "20px",
                        }}
                      >
                        {walletSummary?.total_wallet_amount}{" "}
                        {walletSummary?.currency}
                      </span>
                    </div>
                    <div className="separator__col"></div>
                    <div className="tbl-items__col">
                      <span style={{ color: "#777677" }}>
                        Inmates with wallet
                      </span>
                      <span
                        style={{
                          color: "#262D30",
                          marginTop: "10px",
                          fontSize: "20px",
                        }}
                      >
                        {walletSummary?.inmate_with_wallet}
                      </span>
                    </div>
                  </div>
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={
                      getInmatesWalletsList?.data?.data?.details?.length >= 10
                        ? undefined
                        : false
                    }
                  />
                </>
              ) : (
                <div style={{ margin: "20% auto", height: "800px" }}>
                  <Spin
                    style={{ color: "#282936" }}
                    indicator={
                      <LoadingOutlined style={{ fontSize: 30 }} spin />
                    }
                  />
                </div>
              )}
            </>
          ) : (
            <div className="empty-list">
              <span className="empty-list__title">No results found</span>
              <span className="empty-list__subtitle">{`Try adjusting your search or filter to find what you're looking for.`}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SuperAdminInmateWalletList;
