/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex, Form, Modal, notification, Select } from "antd";
import React, { useEffect, useState } from "react";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import {
  clearRegisterUserStore,
  getCorrectionalServiceData,
  getFacilityUserData,
  getPublicItemsData,
  getUserDataInfo,
  registerUsers,
} from "../../../redux/actions";
import Input from "antd/es/input/Input";
import { roles } from "../../../constants/roles";
import { allGenders } from "../../../constants/gender";
import { countryCode } from "../../../constants/telco";
import {
  hasOnlyDigits,
  isFirstTwoZero,
  isFirstTwoZeroZero,
  nameHasSpecialChar,
  numberStartWithSeven,
} from "../../Appointment";
import { appName } from "../../../constants/defaultLabel";
import Toast from "components/common/Alert";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const CreateAccount: React.FC<any> = ({
  facilityId,
  setChangePage,
  open,
  onCloseModal,
}) => {
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState<any>();
  const [getUserRole, setGetUserRole] = useState<any>();
  const [api, contextHoler] = notification.useNotification();
  const [selectedGender, setSelectedGender] = useState<string | undefined>(
    undefined
  );

  const [form, setForm] = useState({
    email: "",
    username: "",
    phone_number: "",
    first_name: "",
    last_name: "",
    birth_date: "",
    role_id: "",
    is_admin: false,
    is_user: true,
    is_facility: false,
    prison_id: "",
    password: "",
  });

  const { registerUser, getUserData } = userSelector((user) => user);

  const onSubmit = () => {
    const userData = {
      email: form.email,
      username: form.username,
      phone_number: `${countryCode}${form.phone_number}`,
      first_name: form.first_name,
      last_name: form.last_name,
      gender: selectedGender,
      birth_date: "19",
      role_id: getUserRole,
      is_admin: false,
      is_user: true,
      is_facility: false,
      prison_id: facilityId,
      password: "",
      is_dfc: false,
      is_school: false,
      is_simpo: false,
    };
    if (form.email === "") {
      Toast(api, "Error", "Please provide email");
    } else if (selectedGender === "") {
      Toast(api, "Error", "Please select user gender.");
    } else if (!getUserRole) {
      Toast(api, "Error", "Please select user role.");
    } else if (nameHasSpecialChar(form.first_name)) {
      Toast(
        api,
        "Error",
        "The provided first name has some special characters."
      );
    } else if (nameHasSpecialChar(form.last_name)) {
      Toast(
        api,
        "Error",
        "The provided last name has some special characters."
      );
    } else if (!validateEmail(form.email) && form.email !== "") {
      Toast(api, "Error", "Please provide a valid email");
    } else if (form.phone_number === "") {
      Toast(api, "Error", "Please provide phone number");
    } else if (
      !hasOnlyDigits(form.phone_number) ||
      form.phone_number.length < 9 ||
      form.phone_number.length > 9 ||
      isFirstTwoZeroZero(form.phone_number) ||
      isFirstTwoZero(form.phone_number) ||
      !numberStartWithSeven(form?.phone_number)
    ) {
      Toast(api, "Error", "Please provide a valid phone number");
    } else {
      dispatch(registerUsers(userData));
    }
  };

  const onHandleChangeUserInfo = (event: any) => {
    const { name, value } = event.target;
    const list: any = { ...form };
    list[name] = value;
    setForm(list);
  };

  const onSearch = (value: string) => {
    return value;
  };

  const onHandleChangeUserRole = (value: string) => setGetUserRole(value);

  useEffect(() => {
    const newList: any = [];
    roles?.map((item: any) => {
      if (
        ["DDCF", "DCF"].includes(item.name) === true &&
        [1].includes(getUserData?.data?.data[0]?.role_id)
      ) {
        newList.push({
          label: item?.name,
          value: item?.rolesKey,
        });
      } else if (
        ["Super Admin", "Prison Admin", "DCF"].includes(item.name) !== true &&
        [1].includes(getUserData?.data?.data[0]?.role_id) === false
      ) {
        newList.push({
          label: item?.name,
          value: item?.rolesKey,
        });
      }
    });
    setUserRole(newList);
  }, []);

  useEffect(() => {
    if (registerUser.data?.data?.error) {
      Toast(api, "Error", registerUser.data?.data?.error?.description);
      dispatch(clearRegisterUserStore());
    }
  }, [registerUser.data?.data?.error]);

  useEffect(() => {
    if (registerUser.data?.data?.description) {
      Toast(api, "Success", registerUser.data?.data?.description);
      onCloseModal();
      dispatch(clearRegisterUserStore());
      setChangePage("default");
      dispatch(getUserDataInfo());
      dispatch(getCorrectionalServiceData());
      dispatch(getFacilityUserData({ facility_id: "" }));
    }
  }, [registerUser?.data]);

  useEffect(() => {
    dispatch(getPublicItemsData());
  }, [dispatch]);

  return (
    <Modal
      title="Register new user"
      open={open}
      onCancel={onCloseModal}
      footer={
        <Flex justify="space-between">
          <Button
            ghost
            danger
            onClick={() => onSubmit()}
            loading={registerUser?.loading}
            size="large"
          >
            Add user
          </Button>
          <Button
            type="primary"
            onClick={() => onSubmit()}
            loading={registerUser?.loading}
            size="large"
          >
            Add user
          </Button>
        </Flex>
      }
    >
      {contextHoler}
      <Flex vertical>
        <Form layout="vertical">
          <Form.Item label="First name">
            <Input
              size="large"
              value={form.first_name}
              placeholder="Enter first name"
              name="first_name"
              onChange={onHandleChangeUserInfo}
            />
          </Form.Item>
          <Form.Item label="Last name">
            <Input
              size="large"
              value={form.last_name}
              placeholder="Enter last name"
              name="last_name"
              onChange={onHandleChangeUserInfo}
            />
          </Form.Item>
          <Form.Item label="Username">
            <Input
              size="large"
              value={form.username}
              placeholder="Enter username"
              name="username"
              onChange={onHandleChangeUserInfo}
            />
          </Form.Item>

          <Form.Item label="Select gender">
            <Button.Group>
              {allGenders.map((gender) => (
                <Button
                  key={gender.value}
                  type={selectedGender === gender.value ? "primary" : "default"}
                  onClick={() => setSelectedGender(gender.value)}
                >
                  {gender.label}
                </Button>
              ))}
            </Button.Group>
          </Form.Item>

          <Form.Item label="Email">
            <Input
              type="email"
              size="large"
              value={form.email}
              placeholder={`text@${appName}.com`}
              name="email"
              onChange={onHandleChangeUserInfo}
            />
          </Form.Item>
          <Form.Item label="Phone number">
            <Input
              size="large"
              addonBefore={countryCode}
              value={form.phone_number}
              placeholder="78xxxxxxx"
              name="phone_number"
              onChange={onHandleChangeUserInfo}
            />
          </Form.Item>

          <Form.Item label="Select user's role">
            <Select
              showSearch
              placeholder="Select user's role"
              optionFilterProp="children"
              size="large"
              onSearch={onSearch}
              filterOption={(input, option) => {
                const val: any = option?.label ?? "";
                return val.toLowerCase().includes(input.toLowerCase());
              }}
              onChange={onHandleChangeUserRole}
              options={userRole}
            />
          </Form.Item>
        </Form>
      </Flex>
    </Modal>
  );
};

export default CreateAccount;
