import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import StaticNavBar from "../common/staticNavBar";
import SubNavBar from "../common/staticNavBar/subNavBar";
import "./index.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Modal, Spin } from "antd";

import { logoutData } from "../../redux/actions/auth/logout";

import {
  TransactionsReports,
  OrderOfficer,
  PurchaseOrdersDetails,
  ShopPurchaseOrdersDetails,
  ShopDeliveryNoteDetails,
  DeliveryNoteDetails,
  ShopDashboard,
  ProductTable,
  OrdersList,
  InmateWalletList,
  BulkSendingMoney,
  Facilities,
  AppointmentReport,
  FinanceReport,
  ConfirmVisit,
  CompletedVisit,
  RecordSaving,
  RecordTransactions,
  PendingApprovalFile,
  PendingApprovalSavingFile,
  BoundPhoneNumber,
  CallHistory,
  InvoiceOnPhoneCalls,
  InmatePhoneNumber,
  InmateCalls,
  BulkSavingDetails,
  BulkSaving,
  BulkTransactionDetails,
  SuperAdminInmatesList,
  SuperAdminInmateWalletList,
  SuperAdminBookingReport,
  SuperAdminTransactionsReport,
  SuperAdminDashboard,
  ActivityLog,
  ManageAccounts,
  VideoVisits,
  Settings,
  Dashboard,
  InmatesList,
  UsersAccounts,
  UserProfile,
  AppointmentList,
  ErrorPage,
} from "./collectComponents";

dayjs.extend(customParseFormat);
ChartJS.register(ArcElement, Tooltip, Legend);

export const ManageDates = class {
  currentDate = new Date();
  defaultStartedDate() {
    const currentMoth =
      this.currentDate.getMonth() < 9
        ? `0${this.currentDate.getMonth() + 1}`
        : `${this.currentDate.getMonth()}`;
    const date = `${this.currentDate.getFullYear()}-${currentMoth}-${this.currentDate.getDate()}`;
    const currDate = date;
    const dateParts = currDate.split("-");
    dateParts[0] = `${parseInt(dateParts[0], 10) - 1}`;
    return dateParts.join("-");
  }

  defaultEndDate() {
    return moment(this.currentDate).format("YYYY-MM-DD");
  }

  selectedStartedDate(selectedDate: any) {
    const date = selectedDate !== null ? selectedDate[0] : "";
    return moment(date).format("YYYY-MM-DD");
  }

  selectedEndDate(selectedDate: any) {
    const date = selectedDate !== null ? selectedDate[1] : "";
    return moment(date).format("YYYY-MM-DD");
  }
};

const DashboardPage: React.FC<any> = ({
  logout,
  loadingLogout,
  username,
  email,
  adminName,
  province,
  district,
  alertMessage,
  onHandleChangeDistrict,
  onHandleChangeEmail,
  onHandleChangeName,
  onHandleChangeProvince,
  onHandleChangeUsername,
  onHandleChangeUserRoleId,
  registerAdmin,
  loadingRegisterUser,
  roles,
  getUserData,
  getRegisteredInmates,
  registerInmate,
  registerUserData,
  onHandleChangeInmFirstName,
  onHandleChangeInmLastName,
  onHandleChangeInmFatherNames,
  onHandleChangeInmMotherNames,
  onHandleChangeInmJailedReason,
  onHandleChangeInmInmateCode,
  onHandleChangeInmBirthDate,
  onHandleChangeInmProvince,
  onHandleChangeInmDistrict,
  onHandleChangeInmAddress,
  onHandleChangeInmPrisonEntryDate,
  onHandleChangeInmPrisonId,
  inmFirstName,
  inmLastName,
  inmFatherNames,
  inmMotherNames,
  inmJailedReason,
  inmInmateCode,
  inmBirthDate,
  inmProvince,
  inmDistrict,
  inmAddress,
  inmPrisonEntryDate,
  prisonId,
  setInmPrisonId,
  loadingRegisterInmate,
  getRwandaProvinces,
  onHandleSelectDistrict,
  onHandleSelectProvince,
  districtsList,
  dispatch,
  navigate,
}) => {
  const { pathname } = location;
  const splitLocation: any = pathname.split("/");
  const [visitData] = useState<any>();

  useEffect(() => {
    visitData;
  }, []);
  const usersData = useMemo(() => getUserData?.data?.data?.[0], [getUserData]);
  const managePages = useCallback(() => {
    if (usersData?.role_id === undefined) {
      return (
        <div style={{ margin: "20% auto", height: "800px" }}>
          <Spin
            style={{ color: " #282936" }}
            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
          />
        </div>
      );
    }

    switch (splitLocation[1]) {
      case "transactions-report":
        if ([2, 7, 6].includes(usersData?.role_id)) {
          return <TransactionsReports />;
        }
        break;
      case "orders":
        if ([4, 5, 2, 7, 6, 3].includes(usersData?.role_id)) {
          return <OrderOfficer />;
        }
        break;
      case "purchase-order-details":
        if ([4, 5, 2, 7, 6, 3].includes(usersData?.role_id)) {
          return <PurchaseOrdersDetails />;
        }
        break;
      case "shop":
        if ([4, 5].includes(usersData.role_id)) {
          return <ShopDashboard />;
        }
        break;
      case "shop-products":
        if ([4, 5, 7].includes(usersData.role_id)) {
          return <ProductTable />;
        }
        break;
      case "shop-finance":
        if ([4].includes(usersData.role_id)) {
          return <ProductTable />;
        }
        break;
      case "shop-seller":
        if ([4, 5].includes(usersData.role_id)) {
          return <OrdersList />;
        }
        break;
      case "inmates-wallets":
        if ([2, 7, 3, 5].includes(usersData.role_id)) {
          return <InmateWalletList />;
        }
        break;
      case "out-going-money":
        if ([3, 5, 2, 7].includes(usersData.role_id)) {
          return <BulkSendingMoney />;
        }
        break;
      case "facilities":
        if ([1].includes(usersData.role_id)) {
          return <Facilities />;
        }
        break;
      case "appointments-reports":
        if ([2, 7, 6].includes(usersData.role_id)) {
          return <AppointmentReport />;
        }
        break;
      case "finance-report":
        if ([5, 3, 2, 7].includes(usersData.role_id)) {
          return <FinanceReport />;
        }
        break;
      case "confirm-visit":
        if ([6].includes(usersData.role_id)) {
          return <ConfirmVisit />;
        }
        break;
      case "completed-visits":
        if ([6].includes(usersData.role_id)) {
          return <CompletedVisit />;
        }
        break;
      case "record-savings":
        if ([5].includes(usersData.role_id)) {
          return <RecordSaving />;
        }
        break;
      case "record-transaction":
        if ([5].includes(usersData.role_id)) {
          return <RecordTransactions />;
        }
        break;
      case "pending-transaction-approval-request":
        if ([2, 3, 7].includes(usersData.role_id)) {
          return <PendingApprovalFile />;
        }
        break;
      case "pending-approval-request":
        if ([2, 3, 7].includes(usersData.role_id)) {
          return <PendingApprovalSavingFile />;
        }
        break;
      case "appointment":
        if ([2, 7, 5, 3].includes(usersData.role_id)) {
          return <AppointmentList />;
        }
        break;
      case "bound-phone-number":
        if ([2, 7, 5, 3].includes(usersData.role_id)) {
          return <BoundPhoneNumber />;
        }
        break;
      case "inmate-calls-history":
        if ([2, 7, 5, 3].includes(usersData.role_id)) {
          return <CallHistory />;
        }
        break;
      case "inmates-calls-invoices":
        if ([2, 7, 5, 3].includes(usersData.role_id)) {
          return <InvoiceOnPhoneCalls />;
        }
        break;
      case "inmate-phone-numbers":
        if ([2, 7, 5, 3].includes(usersData.role_id)) {
          return <InmatePhoneNumber />;
        }
        break;
      case "inmate-calls":
        if ([2, 7, 5, 3].includes(usersData.role_id)) {
          return <InmateCalls />;
        }
        break;
      case "bulk-saving-details":
        if ([2, 7, 5, 3].includes(usersData.role_id)) {
          return <BulkSavingDetails />;
        }
        break;
      case "bulk-saving":
        if ([2, 7, 5, 3].includes(usersData.role_id)) {
          return <BulkSaving />;
        }
        break;
      case "bulk-transaction-details":
        if ([2, 7, 5, 3].includes(usersData.role_id)) {
          return <BulkTransactionDetails />;
        }
        break;
      case "facility-inmates-list":
        if ([1].includes(usersData.role_id)) {
          return <SuperAdminInmatesList />;
        }
        break;
      case "facility-inmates-wallets":
        if ([1].includes(usersData.role_id)) {
          return <SuperAdminInmateWalletList />;
        }
        break;
      case "user-profile":
        if ([1, 2, 3, 4, 5, 6, 7, 8].includes(usersData.role_id)) {
          return <UserProfile />;
        }
        break;
      case "facility-appointments":
        if ([1].includes(usersData.role_id)) {
          return <SuperAdminBookingReport />;
        }
        break;
      case "facility-transactions":
        if ([1].includes(usersData.role_id)) {
          return <SuperAdminTransactionsReport />;
        }
        break;
      case "general-dashboard":
        if ([1].includes(usersData.role_id)) {
          return <SuperAdminDashboard />;
        }
        break;
      case "activity-log":
        if ([1].includes(usersData.role_id)) {
          return <ActivityLog />;
        }
        break;
      case "manage-accounts":
        if ([1, 2, 7].includes(usersData.role_id)) {
          return <ManageAccounts />;
        }
        break;
      case "video-visits":
        if ([2, 6, 7, 4, 5].includes(usersData.role_id)) {
          return <VideoVisits />;
        }
        break;
      case "settings":
        if ([1, 2, 7, 5, 4, 6, 3].includes(usersData.role_id)) {
          return <Settings />;
        }
        break;
      case "dashboard":
        if ([2, 6, 7, 4, 5].includes(usersData.role_id)) {
          return <Dashboard />;
        }
        break;
      case "shop-purchase-order-details":
        if ([4].includes(usersData.role_id)) {
          return <ShopPurchaseOrdersDetails />;
        }
        break;
      case "shop-delivery-note-details":
        if ([4].includes(usersData.role_id)) {
          return <ShopDeliveryNoteDetails />;
        }
        break;
      case "delivery-note-details":
        if ([5, 2, 7, 6, 3].includes(usersData.role_id)) {
          return <DeliveryNoteDetails />;
        }
        break;
      case "inmates-list":
        if ([2, 6, 7, 4, 5].includes(usersData.role_id)) {
          return (
            <InmatesList
              getRegisteredInmates={getRegisteredInmates}
              registerInmate={registerInmate}
              onHandleChangeInmFirstName={onHandleChangeInmFirstName}
              onHandleChangeInmLastName={onHandleChangeInmLastName}
              onHandleChangeInmFatherNames={onHandleChangeInmFatherNames}
              onHandleChangeInmMotherNames={onHandleChangeInmMotherNames}
              onHandleChangeInmJailedReason={onHandleChangeInmJailedReason}
              onHandleChangeInmInmateCode={onHandleChangeInmInmateCode}
              onHandleChangeInmBirthDate={onHandleChangeInmBirthDate}
              onHandleChangeInmProvince={onHandleChangeInmProvince}
              onHandleChangeInmDistrict={onHandleChangeInmDistrict}
              onHandleChangeInmAddress={onHandleChangeInmAddress}
              onHandleChangeInmPrisonEntryDate={
                onHandleChangeInmPrisonEntryDate
              }
              onHandleChangeInmPrisonId={onHandleChangeInmPrisonId}
              inmFirstName={inmFirstName}
              inmLastName={inmLastName}
              inmFatherNames={inmFatherNames}
              inmMotherNames={inmMotherNames}
              inmJailedReason={inmJailedReason}
              inmInmateCode={inmInmateCode}
              inmBirthDate={inmBirthDate}
              inmProvince={inmProvince}
              inmDistrict={inmDistrict}
              inmAddress={inmAddress}
              inmPrisonEntryDate={inmPrisonEntryDate}
              prisonId={prisonId}
              setInmPrisonId={setInmPrisonId}
              loadingRegisterInmate={loadingRegisterInmate}
              getRwandaProvinces={getRwandaProvinces}
              onHandleSelectDistrict={onHandleSelectDistrict}
              onHandleSelectProvince={onHandleSelectProvince}
              districtsList={districtsList}
            />
          );
        }
        break;
      case "users-accounts":
        if ([1, 2, 7].includes(usersData.role_id)) {
          return (
            <UsersAccounts
              username={username}
              adminName={adminName}
              email={email}
              province={province}
              district={district}
              alertMessage={alertMessage}
              onHandleChangeDistrict={onHandleChangeDistrict}
              onHandleChangeEmail={onHandleChangeEmail}
              onHandleChangeName={onHandleChangeName}
              onHandleChangeProvince={onHandleChangeProvince}
              onHandleChangeUsername={onHandleChangeUsername}
              onHandleChangeUserRoleId={onHandleChangeUserRoleId}
              registerAdmin={registerAdmin}
              loadingRegisterUser={loadingRegisterUser}
              roles={roles}
              getUserData={getUserData}
              registerUserData={registerUserData}
            />
          );
        }
        break;
      default:
        return <ErrorPage />;
    }
  }, [usersData, splitLocation]);

  const handleActivity = () => setLastActiveTime(Date.now());

  const [lastActiveTime, setLastActiveTime] = useState(Date.now());
  const [showWarningModal, setShowWarningModal] = useState(false);

  useEffect(() => {
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, []);

  const resetTimeout = () => {
    setLastActiveTime(Date.now());
    setShowWarningModal(false);
  };

  const isInactive = (timeoutDuration: number): boolean => {
    const now = Date.now();
    return now - lastActiveTime > timeoutDuration;
  };

  useEffect(() => {
    //! TO BE CHANGED
    const timeoutDuration: number = 1000 * 60 * 5;
    const alertTimeout: number = 1000 * 60;

    const handleTimeout = () => {
      if (isInactive(timeoutDuration)) {
        if (showWarningModal) {
          dispatch(logoutData());
        } else {
          setShowWarningModal(true);
          setTimeout(() => {
            if (showWarningModal) {
              setShowWarningModal(false);
              dispatch(logoutData());
            }
          }, alertTimeout);
        }
      }
    };

    const timeoutId = setTimeout(handleTimeout, timeoutDuration);

    return () => clearTimeout(timeoutId);
  }, [lastActiveTime, showWarningModal]);

  useEffect(() => {
    if (logout?.data?.data) {
      navigate("/admin");
      localStorage.removeItem("token");
      localStorage.removeItem("currentUser");
      setTimeout(function () {
        window.location.reload();
      });
    }
  }, [logout?.data?.data]);

  return (
    <>
      <div className="dashboard-container__row">
        <div className="dashboard-container__left">
          <div className="nav-bar__left">
            <SubNavBar
              userData={getUserData?.data?.data}
              logout={logout}
              loadingLogout={loadingLogout}
            />
          </div>
        </div>
        <div className="dashboard-container__right">
          <div className="dashboard-container__box">
            <StaticNavBar userData={getUserData?.data?.data} />
            {managePages()}
          </div>
        </div>
      </div>

      <Modal
        title={"Session Timeout Warning"}
        open={showWarningModal}
        closable={false}
        footer={[]}
      >
        <Divider />
        <h4>{`You've been inactive for a while. Session will time out in a few minutes.`}</h4>
        <Divider />
        <Flex justify="space-between">
          <Button type="primary" onClick={resetTimeout}>
            Stay Logged In
          </Button>
          <Button danger onClick={() => dispatch(logoutData())}>
            Log Out
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default DashboardPage;
