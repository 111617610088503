import { Button, Divider, Flex, Form, Modal, Select } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import {
  clearRegisterUserStore,
  getPublicItemsData,
  registerUsers,
} from "../../../redux/actions";
import Input from "antd/es/input/Input";
import { allGenders } from "../../../constants/gender";
import { countryCode } from "../../../constants/telco";
import { toast } from "react-toastify";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface ISuperAdminAddDP {
  facilityId: string | number;
  setChangePage: Dispatch<SetStateAction<string>>;
  open: boolean;
  onClose: any;
}

interface IForm {
  email: string;
  username: string;
  phoneNumber: string | number;
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  roleId: string | number;
  prisonId: string;
  password: string;
}

const SuperAdminAddDP: React.FC<ISuperAdminAddDP> = ({
  facilityId,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [userGender, setUserGender] = useState<string>("");
  const [form, setForm] = useState<IForm>({
    email: "",
    username: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: "",
    roleId: "",
    prisonId: "",
    password: "",
  });

  const {
    registerUser: { data, loading },
  } = userSelector((user) => user);

  const onSubmit = () => {
    const userData = {
      email: form.email,
      username: form.username,
      phone_number: `${countryCode}${form.phoneNumber}`,
      first_name: form.firstName,
      last_name: form.lastName,
      gender: userGender,
      birth_date: "",
      role_id: 7,
      is_admin: false,
      is_user: true,
      is_facility: false,
      prison_id: facilityId,
      password: "",
      is_dfc: true,
      is_school: false,
      is_simpo: false,
    };
    dispatch(registerUsers(userData));
  };

  const onHandleChangeUserInfo = (event: any) => {
    const { name, value } = event.target;
    const list: any = { ...form };
    list[name] = value;
    setForm(list);
  };

  const onSearch = (value: string) => {
    return value;
  };

  const onGenderChanges = (value: React.SetStateAction<string>) => {
    setUserGender(value);
  };

  useEffect(() => {
    if (data?.data?.error) {
      toast(data?.data?.error?.description);
      dispatch(clearRegisterUserStore());
    }
  }, [data?.data?.error]);

  useEffect(() => {
    if (data?.data?.description) {
      toast(data?.data?.description);
      dispatch(clearRegisterUserStore());
      onClose();
    }
  }, [data?.data]);

  useEffect(() => {
    dispatch(getPublicItemsData());
  }, [dispatch]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={
        <Flex justify="space-between">
          <Button ghost danger onClick={onClose}>
            Cancel
          </Button>
          <Button
            ghost
            type="primary"
            disabled={
              form.firstName === "" ||
              form.email === "" ||
              form.phoneNumber === "" ||
              form.lastName === "" ||
              form.username === "" ||
              userGender === ""
            }
            onClick={() => onSubmit()}
            loading={loading}
          >
            Add DCF
          </Button>
        </Flex>
      }
    >
      <Form layout="vertical">
        <Form.Item label="First name">
          <Input
            size="large"
            value={form.firstName}
            placeholder="Enter first name"
            name="firstName"
            onChange={onHandleChangeUserInfo}
          />
        </Form.Item>
        <Form.Item label="Last name">
          <Input
            size="large"
            value={form.lastName}
            placeholder="Enter last name"
            name="lastName"
            onChange={onHandleChangeUserInfo}
          />
        </Form.Item>
        <Form.Item label="Username">
          <Input
            size="large"
            value={form.username}
            placeholder="Enter username"
            name="username"
            onChange={onHandleChangeUserInfo}
          />
        </Form.Item>

        <Form.Item label="Select gender">
          <Select
            showSearch
            placeholder="Select gender"
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={(input, option) => {
              const val: string = option?.label ?? "";
              return val.toLowerCase().includes(input.toLowerCase());
            }}
            onChange={onGenderChanges}
            options={allGenders}
            size="large"
          />
        </Form.Item>

        <Form.Item label="Email">
          <Input
            size="large"
            value={form.email}
            placeholder="Enter phone number"
            name="email"
            onChange={onHandleChangeUserInfo}
          />
        </Form.Item>
        <Form.Item label="Phone number">
          <Input
            addonBefore={countryCode}
            size="large"
            value={form.phoneNumber}
            placeholder="Enter phone number"
            name="phoneNumber"
            onChange={onHandleChangeUserInfo}
          />
        </Form.Item>
        <Divider />
      </Form>
    </Modal>
  );
};

export default SuperAdminAddDP;
