/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Button,
  Tag,
  Spin,
  DrawerProps,
  RadioChangeEvent,
  Select,
  Flex,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import {
  getPrisonsData,
  getPublicItemsData,
  getSuperAdminInmateData,
  getUserDataInfo,
} from "../../../redux/actions";

import { Input, InputRef } from "antd";
import { ColumnType, FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { useRef } from "react";
import Highlighter from "react-highlight-words";
import { LoadingOutlined } from "@ant-design/icons";
import { INMATE_CRITICAL_STATUS } from "../../../constants/inamteStatus";
import CustomDrawer from "../../common/drawer";
import "../style.css";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface DataType {
  id: string;
  name: string;
  username: number;
  address: string;
  user_profiles: string[];
  userRoleId: number;
  gender: string;
  birthDate: string;
  status: any;
}

const SuperAdminInmatesList: React.FC<any> = () => {
  const dispatch = useDispatch();

  const { getInmateList, getUserData, getPublicItem } = userSelector(
    (user) => user
  );
  const [open, setOpen] = useState(false);
  const [prisonName, setPrisonName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [inmateData, setInmateData] = useState<any>();
  const [placement, setPlacement]: any =
    useState<DrawerProps["placement"]>("right");
  const searchInput = useRef<InputRef>(null);
  const publicItems = getPublicItem?.data?.data;

  const roleId =
    getUserData?.data?.data !== undefined
      ? getUserData?.data?.data[0].role_id
      : undefined;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "left",

      render: (_, data: any) => {
        return (
          <>
            {data?.status !== INMATE_CRITICAL_STATUS ? (
              <Tag color={"green"}>{"Normal"}</Tag>
            ) : (
              <Tag color={"red"}>{"Critical"}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",

      render: (text) => <a>{text}</a>,
      ...getColumnSearchProps("name"),
    },
    {
      title: "RSC case number",
      dataIndex: "inmate_code",
      key: "inmate_code",
      ...getColumnSearchProps("inmate_code"),
    },

    {
      title: "Father names",
      dataIndex: "father_name",
      key: "father_name",
    },
    {
      title: "Mother names",
      dataIndex: "mather_name",
      key: "mather_name",
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",

      render: (data) => {
        return (
          <Space size="middle">
            <a
              onClick={() => {
                setOpen(true);
                setInmateData(data);
              }}
            >
              Details
            </a>
          </Space>
        );
      },
    },
  ];

  const data: DataType[] = getInmateList?.data?.data?.details?.map(
    (item: any) => {
      return {
        birthDate: item?.birth_date,
        city: item?.city,
        district: item?.district,
        father_name: item?.father_name,
        name: `${item?.first_name} ${item?.last_name}`,
        gender: item?.gender === "F" ? "Female" : "Male",
        id: item?.id,
        inmate_code: item?.inmate_code,
        mather_name: item?.mather_name,
        province: item?.state,
        status: item?.status,
      };
    }
  );

  useEffect(() => {
    dispatch(getPrisonsData(`v2/admin/retrieve-prisons`));
  }, [dispatch, getInmateList?.data?.data]);

  useEffect(() => {
    dispatch(getUserDataInfo());
    dispatch(getPublicItemsData());
  }, [dispatch]);

  const inmateShotReport = getInmateList?.data?.data;

  const showDrawer = () => {
    setOpen(true);
  };

  const onChange = (e: RadioChangeEvent) => {
    setPlacement(e.target.value);
  };

  const onClose: any = () => {
    setOpen(false);
  };

  const onSearch = (value: string) => {
    return value;
  };

  const onHandleChangeCorrectionalService = (value: string) =>
    setPrisonName(value);

  return (
    <>
      <div className="user-account-tbl">
        {[1].includes(roleId) === true ? (
          <Flex
            align="center"
            justify="space-between"
            style={{ width: "100%", padding: "8px" }}
          >
            <span className="bold-text">
              {getInmateList?.data?.data?.facility}
            </span>

            <Flex gap="small" align="center">
              <Select
                showSearch
                placeholder="Select a correctional facility"
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) => {
                  const val: any = option?.label ?? "";
                  return val.toLowerCase().includes(input.toLowerCase());
                }}
                onChange={onHandleChangeCorrectionalService}
                options={publicItems?.facilities}
              />
              <Button
                ghost
                type="primary"
                loading={getInmateList?.loading}
                icon={<SearchOutlined />}
                onClick={() => {
                  dispatch(
                    getSuperAdminInmateData({
                      prison_name: prisonName,
                    })
                  );
                }}
              >
                Search
              </Button>
            </Flex>
          </Flex>
        ) : null}

        {data?.length !== 0 ? (
          <>
            <div className="tbl-items" style={{ justifyContent: "flex-start" }}>
              <div className="tbl-items__col">
                <span style={{ color: "#777677" }}>All inmates</span>
                <span
                  style={{
                    color: "#262D30",
                    marginTop: "10px",
                    fontSize: "16px",
                  }}
                >
                  {inmateShotReport?.all_inmates}
                </span>
              </div>
              <div className="separator__col"></div>
              <div className="tbl-items__col">
                <span style={{ color: "#777677" }}>
                  {inmateShotReport?.critical > 1
                    ? "Critical cases"
                    : "Critical case"}
                </span>
                <span
                  style={{
                    color: "#262D30",
                    marginTop: "10px",
                    fontSize: "16px",
                  }}
                >
                  {inmateShotReport?.critical}
                </span>
              </div>
              <div className="separator__col"></div>
              <div className="tbl-items__col">
                <span style={{ color: "#777677" }}>
                  {inmateShotReport?.normal > 1
                    ? "Normal cases"
                    : "Normal case"}
                </span>
                <span
                  style={{
                    color: "#262D30",
                    marginTop: "10px",
                    fontSize: "16px",
                  }}
                >
                  {inmateShotReport?.normal}
                </span>
              </div>
              <div className="separator__col"></div>
              <div className="tbl-items__col">
                <span style={{ color: "#777677" }}>
                  {inmateShotReport?.female > 1 ? "Females" : "Female"}
                </span>
                <span
                  style={{
                    color: "#262D30",
                    marginTop: "10px",
                    fontSize: "14px",
                  }}
                >
                  {inmateShotReport?.female}
                </span>
              </div>
              <div className="separator__col"></div>
              <div className="tbl-items__col">
                <span style={{ color: "#777677" }}>
                  {inmateShotReport?.male > 1 ? "Males" : "Male"}
                </span>
                <span
                  style={{
                    color: "#262D30",
                    marginTop: "10px",
                    fontSize: "14px",
                  }}
                >
                  {inmateShotReport?.male}
                </span>
              </div>
            </div>

            <Table
              size="small"
              columns={columns}
              dataSource={data}
              pagination={data?.length >= 30 ? undefined : false}
            />
          </>
        ) : (
          <div style={{ margin: "20% auto", height: "800px" }}>
            <Spin
              style={{ color: " #282936" }}
              indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
            />
          </div>
        )}
      </div>
      <CustomDrawer
        placement={placement}
        onChange={onChange}
        showDrawer={showDrawer}
        onClose={onClose}
        open={open}
        title={`${inmateData?.name}`}
        onSubmit={undefined}
        category={"details"}
        width={400}
      >
        <div className="show-details">
          <h4>Inmate info</h4>
          <div className="show-details__row">
            <span id="title">Names: </span>
            <span>{inmateData?.name}</span>
          </div>
          <div className="show-details__row">
            <span id="title">RSC case number: </span>
            <span>{inmateData?.inmate_code}</span>
          </div>
          <div className="show-details__row">
            <span id="title">Gender: </span>
            <span>{inmateData?.gender}</span>
          </div>
          <div className="show-details__row">
            <span id="title">Birth date:</span>
            <span>{inmateData?.birthDate}</span>
          </div>
          <h4>Parents info</h4>
          <div className="show-details__row">
            <span id="title">{`Father's name`}: </span>
            <span>{inmateData?.father_name}</span>
          </div>
          <div className="show-details__row">
            <span id="title">{`Mather's name`}: </span>
            <span>{inmateData?.mather_name}</span>
          </div>
          <h4>Address</h4>
          <div className="show-details__row">
            <span id="title">{`Province`}: </span>
            <span>{inmateData?.province}</span>
          </div>
          <div className="show-details__row">
            <span id="title">{`District`}: </span>
            <span>{inmateData?.district}</span>
          </div>
          <div className="show-details__row">
            <span id="title">{`Other address`}: </span>
            <span>{inmateData?.address}</span>
          </div>

          <h4>Inmate status</h4>
          <div className="show-details__row">
            <span id="title">Status: </span>
            <span>
              {" "}
              <>
                {inmateData?.status !== INMATE_CRITICAL_STATUS ? (
                  <Tag color={"green"}>{"Normal"}</Tag>
                ) : (
                  <Tag color={"red"}>{"Critical"}</Tag>
                )}
              </>
            </span>
          </div>

          <br />
        </div>
      </CustomDrawer>
    </>
  );
};

export default SuperAdminInmatesList;
