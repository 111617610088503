import { Button, Empty, Flex, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Table, { ColumnsType } from "antd/es/table";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import {
  getPublicItemsData,
  getTransactionReportData,
} from "../../../redux/actions";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const SenderTransactionsHistory: React.FC<any> = () => {
  const dispatch = useDispatch();
  const [prisonName, setPrisonName] = useState("");

  const {
    getTransactionsReport: { data, loading },
    getPublicItem,
  } = userSelector((user) => user);
  const publicItems = getPublicItem?.data?.data;

  const reportData = data?.data !== undefined ? data?.data : [];

  const senderReportData: any = reportData?.details?.map((item: any) => {
    return {
      name: `${item?.sender?.first_name} ${item?.sender?.last_name}`,
      sender_reference: item?.sender?.sender_contact,
      amount: item?.total_amount,
      inmate_number: item?.inmate_number,
      frequency: item?.frequency,
      inmateCode: item?.inmate_code,
      date: moment(`${item?.created_at}`).format("YYYY-MM-DD"),
    };
  });

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 150,
    },
    {
      title: "Contact",
      dataIndex: "sender_reference",
      key: "sender_reference",
      width: 150,
    },
    {
      title: "Total amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
    },
    {
      title: "Number of inmates",
      dataIndex: "inmate_number",
      key: "inmate_number",
      width: 150,
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      width: 150,
    },
  ];

  const onSearch = (value: string) => {
    return value;
  };
  const onHandleChangeCorrectionalService = (value: string) =>
    setPrisonName(value);

  useEffect(() => {
    dispatch(getPublicItemsData());
  }, [dispatch]);

  return (
    <Flex align="center" vertical>
      <Flex
        align="center"
        justify="flex-end"
        style={{
          width: "100%",
          background: "#fff",
          padding: "8px",
          borderBottom: "1px solid #d8d8d8",
        }}
      >
        <Flex gap="small" align="center">
          <Select
            showSearch
            placeholder="Select a correctional facility"
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={(input, option) => {
              const val: any = option?.label ?? "";
              return val.toLowerCase().includes(input.toLowerCase());
            }}
            onChange={onHandleChangeCorrectionalService}
            options={publicItems?.facilities}
            style={{ width: "200px", textAlign: "left" }}
          />
          <Button
            ghost
            type="primary"
            loading={loading}
            icon={<SearchOutlined />}
            onClick={() => {
              dispatch(
                getTransactionReportData({
                  prison_name: prisonName,
                })
              );
            }}
          >
            Search
          </Button>
        </Flex>
      </Flex>

      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20%" }}>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
            style={{ color: "#282936" }}
          />
        </div>
      ) : senderReportData?.length ? (
        <Table
          columns={columns}
          dataSource={senderReportData}
          pagination={data?.length > 14 ? undefined : false}
          style={{ width: "100%" }}
          size="small"
        />
      ) : (
        <Flex
          align="center"
          style={{ width: "100%", padding: "12px" }}
          vertical
        >
          <Empty
            description="No transactions to show yet"
            style={{ marginTop: "auto 10%" }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default SenderTransactionsHistory;
