/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import "./Appointments.css";
import {
  clearRequestAppointmentStore,
  requestAppointments,
} from "../../redux/actions/appointments/requestAppointment";
import {
  Select,
  Modal,
  Input,
  DatePicker,
  TimePicker,
  Button,
  Flex,
} from "antd";
import { useEffect } from "react";
import { getPrisonsData } from "../../redux/actions";
import { Link, useNavigate } from "react-router-dom";
import {
  appointmentCategory,
  appointmentTypes,
  genders,
} from "../../constants/appointmentType";
import { INCOMING_APPOINTMENT } from "../../constants/appointmentType";
import { ITariffTypes, tariffTypes } from "../../constants/tariffTypes";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { countryCode, telcoList } from "../../constants/telco";
import { IUserSelectorType } from "../../interfaces";
import Translate from "../../languages/Translate";
import { toast } from "react-toastify";
import {
  hasOnlyDigits,
  isFirstTwoZero,
  isFirstTwoZeroZero,
  nameHasSpecialChar,
} from ".";
import axios from "axios";
import { numberStartWithSeven } from "./index";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IForm {
  visitorFirst_name: string;
  visitor_last_name: string;
  visitor_gender: string;
  visitor_age: string;
  visitor_identification: string;
  other_document: string;
  visit_date: string;
  visit_time: string;
  payment_amount: string;
  specification: string;
  appointment_category: string;
  appointment_type: string;
  telco: string;
  sender_phone_number: string;
  relative_name: string;
  inmate_code: string;
  check: true;
  visitor_passport: string;
  user_origin: string;
}

const uploadDocument: string | undefined =
  process.env.REACT_APP_API_UPLOAD_OTHER_DOCUMENT_URL || "";

const VirtualAppointmentPage: React.FC<any> = ({ requestAppointmentData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState<IForm>({
    relative_name: "",
    inmate_code: "",
    check: true,
    visitorFirst_name: "",
    visitor_last_name: "",
    visitor_gender: "",
    visitor_age: "",
    visitor_identification: "",
    other_document: "",
    visit_date: "",
    visit_time: "",
    payment_amount: "",
    specification: "",
    appointment_category: "",
    appointment_type: "",
    telco: " ",
    sender_phone_number: "",
    visitor_passport: "",
    user_origin: "",
  });
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [appointmentType, setAppointType] = useState<string>("");
  const [newTariffAmount, setNewTariffAmount] = useState<ITariffTypes[]>([]);
  const [tariffAmount, setTariffAmount] = useState<any>("");
  const [selectTelco, setSelectTelco] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState<string | string[]>("");
  const [selectedTime, setSelectedTime] = useState<any>("");
  const [nationalIdCheck, setNationalIdCheck] = useState<
    "error" | "warning" | undefined
  >();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const onHandleChangeTariffAmount = (value: any) => {
    setTariffAmount(value);
  };
  const onHandleChangeGender = (value: any) => setGender(value);
  const onHandleChangeAppointmentType = (value: any) =>
    setAppointType(value?.value);

  const { requestAppointment } = userSelector((user) => user);

  const onHandleChangeInputValue = (event: any) => {
    const { name, value } = event.target;
    const list: any = { ...form };
    list[name] = value;
    setForm(list);
  };

  const Success = () => {
    Modal.success({
      content: "Please check your mobile phone to processed...",
    });
  };

  const submitRequestAppointment = () => {
    const age: number = parseInt(form.visitor_age);
    const getBirthYear = currentYear - age;
    const IDNumber = (numberString: any) => {
      const fourDigits = numberString.substring(1, 5);
      return fourDigits;
    };

    if (selectedFileName !== "" && fileUploaded === false) {
      toast.error("Please upload first the document selected");
    } else if (nationalIdCheck === "error") {
      toast.error("The National ID number must consist of exactly 16 digits");
    } else if (
      form.visitor_identification &&
      age >= 16 &&
      getBirthYear !== parseInt(IDNumber(form.visitor_identification))
    ) {
      toast.error(
        "The National ID provided does not match the information you provided"
      );
    } else if (nameHasSpecialChar(form.visitorFirst_name)) {
      toast.error("The provided first name has some special characters.");
    } else if (nameHasSpecialChar(form.visitor_last_name)) {
      toast.error("The provided last name has some special characters.");
    } else if (
      !hasOnlyDigits(form.sender_phone_number) ||
      form.sender_phone_number.length !== 9 ||
      isFirstTwoZeroZero(form.sender_phone_number) ||
      isFirstTwoZero(form.sender_phone_number) ||
      !numberStartWithSeven(form?.sender_phone_number)
    ) {
      toast.error("Incorrect phone number provided");
    } else {
      const appointmentData = {
        relative_name: form?.relative_name,
        inmate_code: form?.inmate_code,
        check: false,
        visitor_first_name: form.visitorFirst_name,
        visitor_last_name: form.visitor_last_name,
        visitor_gender: gender,
        visitor_age: form?.visitor_age,
        visitor_identification: form?.visitor_identification,
        other_document: form.other_document || selectedFileName,
        visit_date: selectedDate,
        visit_time: selectedTime,
        payment_amount: tariffAmount?.value,
        specification: INCOMING_APPOINTMENT,
        appointment_category: appointmentCategory[1].value,
        appointment_type: appointmentType,
        telco: selectTelco,
        sender_phone_number: `${countryCode}${form.sender_phone_number}`,
        visitor_passport: form?.visitor_passport,
        items_allowed_to_bring: [],
        user_origin: "",
      };

      dispatch(
        requestAppointments({
          data: [appointmentData],
          appointment_category: appointmentCategory[1].value,
        })
      );
    }
  };

  const onChangeTelco = (value: any) => {
    setSelectTelco(value);
  };

  const onHandleChangeVisitDate = ({
    dateString,
  }: {
    dateString: string | string[];
  }) => {
    setSelectedDate(dateString);
  };

  const onHandleChangeVisitTime = (time: string | string[]) => {
    setSelectedTime(time);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      setSelectedFileName(file.name);
    } else {
      setSelectedFileName("No file chosen");
    }
  };

  const handleClear = () => {
    setSelectedFile(null);
    setSelectedFileName("");
  };

  const otherDocument = form.visitor_passport;

  const handleUpload = async () => {
    if (!selectedFile) {
      console.log("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${uploadDocument}${otherDocument}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(response.data?.message);
      setFileUploaded(true);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const renderOption = (option: ITariffTypes) => (
    <div>
      <div style={{ fontWeight: "bold" }}>{option.label}</div>
      {option.key === "normal" && (
        <div style={{ fontSize: "12px", color: "#888" }}>
          {option.description}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (
      form.visitorFirst_name !== "" &&
      form.visitor_last_name !== "" &&
      form.sender_phone_number !== "" &&
      form.visitor_identification !== ""
    ) {
      setAlertMessage("");
    }
  }, [
    form.visitorFirst_name,
    form.visitor_last_name,
    form.sender_phone_number,
    form.visitor_identification,
  ]);

  useEffect(() => {
    if (
      (form?.visitor_identification !== "" &&
        form?.visitor_identification?.length < 16) ||
      form?.visitor_identification?.length > 16
    ) {
      setNationalIdCheck("error");
    } else {
      setNationalIdCheck(undefined);
    }
  }, [form.visitor_identification, form.visitor_passport]);

  useEffect(() => {
    dispatch(getPrisonsData(`v1/retrieve-prisons`));
  }, [dispatch]);

  useEffect(() => {
    setNewTariffAmount(() =>
      tariffTypes.filter((type: ITariffTypes) => type?.key === appointmentType)
    );
  }, [appointmentType]);

  useEffect(() => {
    if (requestAppointmentData?.data?.transactionMessage) {
      setTimeout(function () {
        Modal.warning({
          title: "",
          content: `${requestAppointmentData?.data?.transactionMessage}`,
        });
      });

      navigate("/");
      Success();
      setTimeout(function () {
        window.location.reload();
      }, 3500);
    }
  }, [requestAppointmentData?.data?.transactionMessage]);

  useEffect(() => {
    if (requestAppointment.data?.data?.error) {
      toast.error(requestAppointment.data?.data?.error?.description);
      dispatch(clearRequestAppointmentStore());
    }
  }, [requestAppointment.data?.data?.error]);

  useEffect(() => {
    if (requestAppointment.data?.data?.failed) {
      toast.error(requestAppointment.data?.data?.message);
      dispatch(clearRequestAppointmentStore());
    }
  }, [requestAppointment.data?.data?.failed]);

  useEffect(() => {
    if (requestAppointment.data?.data?.description) {
      toast.success(requestAppointment.data?.data?.description);
      navigate("/");
      dispatch(clearRequestAppointmentStore());
    }
  }, [requestAppointment.data?.data]);

  useEffect(() => {
    const age: number = parseInt(form.visitor_age);

    if (age < 16) {
      setForm((prev) => ({ ...prev, visitor_identification: "" }));
    }
  }, [form.visitor_age]);

  return (
    <>
      <div className="container-box__items">
        <div className="container-box__items-middle">
          <div className="appoint-item__contents">
            <span style={{ fontSize: "18px", fontWeight: "600" }}>
              {Translate("You are booking a Virtual Appointment")}
            </span>
            <br />
            <br />
            <div className="appoint-item__content-top">
              <Link to="/">{Translate("Go back")}</Link>
              <span></span>
            </div>
            {alertMessage !== undefined && alertMessage !== "" ? (
              <div className="alert-message">
                <span>{alertMessage}</span>
              </div>
            ) : null}

            <div className="appoint-item__content-top">
              <span style={{ fontSize: "18px" }}>
                {Translate("Inmate's information")}
              </span>
            </div>

            <div className="form__input">
              <span>
                {Translate(`Use inmate case number or parent's names`)}
                {"*"}{" "}
              </span>
              <Input
                type="text"
                placeholder={Translate(
                  "Enter inmate case number or parent names"
                )}
                value={form.inmate_code}
                name="inmate_code"
                onChange={onHandleChangeInputValue}
              />
            </div>

            <div className="form__input">
              <span>{Translate(`Inmate first name or last name`)}</span>
              <Input
                type="text"
                placeholder={Translate("Enter inmate first name or last name")}
                value={form.relative_name}
                name="relative_name"
                onChange={onHandleChangeInputValue}
              />
            </div>

            <div className="appoint-item__content-top">
              <span style={{ fontSize: "18px" }}>
                {Translate("Visitor's information")}
              </span>
            </div>

            <div className="form__input">
              <span>
                {Translate("First name")}
                {"*"}
              </span>
              <Input
                type="text"
                placeholder={Translate("Enter first name")}
                value={form.visitorFirst_name}
                name="visitorFirst_name"
                onChange={onHandleChangeInputValue}
              />
            </div>
            <div className="form__input">
              <span>
                {Translate("Last name")}
                {"*"}
              </span>
              <Input
                type="text"
                placeholder={Translate("Enter last name")}
                value={form.visitor_last_name}
                name="visitor_last_name"
                onChange={onHandleChangeInputValue}
              />
            </div>

            <div className="form__input-row">
              <div className="form__input">
                <span>
                  {Translate("Select your gender")}
                  {"*"}
                </span>
                <Select
                  size="large"
                  options={genders}
                  placeholder={Translate("Select gender")}
                  onChange={onHandleChangeGender}
                  style={{ textAlign: "left" }}
                />
              </div>
              <div className="form__input">
                <span>
                  {Translate("Your age")}
                  {"*"}
                </span>
                <Input
                  type="text"
                  placeholder={Translate("Enter your birth date")}
                  value={form.visitor_age}
                  name="visitor_age"
                  onChange={onHandleChangeInputValue}
                />
              </div>
            </div>

            <div className="form__input">
              <span>
                {Translate("National ID No.")}
                {"*"}
              </span>
              <Input
                type="text"
                status={nationalIdCheck}
                placeholder={Translate("Enter national ID number")}
                value={form.visitor_identification}
                name="visitor_identification"
                onChange={onHandleChangeInputValue}
                disabled={
                  form.visitor_passport !== "" ||
                  parseInt(form.visitor_age) < 16
                    ? true
                    : false
                }
              />
            </div>

            <div className="form__input">
              <span>
                {parseInt(form.visitor_age) < 16
                  ? Translate("Student card No / other document No")
                  : Translate("Passport No.")}
              </span>
              <Input
                type="text"
                disabled={form.visitor_identification !== "" ? true : false}
                placeholder={
                  parseInt(form.visitor_age) < 16
                    ? Translate(
                        "Enter your Student card No / other document No"
                      )
                    : Translate("Enter your passport No.")
                }
                value={form.visitor_passport}
                name="visitor_passport"
                onChange={onHandleChangeInputValue}
              />
            </div>

            {form.visitor_identification === "" ? (
              <div className="display-row-items-space-bt">
                <div className="form__input">
                  <span>{Translate(`Upload your document`)}</span>
                  <label htmlFor="file-upload" className="custom-file-upload">
                    {selectedFileName === "" ? (
                      <Input
                        id="file-upload"
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={handleFileChange}
                      />
                    ) : (
                      selectedFileName
                    )}
                  </label>
                </div>
                <div
                  className="widget-options-form__items"
                  style={{
                    marginTop: "10px",
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {selectedFile && (
                    <>
                      <Button onClick={handleClear} danger>
                        Clear
                      </Button>
                      <Button
                        onClick={handleUpload}
                        style={{
                          background: "#282936",
                          color: "#fff",
                          marginLeft: "5px",
                        }}
                      >
                        Upload
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ) : null}

            <Flex style={{ width: "100%" }} vertical>
              <div className="form__input" style={{ width: "100%" }}>
                <span>{Translate(`Visit type`)}</span>
                <Select
                  size="large"
                  options={appointmentTypes}
                  placeholder={Translate("Enter type")}
                  onChange={onHandleChangeAppointmentType}
                  style={{ textAlign: "left", width: "100%" }}
                  value={appointmentType}
                  optionLabelProp="label"
                  labelInValue
                  dropdownRender={(_menu) => (
                    <div
                      style={{
                        padding: "8px",
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}
                    >
                      {appointmentTypes.map((option, index) => (
                        <div
                          key={index}
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                            borderRadius: "4px",
                            transition: "background-color 0.2s",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor = "#f0f0f0")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "transparent")
                          }
                          onClick={() => onHandleChangeAppointmentType(option)} // Ensure the value is passed on click
                        >
                          <div style={{ fontWeight: "bold" }}>
                            {option.label}
                          </div>
                          <div style={{ fontSize: "12px", color: "#888" }}>
                            {option.description}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
              <div className="form__input">
                <span>{Translate(`Choose tariff`)}</span>
                <Select
                  disabled={!appointmentType}
                  size="large"
                  placeholder="Select tariff"
                  value={tariffAmount}
                  onChange={onHandleChangeTariffAmount}
                  optionLabelProp="label"
                  labelInValue
                  style={{ textAlign: "left", width: "100%" }}
                  dropdownRender={(_menu) => (
                    <div style={{ width: "100%" }}>
                      {newTariffAmount.map((option) => (
                        <div
                          key={option.value}
                          onClick={() => onHandleChangeTariffAmount(option)}
                          style={{
                            padding: "4px 4px",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          {renderOption(option)}
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
            </Flex>
            <div className="form__input-row">
              <div className="form__input">
                <span>
                  {Translate(`Select visit date`)}
                  {"*"}
                </span>
                <DatePicker
                  placeholder={Translate("Select date")}
                  onChange={(_date, dateString) =>
                    onHandleChangeVisitDate({ dateString: dateString })
                  }
                />
              </div>
              <div className="form__input">
                <span>
                  {Translate(`Select visit time`)}
                  {"*"}
                </span>
                <TimePicker
                  format="HH:mm"
                  placeholder={Translate("Select time")}
                  onChange={(_date, dateString) =>
                    onHandleChangeVisitTime(dateString)
                  }
                />
              </div>
            </div>
            <div className="form__input">
              <span>
                {Translate(`Select network operator`)}
                {"*"}
              </span>
              <Select
                size="large"
                options={telcoList}
                placeholder={Translate("Select network operator")}
                onChange={onChangeTelco}
                style={{ textAlign: "left", width: "100%" }}
              />
            </div>
            <div className="form__input">
              <span>{Translate("Phone number")}</span>
              <Input
                type="text"
                addonBefore={countryCode}
                placeholder={Translate("78xxxxxxxxx")}
                value={form.sender_phone_number}
                name="sender_phone_number"
                onChange={onHandleChangeInputValue}
              />
            </div>
            <div className="widget-options-form__items">
              <Button
                disabled={
                  form.inmate_code === "" ||
                  form.relative_name === "" ||
                  form.visitorFirst_name === "" ||
                  form.visitor_last_name === "" ||
                  form.visitor_age === "" ||
                  form.sender_phone_number === "" ||
                  tariffAmount === "" ||
                  appointmentType === "" ||
                  selectedDate === "" ||
                  selectedTime === "" ||
                  selectTelco === "" ||
                  gender === ""
                    ? true
                    : false
                }
                onClick={submitRequestAppointment}
                loading={requestAppointment.loading}
                style={{ background: "#282936", color: "#fff" }}
              >
                {Translate("Proceed")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VirtualAppointmentPage;
