import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import { Button, Empty, Flex, Select, Spin } from "antd";
import {
  getPublicItemsData,
  getTransactionReportData,
} from "../../../redux/actions";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const DetailsOnMoney = () => {
  const dispatch = useDispatch();
  const [prisonName, setPrisonName] = useState("");

  const { getTransactionsReport, getPublicItem } = userSelector((user) => user);
  const publicItems = getPublicItem?.data?.data;

  const columns: ColumnsType<any> = [
    {
      title: "Sender",
      dataIndex: "sender",
      key: "sender",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Receiver",
      dataIndex: "receiver",
      key: "receiver",
    },
    {
      title: "RSC Case number",
      dataIndex: "inmateCode",
      key: "inmateCode",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Received Amount",
      dataIndex: "incoming_amount",
      key: "incoming_amount",
    },
    {
      title: "Outgoing Amount",
      dataIndex: "outgoing_amount",
      key: "outgoing_amount",
    },
  ];

  const data: any = getTransactionsReport?.data?.data?.amount_consumption?.map(
    (item: any) => {
      return {
        sender: `${item?.sender_first_name} ${item?.sender_last_name}`,
        receiver: `${item?.first_name} ${item?.last_name}`,
        description: item?.description,
        contact: item?.sender_reference,
        inmateCode: item?.inmate_code,
        incoming_amount: `${item?.incoming_amount}`,
        outgoing_amount: `${item?.out_going_amount}`,
      };
    }
  );

  const onSearch = (value: string) => {
    return value;
  };
  const onHandleChangeCorrectionalService = (value: string) =>
    setPrisonName(value);

  useEffect(() => {
    dispatch(getPublicItemsData());
  }, [dispatch]);

  return (
    <Flex gap="middle" align="center" vertical>
      <Flex
        align="center"
        justify="flex-end"
        style={{
          width: "100%",
          background: "#fff",
          padding: "8px",
          borderBottom: "1px solid #d8d8d8",
        }}
      >
        <Flex gap="small" align="center">
          <Select
            showSearch
            placeholder="Select a correctional facility"
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={(input, option) => {
              const val: any = option?.label ?? "";
              return val.toLowerCase().includes(input.toLowerCase());
            }}
            onChange={onHandleChangeCorrectionalService}
            options={publicItems?.facilities}
            style={{ width: "200px", textAlign: "left" }}
          />
          <Button
            ghost
            type="primary"
            icon={<SearchOutlined />}
            loading={getTransactionsReport?.loading}
            onClick={() => {
              dispatch(
                getTransactionReportData({
                  prison_name: prisonName,
                })
              );
            }}
          >
            Search
          </Button>
        </Flex>
      </Flex>

      {getTransactionsReport?.loading ? (
        <div style={{ textAlign: "center", marginTop: "20%" }}>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
            style={{ color: "#282936" }}
          />
        </div>
      ) : data?.length ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={
            getTransactionsReport?.data?.data?.details?.length >= 10
              ? undefined
              : false
          }
          style={{ width: "100%" }}
          size="small"
        />
      ) : (
        <Flex
          align="center"
          style={{ width: "100%", padding: "12px" }}
          vertical
        >
          <Empty
            description="No amount to show yet"
            style={{ marginTop: "auto 10%" }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default DetailsOnMoney;
