import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import { Button, Flex, Select, Table } from "antd";
import moment from "moment";
import {
  fetchActivityLogData,
  IActivityLogPayload,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import NoResultFound from "../../common/noResultFound";
import { userActivityLogKey } from "../../../constants/roles";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const defaultPayloadData: IActivityLogPayload = {
  filter_action: false,
  activity_key: null,
};

const ActivityLog = () => {
  const dispatch = useDispatch();
  const [filterForm, setFilterForm] =
    useState<IActivityLogPayload>(defaultPayloadData);

  const {
    fetchActivityLog: {
      fetchActivityLog,
      fetchActivityLogError,
      loadGetActivityLog,
    },
  } = userSelector((user) => user);

  const onHandleInputChanges = (value: string) => {
    setFilterForm(() => ({ filter_action: true, activity_key: value }));
  };

  const onHandleFilter = () => dispatch(fetchActivityLogData(filterForm));

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Facility",
      dataIndex: "facility",
      key: "facility",
    },

    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
    {
      title: "Date and Time",
      dataIndex: "date",
      key: "date",
    },
  ];

  const tableData =
    fetchActivityLog?.data &&
    fetchActivityLog?.data.map(
      (
        item: {
          user_name: string;
          updated_at: moment.MomentInput;
          activity: string;
          actions: string;
          facility_name: string;
        },
        index: number
      ) => {
        return {
          key: index,
          user: item?.user_name,
          facility: item?.facility_name,
          date: moment(item?.updated_at).format("YYYY-MM-DD h:mm:ss"),
          activity: item?.activity,
          actions: item?.actions,
        };
      }
    );

  useEffect(() => {
    if (fetchActivityLogError) {
      toast.error(fetchActivityLogError);
    }
  }, [fetchActivityLogError]);

  useEffect(() => {
    dispatch(fetchActivityLogData(filterForm));
  }, [dispatch]);

  return (
    <div className="dashboard-container__box">
      <Flex gap="middle" vertical style={{ padding: "8px" }}>
        <Flex align="center" justify="space-between">
          <span className="bold-text">Users Activity Log</span>
          <Flex>
            <Select
              value={filterForm.activity_key}
              style={{ width: "200px", textAlign: "left", marginRight: "10px" }}
              options={userActivityLogKey}
              placeholder={"Select action to filter"}
              onChange={onHandleInputChanges}
            />
            <Button type="primary" ghost onClick={onHandleFilter}>
              <span>Display</span>
            </Button>
          </Flex>
        </Flex>
        {tableData === null || (tableData && tableData.length <= 0) ? (
          <NoResultFound />
        ) : null}
        {tableData && tableData.length > 0 && (
          <Table
            size="middle"
            loading={loadGetActivityLog}
            columns={columns}
            dataSource={tableData}
          />
        )}
      </Flex>
    </div>
  );
};

export default ActivityLog;
