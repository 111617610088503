/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  InputRef,
  Space,
  Input,
  Spin,
  DatePicker,
  DatePickerProps,
  Flex,
  Modal,
  Form,
  Empty,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "interfaces";
import { getInmateWalletsData } from "redux/actions/inmates/inmatesWallets";
import moment from "moment";
import { Button } from "antd";
import ReactToPrint from "react-to-print";
import { ColumnType, FilterConfirmProps } from "antd/es/table/interface";
import {
  SearchOutlined,
  ArrowLeftOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { getInmateWalletDetailsData, prepareCouponData } from "redux/actions";
import { ManageDates } from "../..";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import NoResultFound from "components/common/noResultFound";

import "./inmateWallets.css";
import UploadBulkAmounts from "../UploadBulkAmount";
import EmptyTable from "components/common/emptyTable";

dayjs.extend(customParseFormat);

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
}

interface IWalletDataType {
  id: string;
  firstName: string;
  lastName: string;
  currency: string;
  amountSent: string;
  amountReceivedDetails: any;
  totalAmount: string;
  coupon: any;
  amount: any;
  inmate_code: any;
  first_name: any;
  last_name: any;
}

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const InmateWalletList = () => {
  const dispatch = useDispatch();
  const couponRef = useRef(null);
  const [showPage, setShowPage] = useState("default");
  const [amountReceived, setAmountReceived]: any = useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [open, setOpen] = useState(false);
  const getDate = new ManageDates();
  const [selectedDate1, setSelectedDate1]: any = useState();
  const [selectedDate, setSelectedDate]: any = useState();
  const [loadingGenerateToken, setLoadingGenerateToken] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [openUploadBulkAmountModal, setOpenUploadBulkAmountModal] =
    useState<boolean>(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
    showSizeChanger: true,
  });
  const [filterPage, setFilterPage] = useState({
    current: 1,
    pageSize: 100,
  });

  const [form] = Form.useForm();
  const [nameFilter, setNameFilter] = useState("");
  const [inmateCodeFilter, setInmateCodeFilter] = useState("");

  const { getInmatesWallets, getInmatesWalletDetails, prepareCoupon } =
    userSelector((user) => user);

  const walletData =
    getInmatesWalletDetails?.data?.data !== 0
      ? getInmatesWalletDetails?.data?.data
      : [];

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const onSelectDate: DatePickerProps["onChange"] = (date, dateString) => {
    setSelectedDate({
      start_date: dateString,
      date: date,
    });
  };

  const onSelectEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    setSelectedDate1({
      date: date,
      end_date: dateString,
    });
  };

  const handleTableChange = (newPagination: any) => {
    setFilterPage({
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
  };

  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const tokenColumns: ColumnsType<any> = [
    {
      title: "Generated date",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Number",
      dataIndex: "file_name",
      key: "file_name",
      ...getColumnSearchProps("file_name"),
    },
    {
      title: "Started period",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Started period",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Actions",
      key: "Actions",
      render: (_, data: any) => {
        return (
          <>
            <a
              onClick={() => {
                showModal();
                getTokenDetails(data);
              }}
            >
              Details
            </a>
          </>
        );
      },
    },
  ];
  const columns: ColumnsType<DataType> = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Code",
      dataIndex: "inmateCode",
      key: "inmateCode",
      ...getColumnSearchProps("inmateCode"),
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Actions",
      key: "Actions",
      render: (_, data: any) => {
        return (
          <>
            <a
              onClick={() => {
                setAmountReceived(data);
                displayDetailsOnWallet(data);
                setShowPage("walletDetails");
              }}
            >
              Details
            </a>
          </>
        );
      },
    },
  ];

  const tokenListData = prepareCoupon?.data?.data?.token_List?.map(
    (item: any) => {
      return {
        createdAt: moment(item?.created_at).format("DD-MMM-YYYY"),
        end_date: item?.end_date,
        file_name: item?.file_name,
        id: item?.id,
        start_date: item?.start_date,
      };
    }
  );

  const dataDetails = walletData?.details?.map((item: any) => {
    return {
      date: moment(item?.created_at).format("DD-MMM-YYYY"),
      amountSent: `${item?.amountSent} Rwf`,
      description: item?.description,
      incomingAmount: `${item?.incoming_amount}  Rwf`,
      outGoingAmount: `${item?.out_going_amount} Rwf`,
      totalAmount: `${item?.total_amount}  Rwf`,
      createdAt: item?.created_at,
    };
  });

  const tokenDataDetails = prepareCoupon?.data?.data?.details?.map(
    (item: any) => {
      return {
        credit: item?.credit,
        debit: item?.debit,
        description: item?.description,
        id: item?.id,
      };
    }
  );

  const detailsOnDataColumns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Debit",
      dataIndex: "incomingAmount",
      key: "incomingAmount",
    },

    {
      title: "Credit",
      dataIndex: "outGoingAmount",
      key: "outGoingAmount",
    },
  ];

  const couponDataColumn: ColumnsType<any> = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
    },

    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
    },
  ];

  const data: DataType[] = getInmatesWallets?.data?.data?.details?.map(
    (item: IWalletDataType) => {
      return {
        inmateId: item?.id,
        firstName: item?.first_name,
        lastName: item?.last_name,
        inmateCode: item?.inmate_code,
        totalAmount: `${item?.amount} ${item?.currency}`,
        coupon: item?.coupon,
      };
    }
  );

  useEffect(() => {
    dispatch(
      getInmateWalletsData({
        name: nameFilter,
        inmate_code: inmateCodeFilter,
        page: 1,
        per_page: 100,
      })
    );
  }, [dispatch, nameFilter, inmateCodeFilter]);

  const onSearchFromBackend = () => {
    const values = form.getFieldsValue();
    setNameFilter(values.name || "");
    setInmateCodeFilter(values.inmateCode || "");
  };

  const onRefreshList = () => {
    dispatch(
      getInmateWalletsData({
        page: filterPage.current,
        per_page: filterPage.pageSize,
      })
    );
  };

  useEffect(() => {
    if (getInmatesWallets?.data?.data?.details) {
      const inmateData = getInmatesWallets?.data?.data?.details;
      setPagination({
        total: inmateData?.total_inmates,
        current: inmateData?.current_page,
        pageSize: inmateData?.per_page,
        showSizeChanger: true,
      });
    }
  }, [getInmatesWallets?.data?.data?.details]);

  useEffect(() => {
    dispatch(
      getInmateWalletsData({
        page: filterPage.current,
        per_page: filterPage.pageSize,
      })
    );
  }, [dispatch, filterPage]);

  const displayDetailsOnWallet = (data: any) => {
    dispatch(
      getInmateWalletDetailsData({
        start_date: getDate.defaultStartedDate(),
        end_date: getDate.defaultEndDate(),
        inmate_code: `${
          data?.inmateCode === undefined ? "" : data?.inmateCode
        }`,
      })
    );

    dispatch(
      prepareCouponData({
        inmate_id: amountReceived?.inmateId,
        start_date: getDate.defaultStartedDate(),
        end_date: getDate.defaultEndDate(),
      })
    );
  };

  const onSearch = () => {
    dispatch(
      getInmateWalletDetailsData({
        start_date: selectedDate.start_date,
        end_date: selectedDate1.end_date,
        inmate_code: `${amountReceived?.inmateCode}`,
      })
    );
    dispatch(
      prepareCouponData({
        start_date: selectedDate.start_date,
        end_date: selectedDate1.end_date,
        inmate_id: `${walletDetailsSummary?.id}`,
      })
    );
  };

  const walletSummary = getInmatesWallets?.data?.data;
  const walletDetailsSummary =
    getInmatesWalletDetails?.data?.data === undefined
      ? []
      : getInmatesWalletDetails?.data?.data;

  const getPrepareCoupon = prepareCoupon?.data?.data;

  const generateToken = () => {
    dispatch(
      prepareCouponData({
        inmate_id: walletDetailsSummary?.id,
        start_date: selectedDate.start_date,
        end_date: selectedDate1.end_date,
        generate_token: true,
        tokens: false,
        token_id: "",
      })
    );
    setLoadingGenerateToken(true);
  };

  useEffect(() => {
    if (loadingGenerateToken) {
      setIsLoading(prepareCoupon?.loading);
    }
  }, [loadingGenerateToken, prepareCoupon]);

  const allTokens = () => {
    dispatch(
      prepareCouponData({
        inmate_id: walletDetailsSummary?.id,
        start_date: getDate.defaultStartedDate(),
        end_date: getDate.defaultEndDate(),
        generate_token: false,
        tokens: true,
        token_id: null,
      })
    );
    setShowPage("tokens");
  };

  const getTokenDetails = (data: any) => {
    dispatch(
      prepareCouponData({
        inmate_id: walletDetailsSummary?.id,
        start_date: getDate.defaultStartedDate(),
        end_date: getDate.defaultEndDate(),
        generate_token: false,
        tokens: false,
        token_id: data?.id,
      })
    );
  };

  const customLoading = {
    spinning: getInmatesWallets?.loading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  };

  const LoadPrepareCoupon = {
    spinning: prepareCoupon?.loading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  };

  const customDataDetailsLoading = {
    spinning: getInmatesWalletDetails?.loading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  };

  const onHandleOpenUploadBulkAmount = () => setOpenUploadBulkAmountModal(true);
  const onHandleCloseUploadBulkAmount = () =>
    setOpenUploadBulkAmountModal(false);

  return (
    <>
      {showPage === "default" ? (
        <>
          <Flex style={{ width: "100%", height: "100vh" }}>
            {getInmatesWallets?.data?.data?.details !== undefined ? (
              <Flex vertical style={{ width: "100%", padding: "10px" }}>
                <Flex justify="space-between" align="center">
                  <Flex>
                    <div className="tbl-items__col">
                      <span style={{ color: "#777677" }}>
                        Total wallets amount
                      </span>
                      <span
                        style={{
                          color: "#262D30",
                          marginTop: "10px",
                          fontSize: "20px",
                        }}
                      >
                        {walletSummary?.total_wallet_amount}{" "}
                        {walletSummary?.currency}
                      </span>
                    </div>
                    <div className="separator__col"></div>
                    <div className="tbl-items__col">
                      <span style={{ color: "#777677" }}>
                        Inmates with wallet
                      </span>
                      <span
                        style={{
                          color: "#262D30",
                          marginTop: "10px",
                          fontSize: "20px",
                        }}
                      >
                        {walletSummary?.inmate_with_wallet}
                      </span>
                    </div>
                  </Flex>
                  <Flex gap="small">
                    <Flex>
                      <Form
                        form={form}
                        layout="inline"
                        onFinish={onSearchFromBackend}
                        style={{ width: "95%" }}
                      >
                        <Form.Item name="name">
                          <Input placeholder="Filter by name" />
                        </Form.Item>
                        <Form.Item name="inmateCode">
                          <Input placeholder="Filter by inmate code" />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            ghost
                            type="primary"
                            icon={<SearchOutlined />}
                            htmlType="submit"
                          />
                        </Form.Item>
                      </Form>
                      <Button
                        ghost
                        type="primary"
                        onClick={onRefreshList}
                        icon={<ReloadOutlined />}
                      />
                    </Flex>

                    <Button
                      ghost
                      type="primary"
                      onClick={onHandleOpenUploadBulkAmount}
                      icon={<UploadOutlined />}
                    >
                      Upload Bulk Amounts
                    </Button>
                  </Flex>
                </Flex>

                <Table
                  size="small"
                  columns={columns}
                  dataSource={data}
                  loading={customLoading}
                  rowKey={(record: any) => record.id}
                  pagination={
                    getInmatesWallets?.data?.data?.details?.length > 1
                      ? pagination
                      : false
                  }
                  onChange={handleTableChange}
                  locale={{
                    emptyText: (
                      <EmptyTable
                        title={"No inmate wallet yet"}
                        subTitle={"There is no inmate  wallet yet."}
                        Icon={<Empty />}
                        button={undefined}
                      />
                    ),
                  }}
                />
              </Flex>
            ) : (
              <div style={{ margin: "20% auto", height: "800px" }}>
                <Spin
                  style={{ color: "#282936" }}
                  indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                />
              </div>
            )}
          </Flex>
        </>
      ) : null}

      {showPage === "walletDetails" ? (
        <>
          <Flex style={{ width: "100%", height: "100vh" }} vertical>
            <Flex
              style={{ width: "100%", padding: "10px" }}
              justify="space-between"
            >
              <Button
                ghost
                danger
                icon={<ArrowLeftOutlined />}
                onClick={() => setShowPage("default")}
              >
                All wallets
              </Button>

              <Flex gap="small" justify="flex-end">
                <Flex gap="middle">
                  <DatePicker
                    placeholder="Start date"
                    suffixIcon={false}
                    onChange={onSelectDate}
                  />
                  <DatePicker
                    placeholder="End date"
                    onChange={onSelectEndDate}
                  />
                </Flex>
                <Button
                  ghost
                  type="primary"
                  loading={getInmatesWalletDetails?.loading}
                  onClick={onSearch}
                >
                  Search
                </Button>
                <Button
                  disabled={
                    selectedDate?.start_date === undefined &&
                    selectedDate1?.end_date === undefined
                      ? true
                      : false
                  }
                  loading={isLoading}
                  onClick={() => generateToken()}
                  ghost
                  type="primary"
                >
                  Generate coupon
                </Button>
                <Button ghost type="primary" onClick={allTokens}>
                  Coupons
                </Button>
              </Flex>
            </Flex>

            <div className="tbl-items" style={{ justifyContent: "flex-start" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "200px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <span>
                    <b>Name</b>{" "}
                  </span>
                  <span>
                    {amountReceived?.firstName} {amountReceived?.lastName}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <span>
                    <b>Case number</b>{" "}
                  </span>
                  <span>{amountReceived?.inmateCode}</span>
                </div>
              </div>
              <div className="separator__col"></div>
              <div className="tbl-items__col">
                <span style={{ color: "#777677" }}>Total wallet amount</span>
                <span
                  style={{
                    color: "#262D30",
                    marginTop: "10px",
                    fontSize: "20px",
                  }}
                >
                  {walletDetailsSummary.amount} {walletDetailsSummary?.currency}
                </span>
              </div>
              <div className="separator__col"></div>
              <div className="tbl-items__col">
                <span style={{ color: "#777677" }}>Total incoming amount</span>
                <span
                  style={{
                    color: "#262D30",
                    marginTop: "10px",
                    fontSize: "20px",
                  }}
                >
                  {walletDetailsSummary?.incoming_amount}{" "}
                  {walletDetailsSummary?.currency}
                </span>
              </div>
              <div className="separator__col"></div>
              <div className="tbl-items__col">
                <span style={{ color: "#777677" }}>Total outgoing amount</span>
                <span
                  style={{
                    color: "#262D30",
                    marginTop: "10px",
                    fontSize: "20px",
                  }}
                >
                  {walletDetailsSummary?.out_going_amount}{" "}
                  {walletDetailsSummary?.currency}
                </span>
              </div>
            </div>
            <Table
              size="small"
              columns={detailsOnDataColumns}
              dataSource={dataDetails}
              loading={customDataDetailsLoading}
              locale={{ emptyText: NoResultFound }}
              pagination={
                getInmatesWalletDetails?.data?.data?.details?.length >= 10
                  ? undefined
                  : false
              }
            />
          </Flex>
        </>
      ) : null}

      {showPage === "tokens" ? (
        <Flex
          vertical
          justify="flex-start"
          style={{ width: "100%", height: "100vh" }}
        >
          <Flex align="center" style={{ width: "100%", padding: "10px" }}>
            <Button
              ghost
              danger
              icon={<ArrowLeftOutlined />}
              onClick={() => setShowPage("walletDetails")}
            >
              Wallet Details
            </Button>
          </Flex>
          <Table
            size="small"
            columns={tokenColumns}
            dataSource={tokenListData}
            loading={LoadPrepareCoupon}
            locale={{ emptyText: NoResultFound }}
            pagination={
              getInmatesWalletDetails?.data?.data?.details?.length >= 10
                ? false
                : { pageSize: 10 }
            }
          />
        </Flex>
      ) : null}
      <Modal
        title={"Coupon"}
        open={open}
        onCancel={hideModal}
        footer={
          <div
            className="no-print"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            <ReactToPrint
              trigger={() => (
                <Button ghost type="primary">
                  Print Coupon
                </Button>
              )}
              content={() => couponRef.current}
            />
          </div>
        }
      >
        <div ref={couponRef} className="coupon-container">
          <div className="coupon-header">
            <span>RCS {getPrepareCoupon?.facility_name} Jeton</span>
            <p>
              From {getPrepareCoupon?.start_date} to{" "}
              {getPrepareCoupon?.end_date}
            </p>
          </div>

          <div className="coupon-item">
            <span>
              <b>Coupon/Jeton Number:</b>
            </span>
            <span>{getPrepareCoupon?.token_number}</span>
          </div>
          <div className="coupon-item">
            <span>
              <b>Inmate Names:</b>
            </span>
            <span>
              {getPrepareCoupon?.first_name} {getPrepareCoupon?.last_name}
            </span>
          </div>
          <div className="coupon-item">
            <span>
              <b>RCS Code:</b>
            </span>
            <span>{getPrepareCoupon?.inmate_code}</span>
          </div>
          <div className="coupon-item">
            <span>
              <b>Received Amount:</b>
            </span>
            <span>
              {walletDetailsSummary?.incoming_amount}{" "}
              {walletDetailsSummary?.currency}
            </span>
          </div>
          <div className="coupon-item">
            <span>
              <b>Amount Spent:</b>
            </span>
            <span>
              {walletDetailsSummary?.out_going_amount}{" "}
              {walletDetailsSummary?.currency}
            </span>
          </div>
          <div className="coupon-item">
            <span>
              <b>Total cost :</b>
            </span>
            <span className="coupon-balance">
              {walletDetailsSummary?.amount} {walletDetailsSummary?.currency}
            </span>
          </div>

          <Table
            className="coupon-table"
            bordered={false}
            columns={couponDataColumn}
            dataSource={tokenDataDetails}
            loading={LoadPrepareCoupon}
            locale={{ emptyText: NoResultFound }}
            pagination={false}
          />

          <div className="coupon-footer">
            <p>Thank you for using our services!</p>
          </div>
        </div>
      </Modal>
      <UploadBulkAmounts
        open={openUploadBulkAmountModal}
        onClose={onHandleCloseUploadBulkAmount}
      />
    </>
  );
};

export default InmateWalletList;
