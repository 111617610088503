import {
  GET_INMATES_WALLETS_FAIL,
  GET_INMATES_WALLETS_START,
  GET_INMATES_WALLETS_SUCCESS,
} from "../../../constants/actionType/inmates";

export interface IInmateWalletData {
  data: any;
  error: any;
}

export const getInmateWalletsData = (data: any) => ({
  type: GET_INMATES_WALLETS_START,
  data,
});

export const getInmatesWalletsSuccess = (data: IInmateWalletData) => ({
  type: GET_INMATES_WALLETS_SUCCESS,
  payload: data,
});

export const getInmatesWalletsFail = (error: IInmateWalletData) => ({
  type: GET_INMATES_WALLETS_FAIL,
  payload: error,
});
