import React from 'react';
import { Button, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Toast from 'components/common/Alert';

const downloadUrlTemplate = process.env.REACT_APP_API_AGORA_APP_DOWNLOAD_TEMPLATE;

const ExcelTemplateDownload = ({ downloadUrl }: any) => {
    const [api, contextHolder] = notification.useNotification();
    const handleDownload = async () => {
        try {
            const response = await fetch(`${downloadUrlTemplate}${downloadUrl}`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link: any = document.createElement('a');
            link.href = url;
            link?.setAttribute('download', 'inmate-template.xlsx');
            document?.body.appendChild(link);
            link?.click();
            link?.parentNode.removeChild(link);

            Toast(api, 'Success', 'File downloaded successfully');
        } catch (error) {
            Toast(api, 'Failed', 'Failed to download the file');
        }
    };

    return (
        <>
            {contextHolder}
            <Button type="link" icon={<DownloadOutlined />} onClick={handleDownload} style={{ width: '100%' }}>
                Download Excel Template
            </Button>
        </>
    );
};

export default ExcelTemplateDownload;
