/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, Flex, Form, Input, Modal } from "antd";
import { IUserSelectorType } from "interfaces";
import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearUpdateProduct,
  getProductData,
  updateProductData,
} from "redux/actions";
import { initialProductData } from "..";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const UpdateProduct: React.FC<{
  open: boolean;
  onClose: () => void;
  dataToUpdate: any;
}> = ({ open, onClose, dataToUpdate }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { updateProduct } = userSelector((user) => user);

  const onHandleSubmit = () => {
    const formData = form.getFieldsValue();
    const data = {
      id: dataToUpdate?.id,
      item: formData.article,
      unit_price: formData.price,
      quantity: formData.quantity,
    };
    dispatch(updateProductData(data));
  };

  useEffect(() => {
    if (updateProduct?.error) {
      toast.error(updateProduct?.message);
    }
    if (updateProduct?.data?.success) {
      toast.success(updateProduct?.message);
      dispatch(clearUpdateProduct());
      dispatch(getProductData(initialProductData));
      onClose();
      form.resetFields();
    }
  }, [updateProduct?.error, updateProduct?.data]);

  useEffect(() => {
    if (dataToUpdate) {
      form.setFieldsValue({
        article: dataToUpdate?.article,
        price: dataToUpdate?.price,
        quantity: dataToUpdate?.quantity,
      });
    }
  }, [dataToUpdate]);

  return (
    <Modal
      title="Update Product"
      open={open}
      onCancel={() => {
        onClose();
        dispatch(clearUpdateProduct());
        form.resetFields();
      }}
      footer={[]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="article"
          label="Product name"
          style={{ margin: "6px" }}
        >
          <Input type="text" placeholder="Enter product name" size="large" />
        </Form.Item>
        <Form.Item name="price" label="Price" style={{ margin: "6px" }}>
          <Input type="text" placeholder="Enter price" size="large" />
        </Form.Item>
        <Form.Item name="quantity" label="Quantity" style={{ margin: "6px" }}>
          <Input type="text" placeholder="Enter quantity" size="large" />
        </Form.Item>
        <Divider />
        <Form.Item>
          <Flex justify="space-between">
            <Button
              onClick={() => {
                onClose();
                form.resetFields();
              }}
              loading={updateProduct?.loading}
              type="primary"
              ghost
              danger
            >
              Cancel
            </Button>
            <Button
              onClick={onHandleSubmit}
              loading={updateProduct?.loading}
              type="primary"
              ghost
            >
              Update item
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateProduct;
