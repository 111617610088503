import React, { useState } from 'react';
import { Modal, Button, Input, List } from 'antd';
import { countries } from 'constants/countriesList';
// import './index.css';

const CountrySelector = ({ onSelectCountry, selectedCountry }: any) => {
    const [showModal, setShowModal] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(countries);

    const handleSearchChange = (e: { target: { value: string } }) => {
        const value = e.target.value.toLowerCase();
        setSearch(value);
        const filtered = countries.filter((country) => country.name.toLowerCase().includes(value));
        setFilteredCountries(filtered);
    };

    const handleCountrySelect = (country: { name: string; code: string }) => {
        onSelectCountry(country);
        setShowModal(false);
    };

    return (
        <div>
            <Button
                ghost
                type="primary"
                style={{ width: '95%', background: 'white' }}
                onClick={() => setShowModal(true)}
            >
                {selectedCountry ? `You're from ${selectedCountry?.name}` : 'Select your country of origin'}
            </Button>

            <Modal
                title="Select a Country"
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button key="close" onClick={() => setShowModal(false)}>
                        Close
                    </Button>,
                ]}
            >
                <Input
                    placeholder="Search country..."
                    value={search}
                    onChange={handleSearchChange}
                    allowClear
                    style={{ marginBottom: '20px' }}
                />

                <List
                    bordered
                    dataSource={filteredCountries}
                    renderItem={(country) => (
                        <List.Item onClick={() => handleCountrySelect(country)} style={{ cursor: 'pointer' }}>
                            {country.name} ({country.code})
                        </List.Item>
                    )}
                />
            </Modal>
        </div>
    );
};

export default CountrySelector;
