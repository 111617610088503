import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import { message } from "antd";
import { Button, Input } from "semantic-ui-react";
import {
  BsMicFill,
  BsFillMicMuteFill,
  BsCameraVideoFill,
  BsCameraVideoOffFill,
} from "react-icons/bs";
import Footer from "../common/footer";
import NavigationBar from "../common/navBar";
import "../loginPage/loginPage.css";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { checkVisitIDData, clearCheckVisitID } from "../../redux/actions";
import { IUserSelectorType } from "../../interfaces";
import Translate from "../../languages/Translate";

interface IPayload {
  visitId: string;
}

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const MeetingAccessPage: React.FC = () => {
  const dispatch = useDispatch();
  const webcam = useRef<Webcam>(null);
  const navigate = useNavigate();
  const [visitIdForm, setVisitIdForm] = useState<IPayload>({
    visitId: "",
  });

  const [mic, setMic] = useState<boolean>(false);
  const [camera, setCamera] = useState<string>("environment");

  const onHandleChangeInput = (e: { target: any }) => {
    const { name, value } = e.target;
    const data: any = { ...visitIdForm };
    data[name] = value;
    setVisitIdForm(data);
  };

  const { checkVisitId } = userSelector((state) => state);

  const onJoinMeeting = () => {
    if (visitIdForm.visitId === "") {
      message.error(
        Translate(
          "Please provide meeting ID you have been sent while booking your appointment."
        )
      );
    }
    dispatch(
      checkVisitIDData({ visit_id: visitIdForm.visitId, guest: "visitor" })
    );
  };

  useEffect(() => {
    if (checkVisitId?.data?.data?.success) {
      navigate("/start-meeting");
      localStorage.setItem("joinedAsVisitor", "YES");
    }

    if (checkVisitId?.data?.data?.error) {
      message.error(checkVisitId.data.data.message);
      dispatch(clearCheckVisitID());
    }
  }, [checkVisitId?.data]);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: { exact: camera },
  };

  return (
    <>
      <div className="container">
        <NavigationBar title={"Home"} />
        <div id="container-box" className="container-box">
          <div className="video-container-box__items">
            <div className="video-container__content">
              <span className="align-text-left">
                {Translate("Check your camera and microphone before starting")}
              </span>

              <div className="video-container__content-video">
                {camera === "environment" ? (
                  <div className="on-camera-off">
                    <i>
                      <BsCameraVideoOffFill size={50} />
                    </i>
                  </div>
                ) : (
                  <Webcam
                    audio={mic}
                    ref={webcam}
                    videoConstraints={videoConstraints}
                    className="landing-camera"
                  />
                )}
              </div>
              <div className="video-container-btn">
                {mic === false ? (
                  <Button className="v-round-btn" onClick={() => setMic(true)}>
                    {" "}
                    <BsFillMicMuteFill size={20} />{" "}
                  </Button>
                ) : (
                  <Button className="v-round-btn" onClick={() => setMic(false)}>
                    {" "}
                    <BsMicFill size={20} />{" "}
                  </Button>
                )}

                {camera === "user" ? (
                  <Button
                    className="v-round-btn"
                    onClick={() => setCamera("environment")}
                  >
                    <BsCameraVideoFill size={20} />
                  </Button>
                ) : (
                  <Button
                    className="v-round-btn"
                    onClick={() => setCamera("user")}
                  >
                    <BsCameraVideoOffFill size={20} />
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="video-item__contents">
            <div className="video-item__content-form">
              <span className="video-title">{Translate("Join meeting")}</span>
              <div className="video-form__input">
                <span>{Translate("Meeting ID")}</span>
                <Input
                  type="text"
                  value={visitIdForm.visitId}
                  name="visitId"
                  onChange={onHandleChangeInput}
                  placeholder={Translate("Enter meeting ID")}
                />
              </div>

              <div className="video-form__input">
                <Button
                  loading={checkVisitId.loading}
                  onClick={() => onJoinMeeting()}
                >
                  {Translate("Join")}
                </Button>
              </div>

              <div className="video-form__input">
                <Link to="/request-virtual-appointment">
                  {Translate("Book an appointment")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MeetingAccessPage;
