import { Button, Flex, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  clearRegisterInmateStore,
  registerInmateData,
} from "../../../redux/actions/inmates/registerInmates";
import { IUserSelectorType } from "../../../interfaces";
import {
  getInmateData,
  getPublicItemsData,
  getUserDataInfo,
} from "../../../redux/actions";
import { allGenders } from "../../../constants/gender";
import { hasNumber, nameHasSpecialChar } from "../../Appointment";
import { toast } from "react-toastify";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IForm {
  inmate_code: string;
  first_name: string;
  last_name: string;
  birth_date: string;
  gender: string;
  mather_name: string;
  father_name: string;
  state: string;
  city: string;
  district: string;
  other_address: string;
  prison_entry_date: string;
}

const RegisterNewInmate: React.FC<any> = ({ setChangePage, onClose, open }) => {
  const dispatch = useDispatch();
  const [getProvince, setGetProvince] = useState();
  const [getDistrict, setGetDistrict] = useState();
  const [districtsList, setDistrictList]: any = useState([]);
  const [gender, setGender]: any = useState();
  const [step, setStep] = useState(1);

  const [form, setForm] = useState<IForm>({
    inmate_code: "",
    first_name: "",
    last_name: "",
    birth_date: "",
    gender: "",
    mather_name: "",
    father_name: "",
    state: "",
    city: "",
    district: "",
    other_address: "",
    prison_entry_date: "",
  });

  const {
    getPublicItem: { data },
    getRegisteredInmate,
  } = userSelector((user) => user);

  const publicItems = data?.data;

  const onHandleValueFormChange = (event: any) => {
    const { name, value } = event.target;
    const list: any = { ...form };
    list[name] = value;
    setForm(list);
  };

  const onSearch = (value: string) => {
    return value;
  };

  const registerNewInmate = () => {
    const inmateInfo = {
      inmate_code: form.inmate_code,
      first_name: form.first_name,
      last_name: form.last_name,
      birth_date: form.birth_date,
      gender: gender || "",
      mother_name: form.mather_name,
      father_name: form.father_name,
      sector: getProvince || "",
      zone: form.city,
      cell: getDistrict || "",
      village: form.other_address,
      entry_date: form.prison_entry_date,
    };

    if (nameHasSpecialChar(form.first_name) || hasNumber(form.first_name)) {
      toast.error(
        "The provided Inmate first name has some special characters."
      );
    } else if (
      nameHasSpecialChar(form.last_name) ||
      hasNumber(form.last_name)
    ) {
      toast.error("The provided Inmate last name has some special characters.");
    } else if (
      nameHasSpecialChar(form.mather_name) ||
      hasNumber(form.mather_name)
    ) {
      toast.error(
        "The provided Inmate's mother's name has some special characters."
      );
    } else if (
      nameHasSpecialChar(form.father_name) ||
      hasNumber(form.father_name)
    ) {
      toast.error(
        "The provided Inmate's father's name has some special characters."
      );
    } else if (nameHasSpecialChar(form.city) || hasNumber(form.city)) {
      toast.error("The provided city name is not correct.");
    } else if (nameHasSpecialChar(form.inmate_code)) {
      toast.error("The provided Inmate code has some special characters.");
    } else {
      dispatch(registerInmateData({ data: [inmateInfo] }));
    }
  };

  const onHandleSelectProvince = (value: React.SetStateAction<undefined>) => {
    setGetProvince(value);
  };

  const onHandleChangeDistrict = (value: React.SetStateAction<undefined>) =>
    setGetDistrict(value);
  const onHandleChangeGender = (
    value: React.SetStateAction<undefined | string>
  ) => setGender(value);

  const proceedBtn = () => setStep(() => 2);
  const backBtn = () => setStep(() => 1);

  useEffect(() => {
    const newList: any = [];
    publicItems?.location?.map((item: any) => {
      if (getProvince === item?.value) {
        newList.push(item?.data);
      }
    });

    setDistrictList(...newList);
  }, [getProvince, districtsList]);

  useEffect(() => {
    if (getRegisteredInmate.data?.data?.success) {
      toast.success(getRegisteredInmate.data?.message);
      dispatch(clearRegisterInmateStore());
      setChangePage("default");
      dispatch(
        getInmateData({
          inmateWithWallet: "NO",
        })
      );
      dispatch(getUserDataInfo());
    }

    if ([500, 400].includes(getRegisteredInmate.data?.status)) {
      toast.error(getRegisteredInmate.data?.data.message);
      dispatch(clearRegisterInmateStore());
    }
  }, [getRegisteredInmate.data]);

  useEffect(() => {
    dispatch(getPublicItemsData());
  }, [dispatch]);

  return (
    <Modal
      title="Register new inmate"
      open={open}
      onCancel={onClose}
      style={{ marginTop: "-50px" }}
      footer={
        <Flex justify="space-between" style={{ width: "100%" }}>
          {step === 1 ? (
            <Form.Item style={{ width: "100%" }}>
              <Flex justify="space-between" align="center">
                <Button ghost danger onClick={onClose}>
                  <span>Close</span>
                </Button>
                <Button ghost type="primary" onClick={proceedBtn}>
                  <span>Proceed</span>
                </Button>
              </Flex>
            </Form.Item>
          ) : (
            <Form.Item style={{ width: "100%" }}>
              <Flex justify="space-between" align="center">
                <Button ghost danger onClick={backBtn}>
                  <span>Back</span>
                </Button>
                <Button
                  type="primary"
                  onClick={registerNewInmate}
                  loading={getRegisteredInmate.loading}
                >
                  Register new inmate
                </Button>
              </Flex>
            </Form.Item>
          )}
        </Flex>
      }
    >
      <Form layout="vertical" style={{ width: "100%" }}>
        {step === 1 ? (
          <Flex vertical style={{ width: "100%" }}>
            <Form.Item label="First name">
              <Input
                size="large"
                value={form.first_name}
                name="first_name"
                placeholder="Enter first name"
                onChange={onHandleValueFormChange}
              />
            </Form.Item>
            <Form.Item label="Last name">
              <Input
                size="large"
                value={form.last_name}
                name="last_name"
                onChange={onHandleValueFormChange}
                placeholder="Last name"
              />
            </Form.Item>
            <Form.Item label="Select gender">
              <Select
                showSearch
                size="large"
                placeholder="Select gender"
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) => {
                  const val: any = option?.label ?? "";
                  return val.toLowerCase().includes(input.toLowerCase());
                }}
                onChange={onHandleChangeGender}
                options={allGenders}
              />
            </Form.Item>
            <Form.Item label="Birth date">
              <Input
                size="large"
                type="date"
                value={form.birth_date}
                name="birth_date"
                onChange={onHandleValueFormChange}
                placeholder="Enter birth date"
              />
            </Form.Item>
            <Form.Item label="Case number">
              <Input
                size="large"
                placeholder="Enter case number"
                value={form.inmate_code}
                name="inmate_code"
                onChange={onHandleValueFormChange}
              />
            </Form.Item>

            <Form.Item label="Father's names">
              <Input
                size="large"
                placeholder="Enter father's names"
                value={form.father_name}
                name="father_name"
                onChange={onHandleValueFormChange}
              />
            </Form.Item>
          </Flex>
        ) : (
          <Flex vertical style={{ width: "100%" }}>
            <Form.Item label="Mother's names">
              <Input
                size="large"
                placeholder="Enter mother's names"
                value={form.mather_name}
                name="mather_name"
                onChange={onHandleValueFormChange}
              />
            </Form.Item>
            <Form.Item label="Select province">
              <Select
                showSearch
                size="large"
                placeholder="Select a province"
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) => {
                  const val: any = option?.label ?? "";
                  return val.toLowerCase().includes(input.toLowerCase());
                }}
                onChange={onHandleSelectProvince}
                options={publicItems?.location}
              />
            </Form.Item>
            <Form.Item label="Select district`">
              <Select
                showSearch
                size="large"
                placeholder="Select a district"
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={(input, option) => {
                  const val: any = option?.label ?? "";
                  return val.toLowerCase().includes(input.toLowerCase());
                }}
                onChange={onHandleChangeDistrict}
                options={districtsList}
              />
            </Form.Item>
            <Form.Item label="Clity">
              <Input
                size="large"
                placeholder="Enter city"
                value={form.city}
                name="city"
                onChange={onHandleValueFormChange}
              />
            </Form.Item>

            <Form.Item label="Address">
              <Input
                size="large"
                placeholder="Enter other address"
                value={form.other_address}
                name="other_address"
                onChange={onHandleValueFormChange}
              />
            </Form.Item>
            <Form.Item label="Prison entry date">
              <Input
                size="large"
                value={form.prison_entry_date}
                type="date"
                name="prison_entry_date"
                onChange={onHandleValueFormChange}
              />
            </Form.Item>
          </Flex>
        )}
      </Form>
    </Modal>
  );
};

export default RegisterNewInmate;
