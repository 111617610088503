import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../interfaces";
import { getGlobalSummaryData } from "../../redux/actions";
import {
  // HiOutlineArrowNarrowRight,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { Line } from "react-chartjs-2";
import { AiOutlineUngroup, AiOutlineGroup } from "react-icons/ai";
import { SlPeople } from "react-icons/sl";
import { GrVirtualMachine } from "react-icons/gr";
import { MdOutlinePendingActions } from "react-icons/md";
import { Link } from "react-router-dom";
import { Avatar, Flex } from "antd";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const {
    getGlobalSummary: { data },
  } = userSelector((state) => state);

  const globalSummaryData = data?.data;

  const chartData = {
    labels: [
      "Pending physical",
      "Completed physical",
      "Rejected physical",
      "Pending virtual",
      "Completed virtual",
      "Rejected virtual",
      "Approved virtual",
      "Approved physical",
    ],
    datasets: [
      {
        label: "Appointment",
        data: [
          globalSummaryData?.physical_appointment?.approved_appointments,
          globalSummaryData?.physical_appointment?.pending_appointments,
          globalSummaryData?.physical_appointment?.completed_appointments,
          globalSummaryData?.physical_appointment?.rejected_appointments,
          globalSummaryData?.virtual_appointment?.pending_appointments,
          globalSummaryData?.virtual_appointment?.completed_appointments,
          globalSummaryData?.virtual_appointment?.rejected_appointments,
          globalSummaryData?.virtual_appointment?.approved_appointments,
        ],
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Adjusted to a single value for Line chart
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        borderWidth: 2,
        tension: 0.4, // Adds curve to the line
        fill: false, // Avoid filling below the line
      },
    ],
  };

  useEffect(() => {
    dispatch(getGlobalSummaryData());
  }, []);
  return (
    <>
      <Flex gap="middle" vertical style={{ padding: "8px" }}>
        <Flex justify="space-between" gap="middle">
          <Link to="#">
            <div className="card-container">
              <div className="card-container__box">
                <div className="card-container_right-item">
                  <Avatar
                    size={40}
                    icon={<MdOutlinePendingActions />}
                    style={{ background: "#abf1ff" }}
                  />
                </div>
                <div className="card-container_left-item">
                  <Flex align="center">
                    <span>Pending appointments</span>
                  </Flex>
                  <h3>
                    {parseInt(
                      `${globalSummaryData?.physical_appointment?.pending_appointments}`
                    ) +
                      parseInt(
                        `${globalSummaryData?.virtual_appointment?.pending_appointments}`
                      )}
                  </h3>
                </div>
              </div>

              <div className="card-container__box-bottom">
                <div className="bottom-box__item">
                  <span className="box-title">Virtual</span>
                  <span className="box-value">
                    {
                      globalSummaryData?.virtual_appointment
                        ?.pending_appointments
                    }
                  </span>
                </div>
                <div className="bottom-box__item">
                  <span className="box-title">Physical</span>
                  <span className="box-value">
                    {
                      globalSummaryData?.physical_appointment
                        ?.pending_appointments
                    }
                  </span>
                </div>
              </div>
            </div>
          </Link>

          <Link to="#">
            <div className="card-container">
              <div className="card-container__box">
                <div className="card-container_right-item">
                  <Avatar
                    size={40}
                    icon={<SlPeople />}
                    style={{ background: "#99ffd3" }}
                  />
                </div>
                <div className="card-container_left-item">
                  <Flex align="center">
                    <span>Physical appointments</span>
                    <a></a>
                  </Flex>

                  <h3>{globalSummaryData?.physical_appointment?.number}</h3>
                </div>
              </div>

              <div className="card-container__box-bottom">
                <>
                  <div className="bottom-box__item">
                    <span className="box-title">Approved</span>
                    <span className="box-value">
                      {
                        globalSummaryData?.physical_appointment
                          ?.approved_appointments
                      }
                    </span>
                  </div>
                  <div className="bottom-box__item">
                    <span className="box-title">Completed</span>
                    <span className="box-value">
                      {
                        globalSummaryData?.physical_appointment
                          ?.completed_appointments
                      }
                    </span>
                  </div>

                  <div className="bottom-box__item">
                    <span className="box-title">Rejected</span>
                    <span className="box-value">
                      {
                        globalSummaryData?.physical_appointment
                          ?.rejected_appointments
                      }
                    </span>
                  </div>
                </>
              </div>
            </div>
          </Link>

          <Link to="#">
            <div className="card-container">
              <div className="card-container__box">
                <div className="card-container_right-item">
                  <Avatar
                    size={40}
                    icon={<GrVirtualMachine />}
                    style={{ background: "#ffadbe" }}
                  />
                </div>
                <div className="card-container_left-item">
                  <Flex align="center">
                    <span>Virtual appointments</span>
                    <a></a>
                  </Flex>

                  <h3>{globalSummaryData?.virtual_appointment?.number}</h3>
                </div>
              </div>

              <div className="card-container__box-bottom">
                <>
                  <div className="bottom-box__item">
                    <span className="box-title">Approved</span>
                    <span className="box-value">
                      {
                        globalSummaryData?.virtual_appointment
                          ?.pending_appointments
                      }
                    </span>
                  </div>
                  <div className="bottom-box__item">
                    <span className="box-title">Completed</span>
                    <span className="box-value">
                      {
                        globalSummaryData?.virtual_appointment
                          ?.completed_appointments
                      }
                    </span>
                  </div>

                  <div className="bottom-box__item">
                    <span className="box-title">Rejected</span>
                    <span className="box-value">
                      {
                        globalSummaryData?.virtual_appointment
                          ?.rejected_appointments
                      }
                    </span>
                  </div>
                </>
              </div>
            </div>
          </Link>
        </Flex>

        <Flex gap="middle">
          <div className="middle-items_right">
            <Line
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                  },
                },
              }}
            />
          </div>
          <Flex gap="small" className="middle-items_left-box">
            <div className="middle-items_left">
              <div className="middle-items_left-top">
                <div className="title-icon">
                  <AiOutlineUngroup size={30} />
                  <span>Outgoing Appointment</span>
                </div>
              </div>
              <div className="middle-items_left-bottom">
                <div id="items-rows">
                  <span className="telco-names">Today</span>
                  <span className="telco-amount">
                    {globalSummaryData?.outgoing}
                  </span>
                </div>
              </div>
            </div>

            <div className="middle-items_left">
              <div className="middle-items_left-top">
                <div className="title-icon">
                  <AiOutlineGroup size={30} />
                  <span>Incoming Appointment</span>
                </div>
              </div>
              <div className="middle-items_left-bottom">
                <div id="items-rows">
                  <span className="telco-names">Today</span>
                  <span className="telco-amount">
                    {globalSummaryData?.incoming}
                  </span>
                </div>
              </div>
            </div>
            <div className="middle-items_left">
              <div className="middle-items_left-top">
                <div className="title-icon">
                  <HiOutlineUserGroup size={30} />
                  <span>Total Inmates</span>
                </div>
              </div>
              <div className="middle-items_left-bottom">
                <div id="items-rows">
                  <span className="telco-names">Inmate number</span>
                  <span className="telco-amount">
                    {globalSummaryData?.inmate?.inmate_number}
                  </span>
                </div>
              </div>
            </div>

            <div className="middle-items_left">
              <div className="middle-items_left-top">
                <div className="title-icon">
                  <AiOutlineUngroup size={30} />
                  <span>Upcoming physical appointment</span>
                </div>
              </div>
              <div className="middle-items_left-bottom">
                <div id="items-rows">
                  <span className="telco-names">Total</span>
                  <span className="telco-amount">
                    {
                      globalSummaryData?.physical_appointment
                        ?.up_coming_appointment?.number
                    }
                  </span>
                </div>
              </div>
            </div>

            <div className="middle-items_left">
              <div className="middle-items_left-top">
                <div className="title-icon">
                  <AiOutlineUngroup size={30} />
                  <span>Upcoming virtual appointment</span>
                </div>
              </div>
              <div className="middle-items_left-bottom">
                <div id="items-rows">
                  <span className="telco-names">Total</span>
                  <span className="telco-amount">
                    {
                      globalSummaryData?.virtual_appointment
                        ?.up_coming_appointment?.number
                    }
                  </span>
                </div>
              </div>
            </div>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Dashboard;
