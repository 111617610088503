/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_INMATE_PHONE_NUMBER_SUCCESS,
  GET_INMATE_PHONE_NUMBER_FAIL,
  GET_INMATE_PHONE_NUMBER_START,
} from "constants/actionType/linkPhoneNumberToInmate";
import { fetchData } from "../../../helpers";

export interface ResponseGenerator {
  message: string;
}

function* inmatePhoneNumber(data: any) {
  try {
    const userData: ResponseGenerator = yield call(fetchData, data?.data);
    yield put({ type: GET_INMATE_PHONE_NUMBER_SUCCESS, data: userData });
  } catch (e: any) {
    yield put({ type: GET_INMATE_PHONE_NUMBER_FAIL, error: e.message });
  }
}

export default function* fetchInmatePhoneNumber() {
  yield takeEvery(GET_INMATE_PHONE_NUMBER_START, inmatePhoneNumber);
}
