/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import './Appointments.css';
import { LeftOutlined } from '@ant-design/icons';
import { clearRequestAppointmentStore, requestAppointments } from '../../redux/actions/appointments/requestAppointment';
import { Select, Input, DatePicker, TimePicker, Button, Flex, Tag } from 'antd';
import { useEffect } from 'react';
import { clearGetAvailableTimeStore, getAvailableTimeData, getPrisonsData } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import { appointmentCategory, appointmentTypes, genders } from '../../constants/appointmentType';
import { INCOMING_APPOINTMENT } from '../../constants/appointmentType';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { countryCode } from '../../constants/telco';
import { IUserSelectorType } from '../../interfaces';
import Translate from '../../languages/Translate';
import { toast } from 'react-toastify';
import axios from 'axios';
import AddItemAllowedTBring from './AddItemsAllowed';
import CountrySelector from 'components/CountrySelector';

const errorVisitorLimit = 'This time is unavailable, please choose another';
const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IForm {
    visitorFirst_name: string;
    visitor_last_name: string;
    visitor_gender: string;
    visitor_age: string;
    visitor_identification: string;
    other_document: string;
    visit_date: string;
    visit_time: string;
    payment_amount: string;
    specification: string;
    appointment_category: string;
    appointment_type: string;
    telco: string;
    sender_phone_number: string;
    relative_name: string;
    inmate_code: string;
    check: true;
    visitor_passport: string;
}

export function hasSpecialChars(str: string) {
    const regex = /[^a-zA-Z0-9]/;
    return regex.test(str);
}

export function hasOnlyDigits(str: string) {
    const regex = /^\d+$/;
    return regex.test(str);
}

export function isFirstTwoZeroZero(str: string): boolean {
    return str.substring(0, 2) === '00';
}

export function isFirstTwoZero(str: string): boolean {
    return str.substring(0, 1) === '0';
}

export function numberStartWithSeven(str: string): boolean {
    const number = /^7/.test(str);
    return number;
}

function isAlphanumeric(char: string): boolean {
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(char);
}

export function nameHasSpecialChar(str: string): boolean {
    const specialChars = "!@#$%^&*()-_=+[{]}:|;'<,>.?/\\";

    for (const char of str) {
        if (specialChars.includes(char) && !isAlphanumeric(char)) {
            return true;
        }
    }
    return false;
}

export function hasNumber(str: string): boolean {
    const regex = /\d/;
    return regex.test(str);
}

const uploadDocument: string | undefined = process.env.REACT_APP_API_UPLOAD_OTHER_DOCUMENT_URL || '';

const AppointmentPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<IForm>({
        relative_name: '',
        inmate_code: '',
        check: true,
        visitorFirst_name: '',
        visitor_last_name: '',
        visitor_gender: '',
        visitor_age: '',
        visitor_identification: '',
        other_document: '',
        visit_date: '',
        visit_time: '',
        payment_amount: '',
        specification: '',
        appointment_category: '',
        appointment_type: '',
        telco: ' ',
        sender_phone_number: '',
        visitor_passport: '',
    });
    const [openModel, setOpenModal] = useState<boolean>(false);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [appointmentType, setAppointType] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<string | string[]>('');
    const [selectedTime, setSelectedTime] = useState<any>('');
    const [nextStep, setNextStep] = useState<boolean | undefined>(false);
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [itemsSelected, setItemSelected] = useState<any>({
        items_allowed_to_bring: [],
    });
    const [nationalIdCheck, setNationalIdCheck] = useState<'error' | 'warning' | undefined>();

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const onHandleChangeGender = (value: string) => setGender(value);
    const onHandleChangeAppointmentType = (value: any) => setAppointType(value?.value);

    const { requestAppointment, getAvailableTime } = userSelector((user) => user);

    const onHandleChangeInputValue = (event: { target: { name: string; value: string | number } }) => {
        const { name, value } = event.target;
        const list: any = { ...form };
        list[name] = value;
        setForm(list);
    };

    const submitRequestAppointment = () => {
        const age: number = parseInt(form.visitor_age);
        const getBirthYear = currentYear - age;
        const IDNumber = (numberString: any) => {
            const fourDigits = numberString.substring(1, 5);
            return fourDigits;
        };

        if (selectedFileName !== '' && fileUploaded === false) {
            toast.error('Please upload first the document selected');
        } else if (nationalIdCheck === 'error' && form.visitor_passport === '') {
            toast.error('The National ID number must consist of exactly 16 digits');
        } else if (
            form.visitor_identification &&
            age >= 16 &&
            getBirthYear !== parseInt(IDNumber(form.visitor_identification))
        ) {
            toast.error('The National ID provided does not match the information you provided');
        } else if (nameHasSpecialChar(form.visitorFirst_name)) {
            toast.error('The provided first name has some special characters.');
        } else if (nameHasSpecialChar(form.visitor_last_name)) {
            toast.error('The provided last name has some special characters.');
        } else if (hasSpecialChars(form?.visitor_passport)) {
            toast.error('The passport number must not contain special characters');
        } else if (
            !hasOnlyDigits(form.sender_phone_number) ||
            form.sender_phone_number.length !== 9 ||
            isFirstTwoZeroZero(form.sender_phone_number) ||
            isFirstTwoZero(form.sender_phone_number) ||
            !numberStartWithSeven(form?.sender_phone_number)
        ) {
            toast.error('Incorrect phone number provided');
        } else {
            const appointmentData = {
                relative_name: form?.relative_name,
                inmate_code: form?.inmate_code,
                check: false,
                visitor_first_name: form.visitorFirst_name,
                visitor_last_name: form.visitor_last_name,
                visitor_gender: gender,
                visitor_age: form?.visitor_age,
                visitor_identification: parseInt(form.visitor_age) >= 16 ? form?.visitor_identification : 'No ID.',
                other_document: form.other_document || selectedFileName,
                visit_date: selectedDate,
                visit_time: selectedTime,
                payment_amount: '0',
                specification: INCOMING_APPOINTMENT,
                appointment_category: appointmentCategory[0].value,
                appointment_type: appointmentType,
                telco: '',
                sender_phone_number: `${countryCode}${form.sender_phone_number}`,
                visitor_passport: form?.visitor_passport,
                ...itemsSelected,
                user_origin: selectedCountry?.code || '',
            };

            dispatch(
                requestAppointments({ data: [appointmentData], appointment_category: appointmentCategory[0].value }),
            );
        }
    };

    const onHandleChangeVisitDate = ({ dateString }: { dateString: string | string[] }) => {
        setSelectedDate(dateString);
    };

    const onHandleChangeVisitTime = (time: string | string[]) => {
        setSelectedTime(time);
        setNextStep(false);
    };

    useEffect(() => {
        if (
            form.visitorFirst_name !== '' &&
            form.visitor_last_name !== '' &&
            form.sender_phone_number !== '' &&
            form.visitor_identification !== ''
        ) {
            setAlertMessage('');
        }
    }, [form.visitorFirst_name, form.visitor_last_name, form.sender_phone_number, form.visitor_identification]);

    useEffect(() => {
        if (
            (form?.visitor_identification !== '' && form?.visitor_identification?.length < 16) ||
            form?.visitor_identification?.length > 16
        ) {
            setNationalIdCheck('error');
        } else {
            setNationalIdCheck(undefined);
        }
    }, [form.visitor_identification, form.visitor_passport]);

    useEffect(() => {
        dispatch(getPrisonsData(`v1/retrieve-prisons`));
    }, [dispatch]);

    const disabledDate = (current: any) => {
        return (
            current < Date.now() ||
            ([1, 2, 3, 4, 5, 0].includes(new Date(current).getDay()) && appointmentType === 'normal')
        );
    };

    const disabledHours = () => {
        const hours = [];
        for (let i = 0; i < 9; i++) {
            hours.push(i);
        }
        for (let i = 17; i < 24; i++) {
            hours.push(i);
        }
        return hours;
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            setSelectedFileName(file.name);
        } else {
            setSelectedFileName('No file chosen');
        }
    };

    const handleClear = () => {
        setSelectedFile(null);
        setSelectedFileName('');
    };

    const handleCountrySelect = (country: { name: any; code: any }) => {
        setSelectedCountry(country);
    };

    const otherDocument = form.visitor_passport;

    const handleUpload = async () => {
        if (!selectedFile) {
            console.log('No file selected.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post(`${uploadDocument}${otherDocument}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(response.data?.message);
            setFileUploaded(true);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const onHandleOpenModal = () => setOpenModal(true);
    const onHandleCloseModal = () => setOpenModal(false);

    useEffect(() => {
        if (requestAppointment.data?.data?.error) {
            toast.error(requestAppointment.data?.data?.error?.description);
            dispatch(clearRequestAppointmentStore());
        }

        if (requestAppointment.data?.data?.failed) {
            toast.error(requestAppointment.data?.data?.message);
            dispatch(clearRequestAppointmentStore());
        }

        if (requestAppointment.data?.data?.description) {
            toast.success(requestAppointment.data?.data?.description);
            dispatch(clearRequestAppointmentStore());
            setNextStep(false);
            navigate('/');
            dispatch(clearGetAvailableTimeStore());
        }

        if (getAvailableTime.error) {
            toast.error(getAvailableTime?.message);
            dispatch(clearGetAvailableTimeStore());
            setNextStep(false);
        }
    }, [
        getAvailableTime,
        requestAppointment.data?.data?.error,
        requestAppointment.data?.data?.failed,
        requestAppointment.data?.data,
    ]);

    useEffect(() => {
        if (getAvailableTime?.data && !getAvailableTime?.error) {
            const data = getAvailableTime.data;

            if (!data.is_desk_available) {
                setNextStep(false);
                toast.error(errorVisitorLimit);
                dispatch(clearGetAvailableTimeStore());
            }

            if (data.is_desk_available) {
                onHandleSetNextStep();
            }
        }
    }, [getAvailableTime?.data]);

    const onAbortProcess = () => {
        setNextStep(false);
        dispatch(clearGetAvailableTimeStore());
    };

    const onHandleSetNextStep = () => {
        setNextStep(true);
    };
    const onCheckAvailableTime = () => {
        const visitDay: string | any = selectedDate;
        dispatch(
            getAvailableTimeData({
                inmate_info: form.inmate_code,
                visit_hour: selectedTime,
                visit_day: visitDay,
            }),
        );
    };

    const onBackBtn = () => {
        setNextStep(false);
        navigate('/');
        dispatch(clearGetAvailableTimeStore());
    };

    useEffect(() => {
        if (parseInt(form.visitor_age) < 16) {
            setForm((prev) => ({ ...prev, visitor_identification: '' }));
        }
    }, [form.visitor_age]);

    return (
        <>
            <div className="container-box__items">
                <div className="container-box__items-middle">
                    <div className="appoint-item__contents">
                        <Flex gap="middle" vertical>
                            <span style={{ fontSize: '18px', fontWeight: '600', float: 'left' }}>
                                {Translate('You are booking a physical Appointment')}
                            </span>

                            <div className="appoint-item__content-top">
                                <Button onClick={onBackBtn} icon={<LeftOutlined />}>
                                    {Translate('Go back')}
                                </Button>
                                <span>{Translate("Inmate's information")}</span>
                            </div>
                        </Flex>

                        {alertMessage !== undefined && alertMessage !== '' ? (
                            <div className="alert-message">
                                <span>{alertMessage}</span>
                            </div>
                        ) : null}

                        {!nextStep && (
                            <Flex vertical>
                                <CountrySelector
                                    onSelectCountry={handleCountrySelect}
                                    selectedCountry={selectedCountry}
                                />
                                <div className="form__input">
                                    <span>
                                        {Translate(`Use inmate case number or one of the parent's names`)}
                                        {'*'}
                                    </span>
                                    <Input
                                        type="text"
                                        placeholder={Translate('Enter inmate case number or parent names')}
                                        value={form.inmate_code}
                                        name="inmate_code"
                                        onChange={onHandleChangeInputValue}
                                    />
                                </div>

                                <div className="form__input">
                                    <span>
                                        {Translate(`Inmate first name or last name`)}
                                        {'*'}
                                    </span>
                                    <Input
                                        type="text"
                                        placeholder={Translate('Enter inmate first name or last name')}
                                        value={form.relative_name}
                                        name="relative_name"
                                        onChange={onHandleChangeInputValue}
                                    />
                                </div>

                                <Flex style={{ width: '100%' }} vertical>
                                    <div className="form__input" style={{ width: '100%' }}>
                                        <span>{Translate(`Visit type`)}</span>
                                        <Select
                                            size="large"
                                            options={appointmentTypes}
                                            placeholder={Translate('Enter type')}
                                            onChange={onHandleChangeAppointmentType}
                                            style={{ textAlign: 'left', width: '100%' }}
                                            value={appointmentType}
                                            optionLabelProp="label"
                                            labelInValue
                                            dropdownRender={(_menu) => (
                                                <div style={{ padding: '8px', maxHeight: '300px', overflowY: 'auto' }}>
                                                    {appointmentTypes.map((option, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                padding: '8px',
                                                                cursor: 'pointer',
                                                                borderRadius: '4px',
                                                                transition: 'background-color 0.2s',
                                                            }}
                                                            onMouseEnter={(e) =>
                                                                (e.currentTarget.style.backgroundColor = '#f0f0f0')
                                                            }
                                                            onMouseLeave={(e) =>
                                                                (e.currentTarget.style.backgroundColor = 'transparent')
                                                            }
                                                            onClick={() => onHandleChangeAppointmentType(option)}
                                                        >
                                                            <div style={{ fontWeight: 'bold' }}>{option.label}</div>
                                                            <div style={{ fontSize: '12px', color: '#888' }}>
                                                                {option.description}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        />
                                    </div>

                                    <div className="form__input" style={{ width: '100%' }}>
                                        <span>
                                            {Translate(`Select visit date`)}
                                            {'*'}
                                        </span>
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            onChange={(_date, dateString) =>
                                                onHandleChangeVisitDate({ dateString: dateString })
                                            }
                                            style={{ textAlign: 'left', width: '100%' }}
                                            size="large"
                                        />
                                    </div>

                                    <div className="form__input" style={{ width: '100%' }}>
                                        <span>
                                            {Translate(`Select visit time`)}
                                            {'*'}
                                        </span>
                                        <TimePicker
                                            format="HH:mm"
                                            disabledHours={disabledHours}
                                            minuteStep={15}
                                            hideDisabledOptions
                                            onChange={(_date, dateString) => onHandleChangeVisitTime(dateString)}
                                            style={{ textAlign: 'left', width: '100%' }}
                                            size="large"
                                        />
                                    </div>
                                </Flex>
                            </Flex>
                        )}

                        {nextStep && (
                            <Flex vertical>
                                <div className="appoint-item__content-top">
                                    <span style={{ fontSize: '18px' }}>{Translate("Visitor's information")}</span>
                                </div>

                                <Tag color="orange" style={{ marginLeft: '4px', width: '98%' }}>
                                    <Flex align="left" justify="flex-start" vertical>
                                        <span style={{ paddingLeft: '10px' }}>
                                            {Translate('Do you have any items to bring with you when you visit?')}
                                        </span>
                                        <Button
                                            style={{ width: '50%', padding: '0px' }}
                                            type="link"
                                            onClick={onHandleOpenModal}
                                        >
                                            {Translate('Please click here to add items')}
                                        </Button>
                                    </Flex>
                                </Tag>

                                <div className="form__input">
                                    <span>
                                        {Translate('First name')}
                                        {'*'}
                                    </span>
                                    <Input
                                        type="tex  t"
                                        placeholder={Translate('Enter first name')}
                                        value={form.visitorFirst_name}
                                        name="visitorFirst_name"
                                        onChange={onHandleChangeInputValue}
                                    />
                                </div>
                                <div className="form__input">
                                    <span>
                                        {Translate('Last name')}
                                        {'*'}
                                    </span>
                                    <Input
                                        type="text"
                                        placeholder={Translate('Enter last name')}
                                        value={form.visitor_last_name}
                                        name="visitor_last_name"
                                        onChange={onHandleChangeInputValue}
                                    />
                                </div>
                                <div className="form__input-row">
                                    <div className="form__input" style={{ width: '50%' }}>
                                        <span>
                                            {Translate('Select your gender')}
                                            {'*'}
                                        </span>
                                        <Select
                                            size="large"
                                            options={genders}
                                            placeholder={Translate('Select gender')}
                                            onChange={onHandleChangeGender}
                                            style={{ textAlign: 'left' }}
                                        />
                                    </div>
                                    <div className="form__input" style={{ width: '50%' }}>
                                        <span>
                                            {Translate('Your age')}
                                            {'*'}
                                        </span>
                                        <Input
                                            type="text"
                                            placeholder={Translate('Enter your age')}
                                            value={form.visitor_age}
                                            name="visitor_age"
                                            onChange={onHandleChangeInputValue}
                                        />
                                    </div>
                                </div>
                                <div className="form__input">
                                    <span>
                                        {Translate('National ID No.')}
                                        {'*'}
                                    </span>
                                    <Input
                                        type="text"
                                        status={nationalIdCheck}
                                        placeholder={Translate('Enter national ID number')}
                                        value={form.visitor_identification}
                                        disabled={
                                            form.visitor_passport !== '' || parseInt(form.visitor_age) < 16
                                                ? true
                                                : false
                                        }
                                        name="visitor_identification"
                                        onChange={onHandleChangeInputValue}
                                    />
                                </div>
                                <div className="form__input">
                                    <span>
                                        {parseInt(form.visitor_age) < 16
                                            ? Translate('Student card No / other document No')
                                            : Translate('Passport No.')}
                                    </span>
                                    <Input
                                        type="text"
                                        disabled={form.visitor_identification !== '' ? true : false}
                                        placeholder={
                                            parseInt(form.visitor_age) < 16
                                                ? Translate('Enter your Student card No / other document No')
                                                : Translate('Enter your passport No.')
                                        }
                                        value={form.visitor_passport}
                                        name="visitor_passport"
                                        onChange={onHandleChangeInputValue}
                                    />
                                </div>
                                {form.visitor_identification === '' ? (
                                    <div className="display-row-items-space-bt">
                                        <div className="form__input">
                                            <span>{Translate(`Upload your document`)}</span>
                                            <label htmlFor="file-upload" className="custom-file-upload">
                                                {selectedFileName === '' ? (
                                                    <Input
                                                        id="file-upload"
                                                        type="file"
                                                        accept=".png,.jpg,.jpeg,.pdf"
                                                        onChange={handleFileChange}
                                                    />
                                                ) : (
                                                    selectedFileName
                                                )}
                                            </label>
                                        </div>
                                        <div
                                            className="widget-options-form__items"
                                            style={{
                                                marginTop: '10px',
                                                alignSelf: 'center',
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            {selectedFile && (
                                                <>
                                                    <Button onClick={handleClear} danger>
                                                        Clear
                                                    </Button>
                                                    <Button
                                                        onClick={handleUpload}
                                                        style={{
                                                            background: '#282936',
                                                            color: '#fff',
                                                            marginLeft: '5px',
                                                        }}
                                                    >
                                                        Upload
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="form__input">
                                    <span>
                                        {Translate('Phone number')}
                                        {'*'}
                                    </span>
                                    <Input
                                        type="text"
                                        addonBefore={countryCode}
                                        placeholder={Translate('78xxxxxxxxx')}
                                        value={form.sender_phone_number}
                                        name="sender_phone_number"
                                        onChange={onHandleChangeInputValue}
                                    />
                                </div>
                            </Flex>
                        )}

                        <Flex justify="space-between" style={{ padding: '8px' }}>
                            <Button onClick={onAbortProcess} type="primary" ghost danger>
                                {Translate('Abort')}
                            </Button>
                            {!nextStep ? (
                                <Button
                                    disabled={
                                        form.inmate_code === '' ||
                                        appointmentType === '' ||
                                        selectedDate === '' ||
                                        selectedTime === ''
                                            ? true
                                            : false
                                    }
                                    loading={getAvailableTime?.loading}
                                    onClick={onCheckAvailableTime}
                                    type="primary"
                                    ghost
                                >
                                    {Translate('Next')}
                                </Button>
                            ) : (
                                <Button
                                    disabled={
                                        form.inmate_code === '' ||
                                        form.relative_name === '' ||
                                        form.visitorFirst_name === '' ||
                                        form.visitor_last_name === '' ||
                                        form.visitor_age === '' ||
                                        form.sender_phone_number === '' ||
                                        appointmentType === '' ||
                                        selectedDate === '' ||
                                        selectedTime === '' ||
                                        gender === ''
                                            ? true
                                            : false
                                    }
                                    loading={requestAppointment?.loading}
                                    onClick={submitRequestAppointment}
                                    type="primary"
                                    ghost
                                >
                                    {Translate('Proceed')}
                                </Button>
                            )}
                        </Flex>
                    </div>
                </div>
            </div>
            <AddItemAllowedTBring open={openModel} onClose={onHandleCloseModal} setItemSelected={setItemSelected} />
        </>
    );
};

export default AppointmentPage;
