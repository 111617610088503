import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Flex, Form, Input, Typography } from "antd";
import {
  UserOutlined,
  LockOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { generateRandomCode } from "utils/randomCode";
import { appName } from "../../constants/defaultLabel";
import "./loginPage.css";

const { Title, Text } = Typography;

interface LoginFormValues {
  email: string;
  password: string;
  otp?: string;
}

interface LoginPageProps {
  onSubmit: () => void;
  loading: boolean;
  form: LoginFormValues;
  onHandleInputChanges: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showOtp: boolean;
}

const LoginPage: React.FC<LoginPageProps> = ({
  onSubmit,
  loading,
  form,
  onHandleInputChanges,
  showOtp,
}) => {
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    const dataToSend = { changePassword: true, email: form.email };
    localStorage.setItem("isUpdateCredentials", generateRandomCode(10));
    navigate("/change-password", { state: dataToSend });
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <Title level={3} className="login-title">
          Login to your {appName} account
        </Title>
        <Form layout="vertical" className="login-form">
          <Form.Item
            label="Email/Phone number"
            rules={[
              {
                required: true,
                message: "Please input your email or phone number!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              value={form.email}
              name="email"
              onChange={onHandleInputChanges}
              placeholder="Enter email or phone number"
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              value={form.password}
              name="password"
              onChange={onHandleInputChanges}
              placeholder="Enter password"
              onPressEnter={onSubmit}
              size="large"
            />
          </Form.Item>

          {showOtp && (
            <Form.Item
              label="Provide OTP"
              rules={[{ required: true, message: "Please input the OTP!" }]}
            >
              <Input
                prefix={<SafetyCertificateOutlined />}
                value={form.otp}
                onChange={onHandleInputChanges}
                placeholder="Enter OTP"
                name="otp"
                size="large"
              />
            </Form.Item>
          )}

          <Flex justify="flex-end" className="forgot-password-container">
            <Button type="link" onClick={handleForgotPassword}>
              Forgot password?
            </Button>
          </Flex>

          <Form.Item>
            <Button type="primary" loading={loading} onClick={onSubmit} block>
              Login
            </Button>
          </Form.Item>
        </Form>

        <Flex justify="center" className="client-site-link">
          <Link to="/">
            <Text type="secondary">Visit our client site</Text>
          </Link>
        </Flex>
      </div>
    </div>
  );
};

export default LoginPage;
