/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Table } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import NoResultFound from "../../../common/noResultFound";

const AllProducts = ({
  data,
  columns,
  loadingData,
  productData,
  handleTableChange,
}: {
  data: any;
  columns: any;
  loadingData: boolean;
  productData: any;
  handleTableChange: any;
}) => {
  const customLoading = {
    spinning: loadingData,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  };
  return (
    <Flex style={{ height: "100vh" }}>
      <Table
        size="small"
        style={{ width: "100%" }}
        columns={columns}
        dataSource={data}
        loading={customLoading}
        locale={{ emptyText: NoResultFound }}
        pagination={{
          total: productData?.pagination?.total_items,
          pageSize: productData?.pagination?.per_page,
          current: productData?.pagination?.page,
          showSizeChanger: true,
          showTotal: (total: any, range: any) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
      />
    </Flex>
  );
};

export default AllProducts;
