/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Tabs,
  TabsProps,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../interfaces";
import { getOrderFileData, uploadDocumentData } from "../../redux/actions";
import OrderDetails from "./OrderDetails";
import MakeOrder from "./makeOrder";
import { getCommentData } from "../../redux/actions/orders/getComment";
import moment from "moment";
import {
  SearchOutlined,
  RedoOutlined,
  SettingOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import OrderFileUpload from "./OrderFileUpload";
import OrderFiles, { TableDataType } from "./OrderFiles";
import RenderActionIcons from "./RenderActionIcons";
import CreateOrderFileModal from "./CreateOrderFileModal";
import { DAILY_ORDER_FILE } from "../../constants/general";
import PurchaseOrderList from "./PurchaseOrders";
import FDeliveryNoteList from "./DeliveryNoteList";
import ManageZonesModal from "./ManageZones";
import axios from "axios";
import { token } from "../../middleware/authMiddleware";

const useTypedSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IDocument {
  name: string;
  file: string | number;
}

const OrderFileTypes = [
  {
    value: 3,
    label: "All",
  },
  {
    value: 1,
    label: "Shop order files",
  },
  {
    value: 2,
    label: "Food order files",
  },
];

const defaultFilterData = {
  label: "All",
  value: "all",
};

export const filterOrderFile = {
  file_name: "all",
  order_file_type: "all",
};

const OrderOfficer: React.FC = () => {
  const readDocument = process.env.REACT_APP_API_DOCUMENT_URL;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [changePage, setChangePage] = useState<string>("default");
  const [orderFileId, setOrderFileId] = useState<any>();
  const [stepper, setStepper] = useState<any>();
  const [actionType, setActionType] = useState<any>();
  const [currentUser, setCurrentUser] = useState<any>();
  const [open, setOpen] = useState(false);
  const [openUploadedDocument, setOpenUploadedDocument] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [uploadOrderFileModal, setUploadOrderFileModal] =
    useState<boolean>(false);
  const [document, setDocument] = useState<IDocument>({ name: "", file: "" });
  const [saveDocument, setSaveDocument] = useState<any>();
  const [openCreateOrderFile, setOpenCreateOrderFile] = useState(false);
  const { getOrderFile, getUserData, uploadDocument } = useTypedSelector(
    (state) => state
  );
  const [filterPayload, setFilterPayload] = useState(defaultFilterData.value);
  const [openManageZones, setOpenManageZones] = useState(false);
  const [filterType, setFilterType] = useState<string>();
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    dispatch(getOrderFileData(filterOrderFile));
    dispatch(getCommentData());
    setFilterType(`${OrderFileTypes[0]?.value}`);
  }, []);

  useEffect(() => {
    if (stepper || actionType) {
      dispatch(getOrderFileData(filterOrderFile));
      dispatch(getCommentData());
    }
  }, [dispatch, stepper, actionType]);

  const columns: ColumnsType<any> = [
    {
      title: "File name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "File type",
      dataIndex: "order_file_type",
      key: "order_file_type",
      render: (type: string) => (
        <Tag color={parseInt(type) === DAILY_ORDER_FILE ? "cyan" : "blue"}>
          {parseInt(type) === DAILY_ORDER_FILE
            ? "Food Order file"
            : "Shop Order file"}
        </Tag>
      ),
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt: string) => moment(createdAt).format("DD-MMM-YYYY"),
    },
    {
      title: "Status",
      dataIndex: "action_status",
      key: "action_status",
      render: (tag: string) => (
        <>
          {tag === "pending" ? (
            <Tag color={"green"}>{tag.toLocaleUpperCase()}</Tag>
          ) : null}
          {tag === "approved" ? (
            <Tag color={"blue"}>{"Booked".toLocaleUpperCase()}</Tag>
          ) : null}
          {tag === "rejected" ? (
            <Tag color={"red"}>{tag.toLocaleUpperCase()}</Tag>
          ) : null}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      width: "300px",
      render: (_, data) => {
        const userRoleId = getUserData?.data?.data[0]?.role_id;
        return (
          <RenderActionIcons
            data={data}
            userRoleId={userRoleId}
            setDocument={setDocument}
            setOrderFileId={setOrderFileId}
            setChangePage={setChangePage}
            setStepper={setStepper}
            setActionType={setActionType}
            setCurrentUser={setCurrentUser}
            onModelDisplayImg={() => setOpen(true)}
            onModalUploadedDocument={() => setOpenUploadedDocument(true)}
          />
        );
      },
    },
  ];

  const newDataCol = getOrderFile?.data?.map(
    (item: TableDataType, index: number) => ({
      key: index,
      id: item.id,
      file_name: item.file_name,
      created_at: item.created_at,
      current_user_role: item.current_user_role,
      steps: item.steps,
      document: item.document,
      order_file_type: item.order_file_type,
      action_status: item.actions_status,
    })
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetFile: any = event.target.files?.[0];
    const reader = new FileReader();

    reader.onload = () => setFile(reader.result);
    reader.readAsDataURL(targetFile);

    const formData = new FormData();
    formData.append("file", targetFile, targetFile.name);
    setSaveDocument(formData);
  };

  const onSaveDocument = () => {
    dispatch(uploadDocumentData({ file: saveDocument, orderFileId }));
  };

  const onHandleOpenCreateOrderFile = () => setOpenCreateOrderFile(true);
  const onHandleCloseCreateOrderFile = () => setOpenCreateOrderFile(false);
  const refreshTable = () => dispatch(getOrderFileData(filterOrderFile));

  const onHandleFilterFileName = (value: string) => {
    setFilterPayload(value);
  };

  const onHandleFilterType = (value: string) => {
    setFilterType(value);
  };

  const onHandleSearch = () => {
    const formData = form.getFieldsValue();
    dispatch(dispatch(getOrderFileData(formData)));
  };

  const onHandleCloseManageZoneModal = () => {
    setOpenManageZones(false);
  };

  const onHandleOpenManageZoneModal = () => {
    setOpenManageZones(true);
  };

  const filterOrderFileData = [
    {
      key: 0,
      label: "All",
      value: "all",
    },
    ...(newDataCol?.map(
      (item: { file_name: string; id: number }, index: number) => ({
        key: index + 1,
        label: item.file_name,
        value: item.id,
      })
    ) ?? []),
  ];

  const OperationsSlot = getUserData?.data?.data[0]?.role_id === 6 && (
    <Flex gap="small">
      <Form form={form} onFinish={onHandleSearch} layout="horizontal">
        <Space.Compact>
          <Form.Item
            name="file_name"
            noStyle
            rules={[{ required: true, message: "File name is required" }]}
          >
            <Select
              showSearch
              style={{ width: "28vh", textAlign: "left" }}
              placeholder="Select an order file type"
              optionFilterProp="label"
              size="middle"
              defaultValue={filterPayload}
              options={filterOrderFileData}
              onChange={onHandleFilterFileName}
            />
          </Form.Item>
          <Form.Item
            name="order_file_type"
            noStyle
            rules={[{ required: true, message: "Order file type is required" }]}
          >
            <Select
              showSearch
              style={{ width: "20vh", textAlign: "left" }}
              placeholder="Select an order file type"
              optionFilterProp="label"
              size="middle"
              onChange={onHandleFilterType}
              defaultValue={filterType}
              options={OrderFileTypes}
            />
          </Form.Item>
          <Form.Item
            name="order_file_type"
            noStyle
            rules={[{ required: true, message: "Order file type is required" }]}
          >
            <Button
              ghost
              htmlType="submit"
              type="primary"
              icon={<SearchOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="order_file_type"
            noStyle
            rules={[{ required: true, message: "Order file type is required" }]}
          >
            <Button
              ghost
              type="primary"
              onClick={refreshTable}
              icon={<RedoOutlined />}
            />
          </Form.Item>
        </Space.Compact>
      </Form>
      <Button
        ghost
        type="primary"
        onClick={() => setUploadOrderFileModal(true)}
      >
        Order File Upload
      </Button>
      <Button ghost type="primary" onClick={onHandleOpenCreateOrderFile}>
        New Order File
      </Button>
      <Button
        ghost
        type="primary"
        onClick={onHandleOpenManageZoneModal}
        icon={<SettingOutlined />}
      />
    </Flex>
  );

  const tabItems: TabsProps["items"] = [
    {
      label: "Order Files",
      key: "1",
      children: (
        <OrderFiles
          loading={getOrderFile.loading}
          data={newDataCol}
          columns={columns}
        />
      ),
    },
    {
      label: "Purchase Orders",
      key: "2",
      children: <PurchaseOrderList />,
    },
    {
      label: "Delivery Notes",
      key: "3",
      children: <FDeliveryNoteList />,
    },
  ];

  const renderContent = () => {
    switch (changePage) {
      case "default":
        return (
          <Flex style={{ width: "100%", padding: "10px" }} vertical>
            <Tabs
              defaultActiveKey="1"
              tabBarExtraContent={OperationsSlot}
              items={tabItems}
            />
          </Flex>
        );
      case "order-file-details":
        return (
          <OrderDetails
            setChangePage={setChangePage}
            orderFileId={orderFileId}
            stepper={stepper}
            actionType={actionType}
            currentUser={currentUser}
          />
        );
      case "add-orders":
        return (
          <MakeOrder setChangePage={setChangePage} orderFileId={orderFileId} />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `${readDocument}${document.file}/${document.name}`,
          {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const imageUrl = URL.createObjectURL(response.data);
        setImageSrc(imageUrl);
      } catch (error) {
        console.error("Error fetching the image", error);
      }
    };

    if (document) {
      fetchImage();
    }
  }, [document]);

  return (
    <>
      <div className="dashboard-container__box">
        <div className="user-account-tbl">{renderContent()}</div>
      </div>
      <CreateOrderFileModal
        open={openCreateOrderFile}
        close={onHandleCloseCreateOrderFile}
      />
      <ManageZonesModal
        open={openManageZones}
        onHandleClose={onHandleCloseManageZoneModal}
      />
      <Modal
        width={800}
        title="Upload file"
        open={open}
        onCancel={() => setOpen(false)}
        closable={false}
        footer={null}
      >
        <div className="custom-modal-btn-group">
          <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            <Input type="file" onChange={handleFileChange} size="large" />
          </div>
          {file && (
            <div className="image-container">
              <img
                src={file}
                alt="Preview"
                className="fixed-size-image"
                style={{ maxWidth: "100%" }}
              />
            </div>
          )}
          {!file && (
            <Flex align="center" justify="center" className="fixed-size-image">
              <FileImageOutlined style={{ fontSize: "100px" }} />
            </Flex>
          )}
        </div>
        <div
          className="custom-modal-footer"
          style={{ justifyContent: "space-between" }}
        >
          <Button
            key="cancel"
            ghost
            type="primary"
            onClick={() => {
              setOpen(false);
              setFile(null);
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            key="submit"
            ghost
            type="primary"
            onClick={onSaveDocument}
            loading={uploadDocument.loading}
          >
            Save
          </Button>
        </div>
      </Modal>

      <Modal
        width={800}
        title="Attached file"
        open={openUploadedDocument}
        onCancel={() => setOpenUploadedDocument(false)}
        closable={false}
        footer={null}
      >
        <div className="image-container">
          {imageSrc ? (
            <img src={imageSrc} alt="Protected" className="fixed-size-image" />
          ) : (
            <Flex align="center" justify="center" className="fixed-size-image">
              <FileImageOutlined style={{ fontSize: "100px" }} />
            </Flex>
          )}
        </div>

        <div
          className="custom-modal-footer"
          style={{ justifyContent: "space-between" }}
        >
          <Button
            key="close"
            onClick={() => {
              setOpenUploadedDocument(false);
              setImageSrc("");
            }}
            type="primary"
            ghost
            danger
            size="large"
          >
            Close
          </Button>
        </div>
      </Modal>

      <OrderFileUpload
        open={uploadOrderFileModal}
        onHandleCloseModal={() => setUploadOrderFileModal(false)}
      />
    </>
  );
};

export default OrderOfficer;
