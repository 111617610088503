/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { UserOutlined, GlobalOutlined } from '@ant-design/icons';
import './index.css';
import { Avatar, Button, Flex } from 'antd';

const StaticNavBar: React.FC<any> = ({ userData }) => {
    const languages = [
        { language: 'English', flag: 'united kingdom flag' },
        { language: 'Kinyarwanda', flag: 'rwanda flag' },
        { language: 'Français', flag: 'france flag' },
    ];

    const [selectedLanguage, setSelectedLanguage]: any = useState('');

    const changeLanguage = () => {
        setSelectedLanguage(selectedLanguage);
    };

    const usersData = userData === undefined ? {} : userData[0];

    return (
        <>
            <div className="nav-bar__container">
                <Flex className="nav-bar_container-row" justify="space-between" align="center">
                    <div className="left-content">
                        <div className="left-content__row">
                            <Link to="/dashboard">
                                <h2 id="static-nav-bar-title" style={{ color: '#333' }}>
                                    {usersData?.name}
                                </h2>
                            </Link>
                        </div>
                    </div>
                    <Flex gap="small" align="center">
                        <Flex gap="small" align="center" style={{ color: '#333' }}>
                            <Avatar icon={<UserOutlined label="user" />} />
                            <span>{usersData?.username}</span>
                        </Flex>
                        <Popup
                            content={
                                <>
                                    {languages.map((item, index) => (
                                        <Flex key={index}>
                                            <Button type={'link'} onClick={() => changeLanguage()}>
                                                <span>{item.language}</span>
                                            </Button>
                                        </Flex>
                                    ))}
                                </>
                            }
                            on="click"
                            positionFixed
                            trigger={
                                <Button type={'link'} icon={<GlobalOutlined />}>
                                    <span>English</span>
                                </Button>
                            }
                        />
                    </Flex>
                </Flex>
            </div>
        </>
    );
};

export default StaticNavBar;
