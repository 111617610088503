import { Button, Flex, Form, Input, Typography } from "antd";
import { ContactsOutlined, SafetyCertificateOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordData,
  clearChangePasswordStore,
} from "../../redux/actions/auth/changePassword";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Translate from "../../languages/Translate";
import { countryCode } from "../../constants/telco";
import {
  hasOnlyDigits,
  isFirstTwoZero,
  isFirstTwoZeroZero,
  numberStartWithSeven,
} from "../Appointment";
import "./index.css";

const { Title, Text } = Typography;

const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const hasSpecialCharsAndCapitalLetters = (inputString: string) => {
  const specialCharsRegex = /[*()_+{}[\]:;<>,.?~\\]/;
  const capitalLettersRegex = /[A-Z]/;
  const hasSpecialChars = specialCharsRegex.test(inputString);
  const hasCapitalLetters = capitalLettersRegex.test(inputString);

  return {
    hasSpecialChars,
    hasCapitalLetters,
  };
};

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setSteps] = useState<number | any>(1);
  const [codeVerify, setCodeVerify] = useState(false);
  const [verifyCodeSent, setVerifyCodeSent] = useState<boolean>(false);
  const [form, setForm] = useState<any>({
    new_password: "",
    old_password: "",
    repeat_password: "",
    phone_number: "",
    verification_code: "",
    email: "",
  });

  const location = useLocation();

  const receivedData = (location.state as { changePassword?: string })
    ?.changePassword;

  const receivedEmail = (location.state as { email?: string })?.email;

  const onHandleInput = (event: any) => {
    const { name, value } = event.target;
    const list = { ...form };
    list[name] = value;
    setForm(list);
  };
  const {
    changePassword: { data, loading },
  } = useSelector((user: any) => user);

  const onSubmitChangePassword = ({
    lastStep = null,
  }: { lastStep?: string | null | number } = {}) => {
    const checkPhoneNumberMessage = "Please provide correct phone number";
    const inputString = hasSpecialCharsAndCapitalLetters(form.new_password);

    const userData = {
      first_login: receivedData ? false : true,
      new_password: form?.new_password,
      old_password: form?.old_password,
      email: receivedEmail || "",
    };

    if (receivedData) {
      if (form.phone_number.length !== 9 && step === 2) {
        toast.error(checkPhoneNumberMessage);
      }

      if (
        !hasOnlyDigits(form.phone_number) ||
        form.phone_number.length !== 9 ||
        isFirstTwoZeroZero(form.phone_number) ||
        isFirstTwoZero(form.phone_number) ||
        !numberStartWithSeven(form?.phone_number)
      ) {
        toast.error("Incorrect phone number provided");
      }

      const sendVerificationCode = () => {
        dispatch(
          changePasswordData({
            ...userData,
            change_password: receivedData || false,
            check_phone_number: receivedData ?? true,
            email: form?.email,
            phone_number: `${countryCode}${form?.phone_number}`,
            check_verification_code: false,
          })
        );
      };

      if (step === 1) {
        if (!validateEmail(form.email)) {
          toast.error("Please provide a valid email");
        } else {
          sendVerificationCode();
        }
      }

      // Resend code
      if (step === 2 && lastStep === null) {
        sendVerificationCode();
        setVerifyCodeSent(false);
      }

      if (lastStep === 3) {
        if (form?.verification_code === "") {
          toast.error("Please provide the verification code");
        } else {
          const formData = {
            ...userData,
            code_verify: codeVerify,
            change_password: true,
            verification_code: form?.verification_code,
            check_verification_code: true,
            phone_number: `${countryCode}${form?.phone_number}`,
            check_phone_number: true,
            email: form?.email,
          };
          dispatch(changePasswordData(formData));
        }
      }

      if (lastStep === 4) {
        const formData = {
          ...userData,
          code_verify: codeVerify,
          change_password: true,
          verification_code: form?.verification_code,
          check_verification_code: false,
          phone_number: `${countryCode}${form?.phone_number}`,
          check_phone_number: false,
          email: form?.email,
        };

        if (form?.new_password === "") {
          toast.error("Please provide password");
        } else if (form.new_password.length < 8) {
          toast.error("Password is to short");
        } else if (
          inputString.hasCapitalLetters === false &&
          inputString.hasSpecialChars === false
        ) {
          toast.error(
            "Password must contain spacial characters and capital letters"
          );
        } else if (form?.new_password !== form?.repeat_password) {
          toast.error("Password did not match");
        } else {
          dispatch(changePasswordData(formData));
        }
      }
    } else {
      if (form?.new_password === "") {
        toast.error("Please provide password");
      } else if (
        inputString.hasCapitalLetters === false &&
        inputString.hasSpecialChars === false
      ) {
        toast.error(
          "Password must contain spacial characters and capital letters"
        );
      } else if (form.new_password.length <= 8) {
        toast.error("Password is to short");
      } else if (form?.new_password !== form?.repeat_password) {
        toast.error("Password did not match");
      } else {
        dispatch(changePasswordData(userData));
      }
    }
  };

  useEffect(() => {
    const isCurrentPage = localStorage.getItem("isUpdateCredentials");

    if (isCurrentPage === null || isCurrentPage === "") {
      navigate("/admin");
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (_event: any) => {
      localStorage.removeItem("isUpdateCredentials");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (data?.data?.error !== undefined) {
      toast.error(data?.data?.message);
    }

    if (data?.data?.error === 400) {
      toast.error(data?.data?.description);
    }

    if (data?.data?.success !== undefined) {
      toast.error(data?.data?.message);
    }

    if (data?.data?.responseData?.code_verified) {
      setCodeVerify(data?.data?.responseData?.code_verified);
    }

    if (data?.data?.responseData?.verified_code_sent) {
      setVerifyCodeSent(data?.data?.responseData?.verified_code_sent);
      setSteps(2);
    }

    if (data?.data?.success !== undefined) {
      toast.success(data?.message);
    }
    if (data?.data?.key === "DONE") {
      dispatch(clearChangePasswordStore());
      window.location.href = "/admin";
    }
  }, [data?.data]);

  return (
    <div className="update-password-container">
      <div className="update-password-card">
        <Title level={3} className="update-password-title">
          {!receivedData ? "Update your password" : "Change password"}
        </Title>

        <Form layout="vertical" className="update-password-form">
          {receivedData && !codeVerify && (
            <>
              <Form.Item label="Provide your email">
                <Input
                  type="email"
                  value={form?.email}
                  size="large"
                  name="email"
                  onChange={onHandleInput}
                  placeholder="Enter email"
                  prefix={<ContactsOutlined />}
                />
              </Form.Item>
              <Form.Item label="Provide your phone number">
                <Input
                  type="text"
                  value={form?.phone_number}
                  size="large"
                  name="phone_number"
                  addonBefore={countryCode}
                  placeholder={Translate("78xxxxxxxxx")}
                  onChange={onHandleInput}
                />
              </Form.Item>
              {step === 2 && (
                <>
                  <Form.Item
                    label={
                      <span style={{ color: "#007bff" }}>
                        {`Please enter the code sent to the phone number `}
                        <b>{form?.phone_number}</b>{" "}
                        {`to complete the verification process`}
                      </span>
                    }
                  >
                    <Input
                      type="text"
                      prefix={<SafetyCertificateOutlined />}
                      value={form?.verification_code}
                      size="large"
                      name="verification_code"
                      onChange={onHandleInput}
                      placeholder="Enter your verification code"
                    />
                  </Form.Item>
                </>
              )}
            </>
          )}

          {!receivedData && (
            <>
              <Form.Item label="Old password">
                <Input
                  type="password"
                  value={form?.old_password}
                  size="large"
                  name="old_password"
                  onChange={onHandleInput}
                  placeholder="Enter your old password"
                />
              </Form.Item>

              <Form.Item label="New password">
                <Input
                  type="password"
                  value={form?.new_password}
                  size="large"
                  name="new_password"
                  onChange={onHandleInput}
                  placeholder="Enter new password"
                />
              </Form.Item>

              <Form.Item label="Repeat password">
                <Input
                  type="text"
                  value={form?.repeat_password}
                  size="large"
                  name="repeat_password"
                  onChange={onHandleInput}
                  placeholder="Repeat  password"
                />
              </Form.Item>
              <Text type="secondary" className="password-requirements">
                Password should contain numbers, lowercase and uppercase
                letters, and special characters:
                <span className="special-chars">{`* ( ) _ + - = { } [ ] | : ; " ' &lt; &gt; , . ? /`}</span>
              </Text>
            </>
          )}

          {codeVerify && (
            <>
              <Form.Item label="New password">
                <Input
                  type="password"
                  value={form?.new_password}
                  size="large"
                  name="new_password"
                  onChange={onHandleInput}
                  placeholder="Enter new password"
                />
              </Form.Item>

              <Form.Item label="Repeat passwords">
                <Input
                  type="text"
                  value={form?.repeat_password}
                  size="large"
                  name="repeat_password"
                  onChange={onHandleInput}
                  placeholder="Repeat  password"
                />
              </Form.Item>
              <Text type="secondary" className="password-requirements">
                Password should contain numbers, lowercase and uppercase
                letters, and special characters:
                <span className="special-chars">{`* ( ) _ + - = { } [ ] | : ; " ' &lt; &gt; , . ? /`}</span>
              </Text>
            </>
          )}

          <Form.Item style={{ marginTop: "40px" }}>
            {!codeVerify && (
              <Flex gap="middle" vertical>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => onSubmitChangePassword()}
                  block
                >
                  {!receivedData
                    ? "Submit"
                    : receivedData && step === 1
                    ? "Send"
                    : "Resend"}
                </Button>
                {step === 2 && verifyCodeSent && (
                  <Button
                    danger
                    ghost
                    loading={loading && verifyCodeSent ? true : false}
                    onClick={() => onSubmitChangePassword({ lastStep: 3 })}
                    block
                  >
                    Verify
                  </Button>
                )}
              </Flex>
            )}
            {codeVerify && (
              <Button
                type="primary"
                loading={loading}
                onClick={() => onSubmitChangePassword({ lastStep: 4 })}
                block
              >
                Submit
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdatePassword;
