/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import { getPublicItemsData } from "../../../redux/actions";
import { Form } from "antd";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const AddFacilities: React.FC<any> = ({ setAddFacilityForm }) => {
  const dispatch = useDispatch();
  const [districtsList, setDistrictList]: any = useState([]);
  const [prisonName, setPrisonName] = useState("");
  const [district, setDistrict] = useState("");
  const [getProvince, setGetProvince] = useState("");

  const {
    getPublicItem: { data },
  } = userSelector((user: any) => user);

  const publicItems = data?.data;

  useEffect(() => {
    setAddFacilityForm({
      name: prisonName,
      province: getProvince,
      district: district,
    });
  }, [prisonName, getProvince, district]);

  const onHandleSelectProvince = (value: string) => {
    setGetProvince(value);
  };
  const onHandleChangeCorrectionalService = (value: string) =>
    setPrisonName(value);

  const onHandleChangeDistrict = (value: string) => setDistrict(value);

  const onSearch = (value: string) => {
    return value;
  };

  useEffect(() => {
    const newList: any = [];
    publicItems?.location?.map((item: any) => {
      if (getProvince === item?.value) {
        newList.push(item?.data);
      }
    });

    setDistrictList(...newList);
  }, [getProvince, districtsList]);

  useEffect(() => {
    dispatch(getPublicItemsData());
  }, [dispatch]);

  return (
    <Form layout="vertical">
      <Form.Item label="Select correctional facility">
        <Select
          showSearch
          placeholder="Select a correctional facility"
          optionFilterProp="children"
          onSearch={onSearch}
          filterOption={(input, option) => {
            const val: any = option?.label ?? "";
            return val.toLowerCase().includes(input.toLowerCase());
          }}
          onChange={onHandleChangeCorrectionalService}
          options={publicItems?.facilities}
          size="large"
        />
      </Form.Item>
      <Form.Item label="Select province">
        <Select
          showSearch
          placeholder="Select a province"
          optionFilterProp="children"
          onSearch={onSearch}
          filterOption={(input, option) => {
            const val: any = option?.label ?? "";
            return val.toLowerCase().includes(input.toLowerCase());
          }}
          onChange={onHandleSelectProvince}
          options={publicItems?.location}
          size="large"
        />
      </Form.Item>
      <Form.Item label="Select district">
        <Select
          disabled={districtsList?.length > 0 ? false : true}
          showSearch
          placeholder="Select a district"
          optionFilterProp="children"
          onSearch={onSearch}
          filterOption={(input, option) => {
            const val: any = option?.label ?? "";
            return val.toLowerCase().includes(input.toLowerCase());
          }}
          size="large"
          onChange={onHandleChangeDistrict}
          options={districtsList}
        />
      </Form.Item>
    </Form>
  );
};

export default AddFacilities;
