import React, { useState } from "react";
import { Button, Flex, Modal, Popconfirm, Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getCorrectionalServiceData,
  registerFacilityData,
  registerUsers,
} from "../../../redux/actions";
import CreateAccount from "./createAccount";
import AddFacilities from "./addFacilities";
import { updateFacilityData } from "../../../redux/actions/facility/updateFacility";
import SuperAdminAddDP from "./superAdminAddDP";
import FacilityUsers from "./facility_users";
import {
  IFacilityData,
  IFacilityInfoData,
} from "../../../interfaces/facilities";
import NoResultFound from "../../common/noResultFound";
import { PlusOutlined } from "@ant-design/icons";

interface IUserRoles {
  name: string;
  rolesKey: number;
}

const Facilities: React.FC = () => {
  const dispatch = useDispatch();
  const [prisonName, setPrisonName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [userRoleId, setUserRoleId] = useState("");
  const [district, setDistrict] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [newUserRoles, setNewUserRoles] = useState<any>([]);
  const [changePage, setChangePage] = useState<string>("default");
  const [getProvince, setGetProvince] = useState("");
  const [facilityId, setFacilityId] = useState<any>();
  const [open, setOpen] = useState(false);
  const [openAddDCF, setOpenAddDCF] = useState(false);

  const [addFacilityForm, setAddFacilityForm] = useState({
    districtsList: "",
    prisonName: "",
    district: "",
  });

  const onHandleChangeEmail = (e: any) => setEmail(e.target.value);
  const onHandleChangeUsername = (e: any) => setUsername(e.target.value);
  const onHandleChangeUserRoleId = (value: string) => setUserRoleId(value);

  const onHandleChangeDistrict = (value: string) => setDistrict(value);
  const onHandleChangePhoneNumber = (e: any) => setPhoneNumber(e.target.value);
  const onHandleChangeCorrectionalService = (value: string) =>
    setPrisonName(value);

  const {
    getUserData,
    getCorrectionalFacility,
    registerFacility,
    getUserRoles,
    registerUser,
  } = useSelector((user: any) => user);

  const disableFacility = (data: any) => {
    const userData: any = {
      district: data?.district,
      id: data?.id,
      name: data?.name,
      province: data?.province,
      disable: true,
      activate: false,
    };
    dispatch(updateFacilityData(userData));
    dispatch(getCorrectionalServiceData());
  };

  const activateFacility = (data: any) => {
    const userData: any = {
      district: data?.district,
      id: data?.id,
      name: data?.name,
      province: data?.province,
      disable: false,
      activate: true,
    };
    dispatch(updateFacilityData(userData));
    dispatch(getCorrectionalServiceData());
  };

  const onHandleSelectProvince = (value: string) => {
    setGetProvince(value);
  };

  const registerAdmin = () => {
    const userData = {
      name:
        getUserData?.data[0]?.userRoleId === 1
          ? prisonName
          : getUserData?.data[0]?.name,
      email: email,
      username: username,
      province:
        getUserData?.data[0]?.userRoleId === 1
          ? getProvince
          : getUserData?.data[0]?.user_profiles[0]?.province,
      districts:
        getUserData?.data[0]?.userRoleId === 1
          ? district
          : getUserData?.data[0]?.user_profiles[0]?.districts,
      userRoleId: getUserData?.data[0]?.userRoleId === 1 ? 2 : userRoleId,
      prisonId: getUserData?.data[0]?.id,
      phoneNumber: phoneNumber,
      superKey: getUserData?.data[0]?.userRoleId === 1 ? "YES" : "NO",
      is_dfc: false,
    };

    if (email !== "" || username !== "" || phoneNumber !== "") {
      dispatch(registerUsers(userData));
      setTimeout(function () {
        window.location.reload();
        setChangePage("default");
      }, 4000);
    } else {
      setAlertMessage("You have to fill all fields.");
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const columns: ColumnsType<IFacilityData> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Province",
      dataIndex: "district",
      key: "district",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, status: any) => {
        return (
          <>
            {status?.status === true ? (
              <Tag color={"green"}>{"Active"}</Tag>
            ) : (
              <Tag color={"red"}>{"Disable"}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Location",
      key: "user_profiles",
      dataIndex: "user_profiles",
      render: (_, { province, district }) => (
        <>
          <Tag>
            {province?.toUpperCase()}, {district?.toUpperCase()}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, data: any) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          {data?.status === true ? (
            <a
              onClick={() => {
                onHandleOpenAddDCFModal();
                setFacilityId(data?.id);
              }}
            >
              Add DCF
            </a>
          ) : (
            <a style={{ color: "grey" }}>Blocked</a>
          )}

          {data?.status === true ? (
            <Popconfirm
              title="Are you sure you want to disable this facility?"
              onConfirm={() => disableFacility(data)}
              okText="Yes"
              cancelText="Cancel"
            >
              <a style={{ color: "red" }}>Disable</a>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Are you sure you want to activate this facilitys?"
              onConfirm={() => activateFacility(data)}
              okText="Yes"
              cancelText="Cancel"
            >
              <a style={{ color: "green" }}>Activate</a>
            </Popconfirm>
          )}
          {data?.status === true ? (
            <a
              onClick={() => {
                setChangePage("facility-users");
                setFacilityId(data?.id);
              }}
            >
              Details
            </a>
          ) : (
            <a style={{ color: "grey" }}>Details</a>
          )}
        </Space>
      ),
    },
  ];

  const userRoles = getUserRoles?.data?.data?.map((item: IUserRoles) => {
    const data = { value: item?.rolesKey, label: item?.name };
    return data;
  });

  const onSearch = (value: string) => {
    return value;
  };

  const onHandleOpenAddDCFModal = () => setOpenAddDCF(true);
  const onHandleOpenCloseDCFModal = () => setOpenAddDCF(false);

  const [correctionalFacility, setCorrectionalFacility] =
    useState<IFacilityInfoData>();
  const facilityInfo: any = correctionalFacility;
  const data: IFacilityData[] = facilityInfo?.details;

  useEffect(() => {
    if (getCorrectionalFacility?.data || correctionalFacility) {
      setCorrectionalFacility(getCorrectionalFacility?.data);
    } else {
      dispatch(getCorrectionalServiceData());
    }
  }, [dispatch, getCorrectionalFacility?.data, correctionalFacility]);

  useEffect(() => {
    dispatch(getCorrectionalServiceData());
  }, [dispatch]);

  useEffect(() => {
    if (
      prisonName === "" ||
      username === "" ||
      userRoleId === "" ||
      getProvince === "" ||
      district === ""
    ) {
      setAlertMessage("You have to fill all fields.");
    }
    setAlertMessage("");
  }, []);

  useEffect(() => {
    const newList: any = [];
    userRoles?.map((item: any) => {
      if (
        ["Super Admin", "Prison Admin", "DDCF", "DCF"].includes(item.label) !==
        true
      ) {
        newList.push({
          label: item?.label,
          value: item?.value,
        });
      }
    });
    setNewUserRoles(newList);
  }, [userRoles]);

  useEffect(() => {
    if (email !== "" || username !== "") {
      setAlertMessage("");
    }
  }, [email, username]);

  useEffect(() => {
    if (registerFacility.data?.data?.success !== undefined) {
      handleCancel();
      dispatch(getCorrectionalServiceData());
    }
  }, [dispatch, registerFacility]);

  const onAddNewFacility = () => {
    const userData = addFacilityForm;
    dispatch(registerFacilityData(userData));
  };

  return (
    <>
      {changePage === "default" ? (
        <>
          <div className="dashboard-container__box">
            <div className="user-account-tbl">
              <Flex
                align="center"
                justify="flex-end"
                style={{
                  width: "100%",
                  background: "#fff",
                  padding: "8px",
                  borderBottom: "1px solid #d8d8d8",
                }}
              >
                <Button
                  ghost
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => showModal()}
                >
                  <span>New Facility</span>
                </Button>
              </Flex>
              <div
                className="tbl-items"
                style={{ justifyContent: "flex-start" }}
              >
                <div className="tbl-items__col">
                  <span style={{ color: "#777677" }}>All Facilities</span>
                  <span
                    style={{
                      color: "#262D30",
                      marginTop: "10px",
                      fontSize: "20px",
                    }}
                  >
                    {facilityInfo?.all_facilities}
                  </span>
                </div>
                <div className="separator__col"></div>
                <div className="tbl-items__col">
                  <span style={{ color: "#777677" }}>
                    {facilityInfo?.active_facility > 1
                      ? "Active Facilities"
                      : "Active Facility"}
                  </span>
                  <span
                    style={{
                      color: "#262D30",
                      marginTop: "10px",
                      fontSize: "20px",
                    }}
                  >
                    {facilityInfo?.active_facility}
                  </span>
                </div>
                <div className="separator__col"></div>
                <div className="tbl-items__col">
                  <span style={{ color: "#777677" }}>
                    {facilityInfo?.disable_facility > 1
                      ? "Disabled Facilities"
                      : "Disabled Facility"}
                  </span>
                  <span
                    style={{
                      color: "#262D30",
                      marginTop: "10px",
                      fontSize: "20px",
                    }}
                  >
                    {facilityInfo?.disable_facility}
                  </span>
                </div>
              </div>
              {data?.length <= 0 ? (
                <NoResultFound />
              ) : (
                <Table
                  size="small"
                  columns={columns}
                  dataSource={data}
                  pagination={data?.length >= 30 ? undefined : false}
                />
              )}
            </div>
          </div>
        </>
      ) : null}

      <SuperAdminAddDP
        facilityId={facilityId}
        setChangePage={setChangePage}
        open={openAddDCF}
        onClose={onHandleOpenCloseDCFModal}
      />

      {changePage === "facility-users" ? (
        <FacilityUsers facilityId={facilityId} setChangePage={setChangePage} />
      ) : null}

      {changePage === "createNewUserAccount" ? (
        <CreateAccount
          getUserData={getUserData}
          alertMessage={alertMessage}
          email={email}
          username={username}
          onHandleChangeUsername={onHandleChangeUsername}
          onHandleChangeEmail={onHandleChangeEmail}
          onSearch={onSearch}
          onHandleChangeCorrectionalService={onHandleChangeCorrectionalService}
          getCorrectionalFacility={getCorrectionalFacility?.data}
          onHandleSelectProvince={onHandleSelectProvince}
          onHandleChangeDistrict={onHandleChangeDistrict}
          onHandleChangeUserRoleId={onHandleChangeUserRoleId}
          newUserRoles={newUserRoles}
          phoneNumber={phoneNumber}
          onHandleChangePhoneNumber={onHandleChangePhoneNumber}
          registerUser={registerUser}
          registerAdmin={registerAdmin}
          setChangePage={setChangePage}
        />
      ) : null}

      <Modal
        title="Add facility to the list"
        open={open}
        onCancel={handleCancel}
        closable={false}
        footer={
          <Flex justify="space-between" style={{ width: "100%" }}>
            <Button key="submit" ghost danger onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              ghost
              type="primary"
              loading={registerFacility.loading}
              onClick={onAddNewFacility}
            >
              Save
            </Button>
          </Flex>
        }
      >
        <Flex vertical>
          <AddFacilities setAddFacilityForm={setAddFacilityForm} />
        </Flex>
      </Modal>
    </>
  );
};

export default Facilities;
