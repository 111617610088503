export const appointmentCategory = [
    {  
        value: "physical",
        label: "Physical"
    } ,
    {
        value: "virtual",
        label: "Virtual"
    }     
]

export const appointmentTypes = [
    {  
        value: "normal",
        label: "Normal",
        description: "Available for anyone wishing to book a visit"
    } ,
    {  
        value: "special",
        label: "Special",
        description: "For individuals with special recommendations."
    } ,  
    {  
        value: "lawyer",
        label: "Lawyers", 
        description: "Reserved for lawyers visiting an inmate"
    } ,  
]

export const genders = [
    {  
        value: "F",
        label: "Female"
    } ,
    {  
        value: "M",
        label: "Male"
    } ,  
]


export const INCOMING_APPOINTMENT  = "incoming"
export const OUTGOING_APPOINTMENT = 'outgoing'