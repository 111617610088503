import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";
import {
  autoStartSessionData,
  checkVisitIDData,
  clearCheckVisitID,
  getUserDataInfo,
  saveDeviceSignatureData,
} from "../../../redux/actions";
import { IUserSelectorType } from "../../../interfaces";
import "./index.css";
import { Button, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import { startMeetingData } from "../../../redux/actions/videoMeeting/startMeeting";
import { ManageDates } from "../../dashboard";
import { appName } from "../../../constants/defaultLabel";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface ISession {
  first_name: string;
  last_name: string;
  inmate_code: string;
  visit_time: string;
  inmate_visit_id: string;
}

const FacilityVideoMeeting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getDate = new ManageDates();
  const [open, setOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentHours, setCurrentHours] = useState(new Date().getHours());
  const [currentMinutes, setCurrentMinutes] = useState(new Date().getMinutes());
  const [browserName, setBrowserName] = useState<string>("");
  const [browserId, setBrowserId] = useState<string>("");

  const {
    saveDeviceSignature,
    getUserData: { data },
    autoStartSession,
    startMeeting,
    checkVisitId,
  } = userSelector((user) => user);

  const userData = data?.data ? data?.data[0] : {};
  const startSession = !autoStartSession?.data?.data
    ? []
    : autoStartSession?.data?.data?.appointments;

  useEffect(() => {
    dispatch(getUserDataInfo());
  }, [dispatch]);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf("Chrome") > -1) {
      setBrowserName("Google Chrome");
    } else if (userAgent.indexOf("Firefox") > -1) {
      setBrowserName("Mozilla Firefox");
    } else if (userAgent.indexOf("Edge") > -1) {
      setBrowserName("Microsoft Edge");
    } else if (userAgent.indexOf("Safari") > -1) {
      setBrowserName("Apple Safari");
    } else if (
      userAgent.indexOf("Opera") > -1 ||
      userAgent.indexOf("OPR") > -1
    ) {
      setBrowserName("Opera");
    } else if (userAgent.indexOf("Trident") > -1) {
      setBrowserName("Internet Explorer");
    } else {
      setBrowserName("Unknown Browser");
    }
  }, []);

  useEffect(() => {
    if (saveDeviceSignature?.data) {
      navigate("/facility-video-meeting");
    }
  }, []);

  useEffect(() => {
    const existingBrowserId = localStorage.getItem("browserId");
    if (existingBrowserId) {
      setBrowserId(existingBrowserId);
    } else {
      const newBrowserId = uuidv4();
      setBrowserId(newBrowserId);
      localStorage.setItem("browserId", newBrowserId);
    }
  }, []);

  useEffect(() => {
    if (browserName) {
      dispatch(
        saveDeviceSignatureData({
          facility_id: userData?.prison_id,
          browser_name: browserName,
          browser_signature: `${localStorage.getItem("browserId")}`,
          device_status: "opened",
        })
      );
    }
  }, [browserName]);

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (_event) {
      window.history.pushState(null, document.title, window.location.href);
      setOpen(true);
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setCurrentHours(now.getHours());
      setCurrentMinutes(now.getMinutes());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // New function to format time consistently
  const formatTime = (time: Date): string => {
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  // New effect for time-based navigation
  useEffect(() => {
    const checkSessionTime = () => {
      const formattedCurrentTime = formatTime(currentTime);
      startSession &&
        startSession?.forEach((session: ISession) => {
          const sessionTime = session?.visit_time.trim();
          if (sessionTime === formattedCurrentTime) {
            dispatch(
              checkVisitIDData({
                visit_id: session?.inmate_visit_id,
                guest: "inmate",
              })
            );
          }
        });
    };

    const timeCheckInterval = setInterval(checkSessionTime, 1000);
    return () => clearInterval(timeCheckInterval);
  }, [currentTime, startSession, dispatch]);

  useEffect(() => {
    if (currentHours || currentMinutes) {
      const newList: any = [];
      startMeeting.data?.data?.appointments?.filter((appointment: any) => {
        const hours = `${currentHours}:${currentMinutes}`;
        if (hours === `${appointment?.visit_time}`) {
          newList.push({ visit_id: appointment?.inmate_visit_id });
        }
      });

      if (newList.length !== 0) {
        dispatch(
          checkVisitIDData({ visit_id: newList[0]?.visit_id, guest: "inmate" })
        );
      }
    }
  }, [startMeeting.data?.data?.appointments, currentHours, currentMinutes]);

  useEffect(() => {
    if (checkVisitId?.data?.data?.success) {
      navigate("/start-meeting");
    }

    if (checkVisitId?.data?.data?.error) {
      message.error(checkVisitId.data.data.message);
      dispatch(clearCheckVisitID());
    }
  }, [checkVisitId?.data]);

  const onStartSession = () => {
    dispatch(
      autoStartSessionData({
        browser_signature: `${browserId}`,
        today_date: getDate.defaultEndDate(),
      })
    );
    dispatch(
      startMeetingData({
        browser_signature: `${browserId}`,
        today_date: getDate.defaultEndDate(),
      })
    );
  };

  // New function to calculate time until next session
  const getTimeUntilNextSession = (sessionTime: string): string => {
    const [sessionHours, sessionMinutes] = sessionTime.split(":").map(Number);
    const sessionDate = new Date();
    sessionDate.setHours(sessionHours, sessionMinutes, 0);

    const timeDiff = sessionDate.getTime() - currentTime.getTime();
    if (timeDiff <= 0) return "Session time has arrived";

    const minutesUntil = Math.floor(timeDiff / (1000 * 60));
    const hoursUntil = Math.floor(minutesUntil / 60);
    const remainingMinutes = minutesUntil % 60;

    if (hoursUntil > 0) {
      return `Starting in ${hoursUntil}h ${remainingMinutes}m`;
    }
    return `Starting in ${remainingMinutes}m`;
  };
  console.log("I'm here, --------->", startSession);
  return (
    <div className="dashboard-container__row">
      <div className="video-start-page">
        <div className="context-box">
          <h2>{appName}</h2>
          <span className="subtitle-msg">{`Please wait, you will be prompt into the meeting when it'll start.`}</span>
          <div className="spacer"></div>
          {startSession &&
            startSession?.map((session: ISession) => (
              <div key={session.inmate_code}>
                <div className="guest-info">
                  <span className="guest-info-title">Guest Name: </span>
                  <span>
                    {session?.first_name} {session?.last_name}
                  </span>
                </div>
                <div className="guest-info">
                  <span className="guest-info-title">
                    Guest RSC case number:{" "}
                  </span>
                  <span>{session?.inmate_code}</span>
                </div>
                <div className="guest-info">
                  <div className="time-countdown">
                    <span>Start time {session.visit_time}</span>
                    <span className="countdown-timer">
                      {getTimeUntilNextSession(session.visit_time)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          <div className="time-countdown">
            {startSession?.length === 0 ? (
              <Button id="transparent-btn" onClick={onStartSession}>
                Click here to start session
              </Button>
            ) : (
              <div className="time-countdown">
                <span>{currentTime.toLocaleTimeString()}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        title="End session"
        centered
        open={open}
        onOk={() => {
          setOpen(false);
          dispatch(
            saveDeviceSignatureData({
              facility_id: userData?.prison_id,
              browser_name: `${browserName}`,
              browser_signature: `${browserId}`,
              device_status: "closed",
            })
          );
          navigate("/video-visits");
        }}
        onCancel={() => setOpen(false)}
        width={500}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingTop: "16px",
            marginBottom: "55px",
          }}
        >
          <span>Do you want to leave this session?</span>
        </div>
      </Modal>
    </div>
  );
};

export default FacilityVideoMeeting;
