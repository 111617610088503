import React, { useEffect, useRef, useState } from "react";
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalVideoTrack,
  ILocalAudioTrack,
} from "agora-rtc-sdk-ng";
import "./style.css";
import { Flex, Tag } from "antd";
import { clearCheckVisitID, clearCheckWhoJoined } from "redux/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const APP_ID = process.env.REACT_APP_API_AGORA_APP_ID || "";
const CHANNEL_NAME = process.env.REACT_APP_API_AGORA_APP_CHANNEL || "";
const TOKEN = process.env.REACT_APP_API_AGORA_APP_TOKEN || "";

interface VideoCallProps {
  guestData: any;
  guest: any;
}

const VideoCall: React.FC<VideoCallProps> = ({ guestData, guest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isJoined, setIsJoined] = useState(false);
  const [isGuestJoined, setIsGestJoined] = useState(false);
  const [isObserver, setIsObserver] = useState(false);
  const [isMicMuted, setIsMicMuted] = useState(false);
  const [isCameraOff, setIsCameraOff] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [localUid, setLocalUid] = useState<string | null>(null);

  const client = useRef<IAgoraRTCClient>(
    AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
  );
  const localVideoTrack = useRef<ILocalVideoTrack | null>(null);
  const localAudioTrack = useRef<ILocalAudioTrack | null>(null);

  const videoConfig: any = {
    encoderConfig: {
      width: 640,
      height: 360,
      frameRate: 30,
      bitrate: 1000,
    },
    optimizationMode: "detail",
  };

  const joinChannel = async (role: "guest" | "observer") => {
    if (role === "observer") {
      setIsObserver(true);
    }

    // ! IN PROGRESS
    const uid = await client.current.join(APP_ID, CHANNEL_NAME, TOKEN, null);
    setLocalUid(uid.toString());

    if (role === "guest") {
      // Pass video config when creating camera track
      localVideoTrack.current = await AgoraRTC.createCameraVideoTrack(
        videoConfig
      );
      localAudioTrack.current = await AgoraRTC.createMicrophoneAudioTrack();
      await client.current.publish([
        localVideoTrack.current,
        localAudioTrack.current,
      ]);

      setTimeout(() => {
        const localContainer = document.getElementById("local-video");
        if (localContainer && localVideoTrack.current) {
          localVideoTrack.current.play(localContainer, { fit: "contain" });
        }
      }, 100);
    }

    setIsJoined(true);
  };

  const leaveChannel = async () => {
    if (localVideoTrack.current) {
      localVideoTrack.current.stop();
      localVideoTrack.current.close();
      navigate("/access-meeting");
      dispatch(clearCheckWhoJoined());
      dispatch(clearCheckVisitID());
      localStorage.removeItem("onGoingMeeting");
      localStorage.removeItem("joinedAsObserver");
      localStorage.removeItem("joinedAsVisitor");
    }
    if (localAudioTrack.current) {
      localAudioTrack.current.stop();
      localAudioTrack.current.close();
    }
    await client.current.leave();
    setIsJoined(false);
    setRemoteUsers([]);
    setLocalUid(null);
  };

  const toggleMic = async () => {
    if (localAudioTrack.current) {
      await localAudioTrack.current.setEnabled(isMicMuted);
      setIsMicMuted(!isMicMuted);
    }
  };

  const toggleCamera = async () => {
    if (localVideoTrack.current) {
      await localVideoTrack.current.setEnabled(isCameraOff);
      setIsCameraOff(!isCameraOff);
    }
  };

  useEffect(() => {
    const handleUserPublished = async (
      user: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      await client.current.subscribe(user, mediaType);

      if (mediaType === "video") {
        setRemoteUsers((prevUsers) => {
          if (!prevUsers.find((u) => u.uid === user.uid)) {
            return [...prevUsers, user];
          }
          return prevUsers;
        });

        setTimeout(() => {
          if (isObserver) {
            const remoteContainer = document.getElementById(
              `remote-video-${user.uid}`
            );
            if (remoteContainer && user.videoTrack) {
              // Set proper video scaling for observer view
              user.videoTrack.play(remoteContainer, { fit: "contain" });
            }
          } else {
            const remoteContainer = document.getElementById("remote-container");

            if (remoteContainer && user.videoTrack) {
              setIsGestJoined(true);
              user.videoTrack.play(remoteContainer, { fit: "contain" });
            }
          }
        }, 100);
      }

      if (mediaType === "audio" && user.audioTrack) {
        user.audioTrack.play();
      }
    };

    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers((prevUsers) =>
        prevUsers.filter((u) => u.uid !== user.uid)
      );
    };

    client.current.on("user-published", handleUserPublished);
    client.current.on("user-left", handleUserLeft);

    return () => {
      client.current.off("user-published", handleUserPublished);
      client.current.off("user-left", handleUserLeft);
    };
  }, [isObserver]);

  useEffect(() => {
    if (guestData) {
      joinChannel("guest");
      localStorage.setItem("onGoingMeeting", "YES");
    }
  }, [guestData]);

  useEffect(() => {
    const joinedAsObserver = localStorage.getItem("joinedAsObserver");
    if (joinedAsObserver === "YES" && !guestData) {
      joinChannel("observer");
    }
  }, [guestData]);

  useEffect(() => {
    const joinedAsVisitor = localStorage.getItem("joinedAsVisitor");
    if (joinedAsVisitor === "YES") {
      joinChannel("guest");
      localStorage.setItem("onGoingMeeting", "YES");
    }
  }, []);

  useEffect(() => {
    const checkMeetingStatus = localStorage.getItem("onGoingMeeting");
    if (checkMeetingStatus == "YES") {
      navigate("/start-meeting");
    }
  }, []);

  const renderObserverView = () => (
    <div className="video-grid">
      {remoteUsers.map((user) => (
        <div key={user.uid} className="video-grid-item">
          <div id={`remote-video-${user.uid}`} className="video-feed"></div>
          <div className="user-name">Guest {user.uid}</div>
        </div>
      ))}
      <div className="observer-info">Observing as {localUid}</div>
    </div>
  );

  const renderGuestView = () => (
    <div className="video-container">
      <div id="remote-container" className="remote-video-box">
        <div id="local-video" className="local-video-overlay">
          <div className="user-name">Guest {localUid}</div>
          <div className="video-controls">
            <button
              onClick={toggleMic}
              className={`control-icon ${isMicMuted ? "muted" : ""}`}
            >
              {isMicMuted ? "🎤" : "🔇"}
            </button>
            <button
              onClick={toggleCamera}
              className={`control-icon ${isCameraOff ? "camera-off" : ""}`}
            >
              {isCameraOff ? "📹" : "🚫"}
            </button>
          </div>
        </div>
        {!isGuestJoined &&
          guest === "inmate" &&
          guestData &&
          remoteUsers.length <= 1 && (
            <Flex gap="small" justify="space-around">
              <span className="guest-title">We are waiting for</span>
              <span className="guest-name">
                {guestData?.visitor_first_name}
              </span>
              <span className="guest-name">{guestData?.visitor_last_name}</span>
              <span className="guest-title">to join</span>
            </Flex>
          )}

        {!isGuestJoined &&
          guest === "visitor" &&
          guestData &&
          remoteUsers.length <= 1 && (
            <Flex gap="small" justify="space-around">
              <span className="guest-title">We are waiting for</span>
              <span className="guest-name">{guestData?.first_name}</span>
              <span className="guest-name">{guestData?.last_name}</span>
              <span className="guest-title">to join. </span>
              <span className="guest-title">
                His code is {guestData.inmate_code}
              </span>
            </Flex>
          )}
      </div>
    </div>
  );

  return (
    <div className="video-call-app">
      <header className="video-call-header">
        <Flex justify={"space-between"} style={{ width: "100%" }}>
          <span className="bold-text" style={{ color: "#333" }}>
            Ehuza Video Call
          </span>
          {isObserver ? <Tag color={"green"}>Observer Mode</Tag> : ""}
        </Flex>
        {localUid && <div className="user-id">Your ID: {localUid}</div>}
        <div className="controls">
          {isJoined && (
            <>
              {!isObserver && (
                <>
                  <button
                    onClick={toggleMic}
                    className={`control-button ${isMicMuted ? "muted" : ""}`}
                  >
                    {isMicMuted ? "Unmute Mic" : "Mute Mic"}
                  </button>
                  <button
                    onClick={toggleCamera}
                    className={`control-button ${
                      isCameraOff ? "camera-off" : ""
                    }`}
                  >
                    {isCameraOff ? "Turn Camera On" : "Turn Camera Off"}
                  </button>
                </>
              )}
              <button
                onClick={leaveChannel}
                className="control-button leave-button"
              >
                Leave
              </button>
            </>
          )}
        </div>
      </header>
      {isJoined && (isObserver ? renderObserverView() : renderGuestView())}
    </div>
  );
};
export default VideoCall;
