import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import { Button, Select, Spin, Table, Empty, Flex } from "antd";
import {
  getPublicItemsData,
  getTransactionReportData,
} from "../../../redux/actions";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

// Custom Hook for Redux Selector
const useUserSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const AllTransactions: React.FC = () => {
  const dispatch = useDispatch();
  const [prisonName, setPrisonName] = useState<string>("");

  const { getTransactionsReport, getPublicItem } = useUserSelector(
    (state) => state
  );
  const publicItems = getPublicItem?.data?.data?.facilities;

  // Define columns for the table
  const columns: ColumnsType<any> = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "RSC Case number",
      dataIndex: "inmateCode",
      key: "inmateCode",
    },
    {
      title: "Received Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];

  const transactionData =
    getTransactionsReport?.data?.data?.all_transactions?.map((item: any) => ({
      firstName: item?.sender_first_name,
      lastName: item?.sender_last_name,
      contact: item?.sender_reference,
      inmateCode: item?.inmate_code,
      totalAmount: `${item?.incoming_amount}`,
    }));

  const handleFacilityChange = (value: string) => setPrisonName(value);

  const handleSearch = () => {
    if (prisonName) {
      dispatch(getTransactionReportData({ prison_name: prisonName }));
    }
  };

  useEffect(() => {
    dispatch(getPublicItemsData());
  }, [dispatch]);

  return (
    <Flex align="center" vertical>
      <Flex
        align="center"
        justify="flex-end"
        style={{
          width: "100%",
          background: "#fff",
          padding: "8px",
          borderBottom: "1px solid #d8d8d8",
        }}
      >
        <Flex gap="small" align="center">
          <Select
            showSearch
            placeholder="Select a correctional facility"
            optionFilterProp="children"
            onSearch={(value) => value}
            filterOption={(input, option) =>
              (option?.label as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={handleFacilityChange}
            options={publicItems}
            style={{ width: "200px", textAlign: "left" }}
          />
          <Button
            ghost
            type="primary"
            icon={<SearchOutlined />}
            loading={getTransactionsReport?.loading}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Flex>
      </Flex>

      {getTransactionsReport?.loading ? (
        <div style={{ textAlign: "center", marginTop: "20%" }}>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
            style={{ color: "#282936" }}
          />
        </div>
      ) : transactionData?.length ? (
        <Table
          columns={columns}
          dataSource={transactionData}
          pagination={transactionData?.length >= 10 ? undefined : false}
          style={{ width: "100%" }}
          size="small"
        />
      ) : (
        <Flex
          align="center"
          style={{ width: "100%", padding: "12px" }}
          vertical
        >
          <Empty
            description="No amount to show yet"
            style={{ marginTop: "auto 10%" }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default AllTransactions;
