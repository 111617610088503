import {
  GET_PRODUCT_FAIL,
  GET_PRODUCT_START,
  GET_PRODUCT_SUCCESS,
} from "../../../constants/actionType/shop";

export interface ISGetProductData {
  data: any;
  error: any;
}

export const getProductData = (data: any) => ({
  type: GET_PRODUCT_START,
  data,
});

export const getProductSuccess = (data: ISGetProductData) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: data,
});

export const getProductFail = (error: ISGetProductData) => ({
  type: GET_PRODUCT_FAIL,
  payload: error,
});
