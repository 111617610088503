import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import ContainerLayout from "../../common/containerLayout";
import { completedVisitData } from "../../../redux/actions";
import { IAppointment } from "../../../interfaces/appointment";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import NoResultFound from "../../common/noResultFound";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface DataType {
  name: string;
  age: string | number;
  visitDate: string;
  visitTime: string;
  visitType: string;
  visitMethod: string;
  status: string;
  visitorContact: string;
  visitNationalID: number | string;
  gender: string;
  visitorPassport: string;
}

const CompletedVisit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { completedVisit } = userSelector((state) => state);
  const visitData = completedVisit?.completedVisitData?.data;

  const columnData = visitData?.map((visit: IAppointment) => {
    return {
      name: `${visit.visitor_first_name} ${visit.visitor_last_name}`,
      age: visit.visitor_age,
      visitDate: visit.visit_date,
      visitTime: visit.visit_time,
      visitType: visit.appointment_type,
      visitMethod: visit.appointment_category,
      status: visit.status,
      visitorContact: visit.visitor_contacts,
      visitNationalID: visit.visitor_identification,
      visitorPassport: visit.visitor_passport,
      gender: `${visit.visitor_gender === "F" ? "Female" : "Male"}`,
    };
  });

  const columnText: ColumnsType<DataType> = [
    {
      title: "Visit name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 150,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      width: 200,
    },

    {
      title: "Contact",
      dataIndex: "visitorContact",
      key: "visitorContact",
      width: 150,
    },

    {
      title: "National ID No.",
      dataIndex: "visitNationalID",
      key: "visitNationalID",
      width: 150,
    },
    {
      title: "Passport No.",
      dataIndex: "visitorPassport",
      key: "visitorPassport",
      width: 150,
    },

    {
      title: "Visit method",
      dataIndex: "visitMethod",
      key: "visitMethod",
      width: 150,
    },
  ];

  useEffect(() => {
    dispatch(completedVisitData());
  }, []);

  return (
    <>
      <ContainerLayout
        btnBtn={
          <div className="container-back-btn">
            <Button
              className="btnIcon"
              onClick={() => navigate("/confirm-visit")}
            >
              <span className="icon">
                <BsArrowLeft size="22" />
              </span>
            </Button>
            <span className="back-btn-text">All inmates</span>
          </div>
        }
        leftTopItem={undefined}
      >
        {columnData.length !== 0 ? (
          <>
            {" "}
            <Table
              size="middle"
              columns={columnText}
              dataSource={columnData}
              pagination={columnData?.length >= 30 ? undefined : false}
            />
          </>
        ) : (
          <NoResultFound />
        )}
      </ContainerLayout>
    </>
  );
};

export default CompletedVisit;
