const GlobalLanguages = {
    "Kinyarwanda": {
        "English": "Icyongereza",
        "Physical visit": "Gusura imbona nkubone",
        "Video visit": "Gusura hakoreshejwe video",
        "Join video meeting": "Guhurira munama hakoreshejwe video",
        "Connecting families": "Guhuza imiryango",
        "About Us": "Ibyerekeranye na twe",
        "Contact Us": "Twandikire",
        "How it works": "Bikorabite",
        "Terms && conditions": "Amategeko n'amabwiriza",
        "Social Media": "Imbuga nkoranya mbaga",
        "Follow Us on": "Dukurikire kuri",
        "You are booking a physical Appointment": "Urimo kubitsa umwanya wo kuzasura imbonankubone",
        "Go back": "Subira inyuma",
        "Inmate information": "Amakuru yinfumgwa",
        "Use inmate case number or parent's names": "Koresha nomero yogusuriraho umugororwae wa RCS cyangwa amazina yababyeyi",
        "Inmate first name or last name": "Izina bwite ryumugororwa cyangwa rusange",
        "Visit type": "Uburyo bwogusura",
        "Select visit date": "Hitamo umunsi wogusura",
        "Select visit time": "Hitamo isaha yogusuriraho",
        "Proceed": "Komeza",
        "Visitor information": "Amakuru yusuye",
        "First name": "Izina bwite",
        "Last name": "Izina rusange",
        "National ID No.": "Inomero y'irangamuntu",
        "Next": "Ibikurikira",
        "Phone number": "Numero ya terefone",
        "Choose tariff" : "Hitamo igiciro",
        "Select your gender": "Hitamo igitsina cyawe",
        "Your age": "Imyaka yawe",
        "Other documents": "Izindi nyandiko",
        "Female": "Umugore",
        "Male": "Umugabo",
        "Enter inmate case number or parent names":"Andika nomero y'umugororwa wa RCS cyangwa amazina yabayeyi",
        "Enter inmate first name or last name":"Andika izina bwite ry'umugororwa cyangwa izina rusange",
        "Enter type":"Andika uburyo bwogusura",  
        "Select a date":"Shyiraho itariki yogusuriraho",
        "Send money":"Kohereza amafaranga",
        "Inmate code or parents names":"Nomero yumugororwa cyangwa amazina yababyeyi",
        "Enter inmate code or parents names":"Andika nomero yumugororwa cyangwa amazina yababyeyi", 
        "Your name":"Amazina yawe",
        "Enter your first name":"Andika izina bwite ryawe",
        "Amount":"Umubare wamafaranga",
        "Enter amount":"Andika  umubare wamafaranga",
        "Telco":"Umurongo wo gukoresha",
        "Select mobile operator":"Hitamo umurongo ukoresha wa mobile", 
        "Enter first name":"Andika izina bwite",
        "Enter last name":"Andika izina rusange",
        "Select gender":"Hitamo igitsina",
        "Enter your birth date":"Andika itariki yamavuko",
        "Enter national ID number":"Andika nomero yirangamuntu",
        "Enter other documents":"Andika izindi nyandiko",
        "Select tariff":"Hitamo ibiciro",
        "Select date":"Hitamo itariki",
        "Select time":"Hitamo isaha",
        "Select network operator":"Hitamo umurongo ukoresha",
        "Enter your phone number":"Andika nomero ya terefone",
        "Inmate not found": "Umugororwa ntiyagaragaye",
        "Normal":"Bisanzwe",
        "Special":"Bidasanzwe",
        "Lawyers":"Umushinja cyaha",
        "Inmate RSC case number or parent's names not found":"Nomero yumugororwa cyangwa amazina yababyeyi ntibiri kuboneka",
        "Send":"Kohereza",
        "Check your camera and microphone before starting":"Genzura kamera yawe na mikorofone mbere yogutangira",
        "Join meeting":"Injira munama",
        "Meeting ID":"Imyirondoro y' inama",   
        "Enter meeting ID":"Andika imyirondoro yinama",
        "Join":"Injira",
        "Book an appointment":"Kubitsa igihe cya gahunda",
        "Support":"Ubufasha",
        "Enter your last name":"Andika izina rusange",
        "Please provide meeting ID you have been sent while booking your appointment.":"Andika kode wohererejwe usaba gahunda",
        "Visit ID is not correct, please try again": "Kode yogusuriraho ntabwo ariyo gerageza indi.",
        "Login to your eHuza account":"Shyiramo konte yawe ya eHuza",
        "Correctional facilities":"Ikigo ngororamuco",
        "Inmates in country":"Ingororwa zo mugihugu",
        "Incoming amount":"Amafaranga yinjiye",
        "Outgoing":"Amafaranga yasohotse",
        "Finance Report":"KUgaragaza umutungo uhari",
        "Inmate per facility":"Umugororwa kuri buri kigo",
        "Balance":"Amafaranga ahari",
        "Number of inmates":"Umubare wabagororwa",
        "All facilities":"Ibigo ngororamuco byose hamwe",
        "Active faciliyies":"Ibigo ngororamuco birimo gukora",
        "Disabled facility":"Ibigo ngororamuco bidakora",
        "Action":"Igikorwa",
        "Name":"Izina",
        "Province":"Akarere",
        "Status": "Imiterere",
        "Location": "Ahantu",
        "All visits": "Abasuye bose",
        "Virtual visits": "Gusura byikorana buhanga",
        "Physical visits":"Gusura imbona nkubone",
        "Specials":"Bidasanzwe",
        "Individuals":"Bwite",
        "case number":"Nimero yurubanza",
        "Visitor name":"Izina ryusuye",
        "Visitor contact":"Nomero yusuye",
        "Visit method":"Uburyo bwo gusura",
        "Father names":"Amazina yase",
        "Mother names":"Amazina yanyina",
        "All inmates":"Abagororwa bose",
        "Critical case":" Urubanza rukomeye",
        "Normal case":" Urubanza rusanzwe",
        "Select correctional facility":"Itamo ikigo ngororamuco",
        "Select":"Hitamo",
        "Please select":"Nyabuneka hitamo",
        "Search":"Shakisha",
        "Inmates wallets":"Umufuka w'abagororwa",
        "Inmates per amount":"Buri mugororwa namafaranga ye",
        "Total wallets amount":"Umubare wose wamafaranga ",
        "Inmates with wallet":"Abagororwa bafite amafaranga",
        "Incoming amounts":"Amafaranga winjiye",
        "Senders transactions":"Abohereje",
        "Money consumption details":"Uburyo amafaranga yakoreshejwe",
        "Received amount":"Amafaranga yakiriwe",
        "Accounts":"Konti",
        "All users":"Abakoresha bose",
        "Role":"Uruhare",
        "Email":"Imeri",
        "Add new user":"Ongera umukoresha",
        "Personal information":"Amakuru yihariye",
        "User role":"Uruhare",
        "Security":"Umutekano",
        "Update information":"Kuvugurura amakuru",
        "Change password":"Hingura umubare wibanga",
        "After changing your password you will be logout":"Nyuma yoguhindura umubare wibanga urahita usohokamo",
        "Old password":"Umubare wibanga wakera",
        "New password": "Umubare wibanga mushya",
        "Enter old password":"Andika umubare wibanga wakera",
        "Enter new password":"Andika umubare wawe wibanga mushya",
        "Apply change":"Koresha impinduka",
        "Profile":"Umwirondoro",
        "Logout":"Sohokamo",
        "Transaction":"Kwohereza",
        "List":"Urutonde",
        "Manage accounts":"Gucunga konti",
        "Dashboard":"Bikubiyemo",
        "Wallets":"Umufuka",
        "Pending appointment": "Gahunda itariyakorwa",
        "Pending": "Bitegereje",
        "Completed": "Ibyakozwe",
        "Rejected": "Ibitaremewe",
        "Outgoing appointment": "Gahunda yoherejwe",
        "Icomming appointment": "Gahunda zabonetse",
        "Today": "Uyumunsi",
        "Total inmates": "Abagororwa bose",
        "Upcomming physical appointment": "Gahunda zigiye gukorwa",
        "Inmate number": "Umubare w'abagororwa",
        "Language":"Ururimi",
        "Products": "Ibicuruzwa",
        "Reports": "Raporo",
        "Shop orders": "Ibyatumijwe",
        "Meeting": "Inama",
        "Services": "Serivisi",

    }

}
export default GlobalLanguages;
