export interface ITariffTypes {
    value: string;
    label: string;
    key: string;
    description: string;
}

export const tariffTypes: ITariffTypes[] = [
    {  
        value: "100",
        label: "100 Frw",
        key: "normal",
        description: "Cost for 10 minutes"
    } ,
    {
        value: "200",
        label: "200 Frw",
        key: "special",
        description: "Cost for 10 minutes"
    },
    {
        value: "300",
        label: "300 Frw",
        key: "lawyer"
        ,description: "Cost for 10 minutes"
    }         
]

export const physicalAppointmentTariffTypes = [
    {  
        value: "200",
        label: "200 Frw"
    } ,
]
