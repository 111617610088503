import React from "react";
import { Tabs } from "antd";
import AllTransactions from "./allTransactions";
import SenderTransactionsHistory from "./senderTransactionsHistory";
import DetailsOnMoney from "./detailsOnMoney";

const { TabPane } = Tabs;

const SuperAdminTransactionsReport: React.FC = () => {
  return (
    <div className="dashboard-container__box">
      <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
        <TabPane tab="Incoming amounts" key="1">
          <AllTransactions />
        </TabPane>
        <TabPane tab="Senders transactions" key="2">
          <SenderTransactionsHistory />
        </TabPane>
        <TabPane tab="Money Consumption details" key="3">
          <DetailsOnMoney />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SuperAdminTransactionsReport;
