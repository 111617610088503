/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import { retrievePurchaseOrderListData } from "../../../redux/actions";
import moment from "moment";
import Table, { ColumnsType } from "antd/es/table";
import { TableDataType } from "../OrderFiles";
import { Button, Spin, Tag } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DAILY_ORDER_FILE } from "../../../constants/general";
import { useNavigate } from "react-router-dom";

const useTypedSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const filterDefaultData = {
  order_file_type: "all",
};

const PurchaseOrderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { retrievePurchaseOrderList } = useTypedSelector((state) => state);

  const columns: ColumnsType<any> = [
    {
      title: "File number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Cell",
      dataIndex: "cell_name",
      key: "cell_name",
    },
    {
      title: "File type",
      dataIndex: "order_file_type",
      key: "order_file_type",
      render: (type: string) => (
        <Tag color={parseInt(type) === DAILY_ORDER_FILE ? "cyan" : "blue"}>
          {parseInt(type) === DAILY_ORDER_FILE
            ? "Food Order file"
            : "Shop Order file"}
        </Tag>
      ),
    },
    {
      title: "Items",
      dataIndex: "product_number",
      key: "product_number",
    },
    {
      title: "Status",
      dataIndex: "actions_status",
      key: "actions_status",
      render: (status: string) =>
        status === "executed" ? (
          <Tag color="green">Booked</Tag>
        ) : status === "approved" ? (
          <Tag color="blue">Booked</Tag>
        ) : (
          <Tag color="orange">Pending</Tag>
        ),
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt: string) => moment(createdAt).format("DD-MMM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, data, index) => {
        return (
          <Button
            key={index}
            type="primary"
            onClick={() => displayPurchaseOrderDetails(data?.id)}
            ghost
          >
            Details
          </Button>
        );
      },
    },
  ];

  const newDataCol = retrievePurchaseOrderList?.data?.map(
    (item: TableDataType, index: number) => ({
      key: index,
      id: item.order_id,
      number: item.number,
      cell_name: item.cell_name,
      actions_status: item.actions_status,
      created_at: item.created_at,
      current_user_role: item.current_user_role,
      steps: item.steps,
      document: item.document,
      order_file_type: item.order_file_type,
      product_number: item.product_number,
    })
  );

  const displayPurchaseOrderDetails = (order_id: number) => {
    navigate("/purchase-order-details", {
      state: { orderFileId: order_id },
    });
  };

  useEffect(() => {
    dispatch(retrievePurchaseOrderListData(filterDefaultData));
  }, [filterDefaultData]);

  return (
    <>
      {!retrievePurchaseOrderList.loading ? (
        <Table
          columns={columns}
          dataSource={newDataCol}
          size="small"
          pagination={newDataCol?.length > 14 ? undefined : false}
        />
      ) : (
        <div style={{ margin: "20% auto", height: "800px" }}>
          <Spin
            style={{ color: " #282936" }}
            indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
          />
        </div>
      )}
    </>
  );
};

export default PurchaseOrderList;
