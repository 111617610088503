import axios from "axios";
import { token } from "../middleware/authMiddleware";

const REACT_APP_URL_API =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_URL_API_PROD
    : process.env.REACT_APP_URL_API_DEV;

const VIDEO_MEETING_BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_URL_VIDEO_HTTP_BACKEND_PROD
    : process.env.REACT_APP_URL_VIDEO_HTTP_BACKEND;

const apiRequest = async ({ requestMethod, url, data = null }: any) => {
  const config = {
    headers: { Authorization: `Bearer ` + token },
  };

  try {
    if (requestMethod === "GET") {
      const response =
        token === undefined || token === ""
          ? await axios.get(`${REACT_APP_URL_API}/${url}`)
          : await axios.get(`${REACT_APP_URL_API}/${url}`, config);
      return response.data;
    } else if (requestMethod === "DELETE") {
      const response =
        token === undefined || token === ""
          ? await axios.post(
              `${REACT_APP_URL_API}/${url}`,
              JSON.stringify(data)
            )
          : await axios.post(
              `${REACT_APP_URL_API}/${url}`,
              JSON.stringify(data),
              config
            );
      return response.data;
    } else {
      const response =
        token === undefined || token === ""
          ? await axios.post(`${REACT_APP_URL_API}/${url}`, data)
          : await axios.post(`${REACT_APP_URL_API}/${url}`, data, config);
      return response.data;
    }
  } catch (e: any) {
    return e.response;
  }
};

export const videoAPIRequest = async ({
  requestMethod,
  url,
  data = null,
}: any) => {
  const config = {
    headers: { Authorization: `Bearer ` + token },
  };

  try {
    if (requestMethod === "GET") {
      const response =
        token === undefined || token === ""
          ? await axios.get(`${VIDEO_MEETING_BACKEND_URL}${url}`)
          : await axios.get(`${VIDEO_MEETING_BACKEND_URL}${url}`, config);
      return response.data;
    }

    if (requestMethod === "POST") {
      const response = await axios.post(
        `${VIDEO_MEETING_BACKEND_URL}${url}`,
        data
      );
      return response.data;
    }
  } catch (e: any) {
    return e.response;
  }
};

export default apiRequest;
