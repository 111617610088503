/* eslint-disable @typescript-eslint/no-empty-interface */
import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_START,
} from "../../../constants/actionType/shop";
import { saveData } from "../../../helpers";

export interface ResponseGenerator {}

function* getProduct(data: any) {
  try {
    const userData: ResponseGenerator = yield call(
      saveData,
      "shop/retrieve-product-item",
      data?.data
    );
    yield put({ type: GET_PRODUCT_SUCCESS, data: userData });
  } catch (e: any) {
    yield put({ type: GET_PRODUCT_FAIL, error: e.message });
  }
}

export default function* fetchGetProduct() {
  yield takeEvery(GET_PRODUCT_START, getProduct);
}
