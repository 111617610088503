/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input, Select, Button, Modal, Form, Flex, Divider } from "antd";
import { IUserSelectorType } from "interfaces";
import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  saveNewProductData,
  clearSaveProductData,
  getProductData,
  getAllSupplierData,
} from "redux/actions";
import { initialProductData } from "..";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const NewProduct = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [suppliers, setSuppliers] = useState<any>();
  const [supplier, setSupplier] = useState<any>();

  const { saveNewProduct, getAllSupplier } = userSelector((user) => user);

  const onHandleSubmit = () => {
    const formData = form.getFieldsValue();
    const data = {
      item: formData.article,
      unit_price: 0,
      quantity: 0,
      supplier_id: suppliers || "",
    };

    dispatch(saveNewProductData({ data: [data] }));
  };

  const onHandleChangeUserRole = (value: string) => setSuppliers(value);

  const onSearch = (value: string) => {
    return value;
  };

  useEffect(() => {
    if (saveNewProduct.data?.success) {
      toast.success(saveNewProduct?.message);
      dispatch(clearSaveProductData());
      dispatch(getProductData(initialProductData));
      onClose();
      form.resetFields();
    }
  }, [saveNewProduct.data, saveNewProduct?.error]);

  useEffect(() => {
    if (getAllSupplier.data?.data) {
      setSupplier(
        getAllSupplier?.data?.data?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        }))
      );
    }
  }, [getAllSupplier.data]);

  useEffect(() => {
    dispatch(getAllSupplierData());
  }, [dispatch]);

  return (
    <>
      <Modal
        title="New Product"
        open={open}
        onCancel={() => {
          onClose();
          dispatch(clearSaveProductData());
        }}
        footer={
          <Flex justify="space-between">
            <Button
              danger
              type="primary"
              ghost
              onClick={() => {
                onClose();
                dispatch(clearSaveProductData());
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onHandleSubmit}
              loading={saveNewProduct?.loading}
              ghost
            >
              Save
            </Button>
          </Flex>
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="article"
            label="Product name"
            style={{ margin: "6px" }}
          >
            <Input
              type="text"
              placeholder="Enter product name"
              name="article"
            />
          </Form.Item>

          <Form.Item label="Supplier" style={{ margin: "6px" }}>
            <Select
              loading={getAllSupplier.loading}
              showSearch
              placeholder="Select supplier"
              optionFilterProp="children"
              onSearch={onSearch}
              filterOption={(input, option) => {
                const val: any = option?.label ?? "";
                return val.toLowerCase().includes(input.toLowerCase());
              }}
              onChange={onHandleChangeUserRole}
              options={supplier}
            />
          </Form.Item>
          <Divider />
        </Form>
      </Modal>
    </>
  );
};

export default NewProduct;
