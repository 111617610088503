/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Button, Skeleton, Divider } from "antd";
import {
  MdCalendarToday,
  MdManageAccounts,
  MdOutlineDashboardCustomize,
  MdVideocam,
  MdOutlineMiscellaneousServices,
  MdOutlineViewAgenda,
  MdProductionQuantityLimits,
} from "react-icons/md";
import { GiMoneyStack, GiPrisoner } from "react-icons/gi";
import { BsClipboardCheck, BsShop } from "react-icons/bs";
import { AiFillProfile } from "react-icons/ai";
import { TbPrison, TbReport } from "react-icons/tb";
import { HiHome } from "react-icons/hi";
import { FaHistory } from "react-icons/fa";
import { LogoutOutlined } from "@ant-design/icons";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IUserSelectorType } from "../../../../interfaces";
import { getNotificationData, readNotificationData } from "redux/actions";
import "./index.css";
import { appName } from "constants/defaultLabel";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const SubNavBar = ({ userData, logout, loadingLogout }: any) => {
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const dispatch = useDispatch();
  const [openReportSubNav, setOpenReportSubNav] = useState(false);
  const [openInmatesSubNav, setOpenInmatesSubNav] = useState(false);
  const [openSupInmatesSubNav, setOpenSupInmatesSubNav] = useState(false);
  const [openServices, setOpenServices] = useState(false);

  const {
    getNotifications: { data },
  } = userSelector((user) => user);

  const readNotificationBtn = () => {
    dispatch(
      readNotificationData({
        data: data?.data?.notificationData,
      })
    );
  };

  useEffect(() => {
    dispatch(getNotificationData());
  }, [dispatch]);

  const usersData = userData?.[0] || {};

  const renderMenu = () => {
    if (usersData?.role_id === 1) {
      return (
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[splitLocation[1]]}
          style={{ width: 220 }}
        >
          <Menu.Item
            key="general-dashboard"
            icon={<MdOutlineDashboardCustomize size={18} />}
          >
            <Link to="/general-dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="facilities" icon={<TbPrison size={18} />}>
            <Link to="/facilities">Facility</Link>
          </Menu.Item>
          <Menu.Item
            key="facility-appointments"
            icon={<MdCalendarToday size={18} />}
          >
            <Link to="/facility-appointments">Booking</Link>
          </Menu.Item>
          <Menu.SubMenu
            key="inmates"
            title="Inmates"
            icon={<GiPrisoner size={18} />}
            onTitleClick={() => setOpenSupInmatesSubNav(!openSupInmatesSubNav)}
          >
            <Menu.Item key="facility-inmates-list">
              <Link to="/facility-inmates-list">List</Link>
            </Menu.Item>
            <Menu.Item key="facility-inmates-wallets">
              <Link to="/facility-inmates-wallets">Wallets</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item
            key="facility-transactions"
            icon={<GiMoneyStack size={18} />}
          >
            <Link to="/facility-transactions">Transactions</Link>
          </Menu.Item>
          <Menu.Item
            key="manage-accounts"
            icon={<MdManageAccounts size={18} />}
          >
            <Link to="/manage-accounts">Manage accounts</Link>
          </Menu.Item>
          <Menu.Item key="activity-log" icon={<FaHistory size={18} />}>
            <Link to="/activity-log">Activity Log</Link>
          </Menu.Item>
        </Menu>
      );
    }

    if ([2, 3, 5, 6, 7].includes(usersData?.role_id)) {
      return (
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[splitLocation[1]]}
          style={{ width: 220 }}
        >
          <Menu.Item key="dashboard" icon={<HiHome size={18} />}>
            <Link to="/dashboard" onClick={readNotificationBtn}>
              Dashboard
            </Link>
          </Menu.Item>
          {[2, 7, 3, 5].includes(usersData.role_id) && (
            <Menu.Item
              key="appointment"
              icon={<MdOutlineViewAgenda size={18} />}
            >
              <Link to="/appointment">Appointment</Link>
            </Menu.Item>
          )}
          <Menu.SubMenu
            key="inmates"
            title="Inmates"
            icon={<GiPrisoner size={18} />}
            onTitleClick={() => setOpenInmatesSubNav(!openInmatesSubNav)}
          >
            {[2, 5, 6, 7].includes(usersData?.role_id) && (
              <Menu.Item key="inmates-list">
                <Link to="/inmates-list">List</Link>
              </Menu.Item>
            )}
            {[2, 3, 5, 7].includes(usersData?.role_id) && (
              <Menu.Item key="inmates-wallets">
                <Link to="/inmates-wallets">Wallets</Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
          {[2, 3, 5, 7].includes(usersData.role_id) && (
            <Menu.SubMenu
              key="services"
              title="Services"
              icon={<MdOutlineMiscellaneousServices size={18} />}
              onTitleClick={() => setOpenServices(!openServices)}
            >
              <Menu.Item key="out-going-money">
                <Link to="/out-going-money">Transfers</Link>
              </Menu.Item>
              <Menu.Item key="bulk-saving">
                <Link to="/bulk-saving">10% Income</Link>
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {[2, 6, 7].includes(usersData?.role_id) && (
            <Menu.SubMenu
              key="reports"
              title="Reports"
              icon={<TbReport size={18} />}
              onTitleClick={() => setOpenReportSubNav(!openReportSubNav)}
            >
              <Menu.Item key="appointments-reports">
                <Link to="/appointments-reports">Booking</Link>
              </Menu.Item>
              <Menu.Item key="transactions-report">
                <Link to="/transactions-report">Transactions</Link>
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {[6, 5].includes(userData.role_id) && (
            <Menu.Item
              key="confirm-visit"
              icon={<BsClipboardCheck size={18} />}
            >
              <Link to="/confirm-visit">Confirm Visit</Link>
            </Menu.Item>
          )}
          {[5].includes(usersData?.role_id) && (
            <Menu.Item
              key="shop-products"
              icon={<MdProductionQuantityLimits size={18} />}
            >
              <Link to="/shop-products">Products</Link>
            </Menu.Item>
          )}
          <Menu.Item key="orders" icon={<BsShop size={18} />}>
            <Link to="/orders" onClick={readNotificationBtn}>
              Shop Orders
            </Link>
          </Menu.Item>
          {[2, 5, 6, 7].includes(usersData?.role_id) && (
            <Menu.Item key="video-visits" icon={<MdVideocam size={18} />}>
              <Link to="/video-visits">Video Visits</Link>
            </Menu.Item>
          )}
          {[2, 7].includes(usersData?.role_id) && (
            <Menu.Item
              key="manage-accounts"
              icon={<MdManageAccounts size={18} />}
            >
              <Link to="/manage-accounts">Manage Accounts</Link>
            </Menu.Item>
          )}
        </Menu>
      );
    }

    if (usersData?.role_id === 4) {
      return (
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={[splitLocation[1]]}
          style={{ width: 220 }}
        >
          <Menu.Item
            key="shop-products"
            icon={<MdProductionQuantityLimits size={18} />}
          >
            <Link to="/shop-products">Products</Link>
          </Menu.Item>
          <Menu.Item key="orders" icon={<BsShop size={18} />}>
            <Link to="/shop-seller" onClick={readNotificationBtn}>
              Shop Orders
            </Link>
          </Menu.Item>
        </Menu>
      );
    }

    return (
      <>
        {[...Array(11)].map((_x, i) => (
          <Skeleton.Button
            active
            size="default"
            shape="default"
            block={false}
            style={{ width: "100%", marginBottom: "25px" }}
            key={i}
          />
        ))}
      </>
    );
  };

  return (
    <div className="side-bar__container">
      <div className="img-content__item">
        <span>{appName}</span>
      </div>
      <div className="side-bar__container-box-content">{renderMenu()}</div>
      <Divider style={{ background: "white", margin: "8px" }} />
      <div style={{ paddingLeft: "12px", paddingRight: "12px" }}>
        <Link to="/user-profile" className="Icon-group">
          <AiFillProfile size={18} />
          <span>Profile</span>
        </Link>
        <Button
          type="text"
          style={{ width: "100%", textAlign: "left", color: "white" }}
          icon={loadingLogout === false ? <LogoutOutlined /> : null}
          loading={loadingLogout}
          onClick={logout}
        >
          <span>{loadingLogout ? `Please wait...` : `Logout`}</span>
        </Button>
      </div>
    </div>
  );
};

export default SubNavBar;
