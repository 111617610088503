/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Button, Flex, Popconfirm, Space, Spin, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  clearFacilityUserStore,
  getCorrectionalServiceData,
  getFacilityUserData,
  getUserDataInfo,
  updateFacilityUserData,
} from "../../../redux/actions";
import { roles } from "../../../constants/roles";
import CreateAccount from "./createAccount";
import { toast } from "react-toastify";

interface DataType {
  id: string;
  name: string;
  username: string;
  email: string;
  address: string;
  otherNames: any;
  userRoleId: number;
  district: string;
  province: string;
  status: any;
  roleId: any;
}

interface IUserRoles {
  name: string;
  rolesKey: number;
}

const UsersAccounts: React.FC<any> = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [changePage, setChangePage] = useState<string>("default");
  const { getUserData, getUserRoles, getFacilityUser, updateFacilityUser } =
    useSelector((user: any) => user);

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Role",
      dataIndex: "roleId",
      key: "roleId",
      render: (_, data: any) => {
        return roles.map((item: any) => {
          if (data?.roleId === item.rolesKey && data?.roleId !== roles) {
            return (
              <>
                <a color={"grey"}>{item?.name}</a>
              </>
            );
          }
        });
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, data: any) => (
        <>
          {data?.status === true ? (
            <Tag color={"green"}>{"Active"}</Tag>
          ) : (
            <Tag color={"red"}>{"Disable"}</Tag>
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, data: any) => (
        <Space
          size="middle"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          {data?.status === true ? (
            <Popconfirm
              title="Are you sure you want to disable this user?"
              onConfirm={() => disableFacility(data)}
              okText="Yes"
              cancelText="Cancel"
            >
              <a style={{ color: "red" }}>Disable</a>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Are you sure you want to activate this user?"
              onConfirm={() => activateFacility(data)}
              okText="Yes"
              cancelText="Cancel"
            >
              <a style={{ color: "green" }}>Activate</a>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const disableFacility = (data: any) => {
    const userData: any = {
      user_id: data?.id,
      blocked: true,
      unblocked: false,
    };
    dispatch(updateFacilityUserData(userData));
  };

  const activateFacility = (data: any) => {
    const userData: any = {
      user_id: data?.id,
      blocked: false,
      unblocked: true,
    };
    dispatch(updateFacilityUserData(userData));
  };

  const userRoles = getUserRoles?.data?.data?.map((item: IUserRoles) => {
    const data = { value: item?.rolesKey, label: item?.name };
    return data;
  });

  const onHandleOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  useEffect(() => {
    if (updateFacilityUser?.data?.data?.error) {
      toast(updateFacilityUser?.data?.data?.error?.description);
      dispatch(clearFacilityUserStore());
    }
  }, [updateFacilityUser?.data?.data?.error]);

  useEffect(() => {
    if (updateFacilityUser?.data?.data?.description) {
      toast(updateFacilityUser?.data?.message);
      dispatch(clearFacilityUserStore());
      dispatch(getFacilityUserData({ facility_id: "" }));
    }
  }, [updateFacilityUser?.data]);

  useEffect(() => {
    const newList: any = [];
    userRoles?.map((item: any) => {
      if (
        ["Super Admin", "Prison Admin", "DDCF", "DCF"].includes(item.label) !==
        true
      ) {
        newList.push({
          label: item?.label,
          value: item?.value,
        });
      }
    });
  }, [userRoles]);

  const data: DataType[] = getFacilityUser?.data?.data?.map((item: any) => {
    return {
      id: item?.id,
      name: `${item?.first_name} ${item?.last_name}`,
      email: item?.email,
      phone: item?.phone,
      birthDate: item?.birth_date,
      roleId: item?.role_id,
      username: item?.username,
      language: item?.language,
      status: item?.status,
    };
  });

  const prisonId =
    getUserData?.data?.data !== undefined
      ? getUserData?.data?.data[0].prison_id
      : undefined;

  useEffect(() => {
    dispatch(getUserDataInfo());
    dispatch(getCorrectionalServiceData());
    dispatch(getFacilityUserData({ facility_id: "" }));
  }, [dispatch]);

  return (
    <>
      {changePage === "default" ? (
        <>
          {prisonId !== undefined ? (
            <>
              {data?.length !== 0 ? (
                <Flex gap="middle" vertical style={{ padding: "8px" }}>
                  <Flex align="center" justify="space-between">
                    <span className="bold-text">All users</span>
                    <Button
                      ghost
                      type="primary"
                      onClick={onHandleOpenModal}
                      icon={<PlusOutlined />}
                    >
                      <span>New User</span>
                    </Button>
                  </Flex>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={data}
                    pagination={data?.length >= 16 ? undefined : false}
                  />
                </Flex>
              ) : (
                <span>No data found</span>
              )}
            </>
          ) : (
            <div style={{ margin: "20% auto", height: "800px" }}>
              <Spin
                style={{ background: " #282936" }}
                indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
              />
            </div>
          )}
        </>
      ) : null}

      <CreateAccount
        facilityId={prisonId}
        setChangePage={setChangePage}
        open={openModal}
        onCloseModal={onCloseModal}
      />
    </>
  );
};

export default UsersAccounts;
