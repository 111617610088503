// import React, { useEffect, useState } from "react";
// import useAgora from "./agoraConfig";
// import VideoPlayer from "./videoPlayer";

// import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import { IUserSelectorType } from "../../../interfaces";
// import { useNavigate } from "react-router-dom";
// import { checkWhoJoinedData } from "../../../redux/actions";
// import { ManageDates } from "../../dashboard";
// import { message } from "antd";

// const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

// interface IGuestData {
//   appointment_category: string;
//   appointment_type: string;
//   create_at: string;
//   first_gest_status: string;
//   first_name: string;
//   id: number | string;
//   inmate_code: string;
//   last_name: string;
//   second_gest_status: string;
//   visit_date: string;
//   visit_time: string;
//   visitor_first_name: string;
//   visitor_last_name: string;
// }

// const APP_ID = process.env.REACT_APP_API_AGORA_APP_ID || "";
// const TOKEN = process.env.REACT_APP_API_AGORA_APP_TOKEN || "";
// const CHANNEL = process.env.REACT_APP_API_AGORA_APP_CHANNEL || "";

// const VideoMeeting = () => {
//   const getDate = new ManageDates();
//   const currentTime = new Date();
//   const hours = currentTime.getHours();
//   const minutes = currentTime.getMinutes();
//   const time = `${hours}:${minutes}`;
//   const [useClient, useMicrophoneAndCameraTracks]: any = useAgora();
//   const [users, setUsers] = useState<any>([]);

//   const [localTracks, setLocalTracks] = useState<any>([]);
//   const [guestData, setGuestData] = useState<IGuestData>();
//   const client: any = useClient();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { ready, tracks } = useMicrophoneAndCameraTracks();

//   const { checkWhoJoined, checkVisitId } = userSelector((state) => state);

//   const handleUserJoined = async (user: any, mediaType: string) => {
//     await client.subscribe(user, mediaType);

//     if (mediaType === "video") {
//       setUsers((previousUsers: any) => [...previousUsers, user]);
//     }

//     if (mediaType === "audio") {
//       user.audioTrack.play();
//     }
//   };

//   const handleUserLeft = (user: { uid: any }) => {
//     setUsers((previousUsers: any[]) =>
//       previousUsers.filter((u: { uid: any }) => u.uid !== user.uid)
//     );
//   };

//   useEffect(() => {
//     if (!checkVisitId?.data || checkVisitId?.data.length === 0) {
//       navigate("/access-meeting");
//     } else {
//       dispatch(
//         checkWhoJoinedData({
//           visit_id: checkVisitId?.data?.data?.visit_id,
//           today: getDate.defaultEndDate(),
//           time: time,
//         })
//       );
//     }
//   }, [checkVisitId?.data]);

//   useEffect(() => {
//     if (checkWhoJoined?.data?.data?.error) {
//       message.error(checkWhoJoined?.data?.data?.message);
//     }

//     if (checkWhoJoined?.data?.data?.appointments) {
//       setGuestData(checkWhoJoined?.data?.data?.appointments);
//     }
//   }, [
//     checkWhoJoined?.data?.data?.error,
//     checkWhoJoined?.data?.data?.appointments,
//   ]);

//   useEffect(() => {
//     const init = async (name: string) => {
//       client.on("user-published", handleUserJoined);
//       client.on("user-left", handleUserLeft);

//       await client.join(APP_ID, name, TOKEN, null);
//       if (tracks) await client.publish(tracks);
//       setLocalTracks(tracks);
//     };

//     if (guestData) {
//       if (ready && tracks) {
//         console.log("init ready");
//         init(CHANNEL);
//       }
//     }

//     return () => {
//       for (const localTrack of localTracks) {
//         localTrack.stop();
//         localTrack.close();
//       }
//       client.off("user-published", handleUserJoined);
//       client.off("user-left", handleUserLeft);
//       // client.unpublish(tracks).then(() => client.leave());
//     };
//   }, [ready, tracks, guestData]);

//   return (
//     <div>
//       {ready && tracks && (
//         <VideoPlayer
//           tracks={tracks}
//           users={users}
//           guestData={guestData}
//           guest={checkVisitId?.data?.data?.guest}
//         />
//       )}
//     </div>
//   );
// };

// export default VideoMeeting;

import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import { useNavigate } from "react-router-dom";
import { ManageDates } from "../../dashboard";
import { message } from "antd";
import VideoCall from "./Compo/VideoCall";
import { checkWhoJoinedData } from "redux/actions";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface IGuestData {
  appointment_category: string;
  appointment_type: string;
  create_at: string;
  first_gest_status: string;
  first_name: string;
  id: number | string;
  inmate_code: string;
  last_name: string;
  second_gest_status: string;
  visit_date: string;
  visit_time: string;
  visitor_first_name: string;
  visitor_last_name: string;
}

const VideoMeeting: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getDate = new ManageDates();
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const time = `${hours}:${minutes}`;
  const [guestData, setGuestData] = useState<IGuestData>();

  const { checkWhoJoined, checkVisitId } = userSelector((state) => state);

  useEffect(() => {
    if (checkWhoJoined?.data?.data?.error) {
      message.error(checkWhoJoined?.data?.data?.message);
    }

    if (checkWhoJoined?.data?.data?.appointments) {
      setGuestData(checkWhoJoined?.data?.data?.appointments);
    }
  }, [
    checkWhoJoined?.data?.data?.error,
    checkWhoJoined?.data?.data?.appointments,
  ]);

  useEffect(() => {
    if (!checkVisitId?.data || checkVisitId?.data.length === 0) {
      navigate("/access-meeting");
    } else {
      dispatch(
        checkWhoJoinedData({
          visit_id: checkVisitId?.data?.data?.visit_id,
          today: getDate.defaultEndDate(),
          time: time,
        })
      );
    }
  }, [checkVisitId?.data]);

  return (
    <VideoCall
      guestData={guestData}
      // users={users}
      guest={checkVisitId?.data?.data?.guest}
    />
  );
};

export default VideoMeeting;
