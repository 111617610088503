/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, Flex, Modal, Table, Upload } from "antd";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { IUserSelectorType } from "interfaces";
import { UploadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  clearSaveProductData,
  getProductData,
  saveNewProductData,
} from "redux/actions";
import NoResultFound from "components/common/noResultFound";
import ExcelTemplateDownload from "components/downloadTemplate";
import { initialProductData } from "..";

const requiredColumns = [
  {
    title: "Item",
    width: 100,
    dataIndex: "item",
    key: "item",
    fixed: "left",
  },
  {
    title: "Supplier ID",
    width: 100,
    dataIndex: "supplier_id",
    key: "supplier_id",
  },
];

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const requiredColumnKeys = requiredColumns.map(
  (col: { dataIndex: any }) => col.dataIndex
);

const UploadProductFile = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  const { saveNewProduct } = userSelector((user) => user);

  const handleFileUpload = (file: File) => {
    const isXlsx =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isXlsx) {
      toast.error("You can only upload XLSX files!");
      return Upload.LIST_IGNORE;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target?.result;
      if (binaryStr) {
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (jsonData.length) {
          const headers: any = jsonData[0];
          const headerKeys = headers.map((header: string) =>
            header.toLowerCase().replace(" ", "_")
          );
          const isValid = requiredColumnKeys.every((key: any) =>
            headerKeys.includes(key)
          );

          if (!isValid) {
            toast.error(
              "The uploaded file does not contain the required columns: Product Item, Supplier ID."
            );
            return Upload.LIST_IGNORE;
          }

          const tableColumns = headers.map((header: string) => ({
            title: header,
            dataIndex: header.toLowerCase().replace(" ", "_"),
            key: header.toLowerCase().replace(" ", "_"),
          }));

          const tableData = jsonData.slice(1).map((row: any, index: number) => {
            const rowData: { key: number; [key: string]: any } = { key: index };
            row.forEach((cell: any, cellIndex: number) => {
              rowData[headerKeys[cellIndex]] = cell;
            });
            return rowData;
          });

          setColumns(tableColumns);
          setData(tableData);
        }
      }
    };
    reader.readAsBinaryString(file);
    return false;
  };

  const handleSaveUploadedFile = () => {
    const saveProductWithIssues: { item: string; supplier: string }[] = [];

    const newProductList = data.map((item) => item).filter(Boolean);

    if (saveProductWithIssues.length > 0) {
      const issuesMessage = saveProductWithIssues
        .map((issue) => `${issue.item}: ${issue.supplier}`)
        .join("\n");
      toast.error(
        `The following uploaded products contains issues:\n${issuesMessage}`
      );
    }

    const itemData = newProductList;
    dispatch(saveNewProductData({ data: itemData }));
  };

  useEffect(() => {
    if (saveNewProduct.data?.success) {
      dispatch(clearSaveProductData());
      dispatch(getProductData(initialProductData));
      onClose();
      setData([]);
      setColumns([]);
    }

    if (saveNewProduct.error) {
      toast.error(saveNewProduct?.message);
      dispatch(clearSaveProductData());
    }
  }, [saveNewProduct.data, saveNewProduct?.error]);

  return (
    <Modal
      width={800}
      title={"Upload product"}
      open={open}
      onCancel={onClose}
      footer={[]}
    >
      <Flex style={{ width: "100%" }}>
        <ExcelTemplateDownload downloadUrl={"upload-products-excel-template"} />
      </Flex>
      <div className="display-row-items-space-bt" style={{ marginTop: "20px" }}>
        <Upload beforeUpload={handleFileUpload} showUploadList={false}>
          <Button ghost type="primary" icon={<UploadOutlined />}>
            Select File
          </Button>
        </Upload>
        {data.length ? (
          <Button
            type="primary"
            icon={<CloudUploadOutlined />}
            onClick={handleSaveUploadedFile}
            style={{ marginRight: "20px" }}
            loading={saveNewProduct.loading}
          >
            Submit
          </Button>
        ) : null}
      </div>

      <Divider style={{ margin: "8px" }} />
      <Table
        columns={columns}
        locale={{ emptyText: NoResultFound }}
        dataSource={data}
        size="small"
        pagination={{ pageSize: 50 }}
      />
    </Modal>
  );
};

export default UploadProductFile;
