import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Modal, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { sendMoneys } from '../../redux/actions';
import { IUserSelectorType } from '../../interfaces';
import { countryCode, telcoList } from '../../constants/telco';
import Translate from '../../languages/Translate';
import {
    hasOnlyDigits,
    isFirstTwoZero,
    isFirstTwoZeroZero,
    nameHasSpecialChar,
    numberStartWithSeven,
} from '../Appointment';
import { toast } from 'react-toastify';
import CountrySelector from 'components/CountrySelector';

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;
const { confirm: confirmModal } = Modal;

interface IForm {
    sender_phone_number: string;
    tariffAmount: string | number;
    inmate_code: string;
    inmate_name: string;
    sender_first_name: string;
    sender_last_name: string;
    telco: string;
}

const SendMoney = () => {
    const dispatch = useDispatch();
    const [selectedTelco, setSelectedTelco] = useState<string>('');
    const [selectedCountry, setSelectedCountry] = useState<any>(null);

    const {
        sendMoney: { loading, data },
    } = userSelector((user) => user);

    const [form, setForm] = useState<IForm>({
        sender_phone_number: '',
        tariffAmount: '',
        inmate_code: '',
        inmate_name: '',
        sender_first_name: '',
        sender_last_name: '',
        telco: '',
    });

    const onHandleChange = (e: any) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleCountrySelect = (country: { name: any; code: any }) => {
        setSelectedCountry(country);
    };

    const onSubmit = () => {
        if (
            !hasOnlyDigits(form.sender_phone_number) ||
            form.sender_phone_number.length < 9 ||
            form.sender_phone_number.length > 9 ||
            isFirstTwoZeroZero(form.sender_phone_number) ||
            isFirstTwoZero(form.sender_phone_number) ||
            !numberStartWithSeven(form?.sender_phone_number)
        ) {
            toast.error('Please provide a valid phone number');
        } else if (nameHasSpecialChar(form.sender_first_name)) {
            toast.error('The provided first name has some special characters.');
        } else if (nameHasSpecialChar(form.sender_last_name)) {
            toast.error('The provided last name has some special characters.');
        } else {
            const userData = {
                sender_phone_number: form.sender_phone_number,
                tariffAmount: form.tariffAmount,
                inmate_code: form.inmate_code,
                inmate_name: form.inmate_name,
                sender_first_name: form.sender_first_name,
                sender_last_name: form.sender_last_name,
                telco: selectedTelco,
                user_origin: selectedCountry?.code,
            };

            dispatch(sendMoneys(userData));
        }
    };

    const handleChangeTelco = (value: string) => {
        setSelectedTelco(value);
    };

    const confirm = () => {
        confirmModal({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to proceed with sending money?',
            okText: 'Yes',
            cancelText: 'Cancel',
            onOk() {
                onSubmit();
            },
        });
    };

    // onSubmit()
    useEffect(() => {
        if (data?.data === null) {
            toast.error(data?.message);
        }

        if (data?.data) {
            toast.success(data?.message);
            setForm({
                sender_phone_number: '',
                tariffAmount: '',
                inmate_code: '',
                inmate_name: '',
                sender_first_name: '',
                sender_last_name: '',
                telco: selectedTelco,
            });
        }

        if (data?.data?.failed) {
            toast.error(data?.data?.message);
        }
    }, [data]);

    return (
        <>
            <div className="container-box__items">
                <div className="container-box__items-middle">
                    <div className="appoint-item__contents" style={{ background: 'transparent' }}>
                        <div className="widget-options-form" style={{ margin: '5% auto', width: '100%' }}>
                            <span style={{ fontSize: '18px', fontWeight: '600' }}>{Translate('Send money')}</span>
                            <br />
                            <br />
                            <div className="appoint-item__content-top">
                                <Link to="/">{Translate('Go back')}</Link>
                                <span></span>
                            </div>
                            <CountrySelector onSelectCountry={handleCountrySelect} selectedCountry={selectedCountry} />
                            <div className="widget-options-form__items">
                                <span style={{ color: '#333' }}>
                                    {Translate('Inmate code or parents names')}
                                    {'*'}
                                </span>
                                <Input
                                    name="inmate_code"
                                    value={form.inmate_code}
                                    placeholder={Translate('Enter inmate code or parents names')}
                                    onChange={onHandleChange}
                                />
                            </div>
                            <div className="widget-options-form__items">
                                <span style={{ color: '#333' }}>
                                    {Translate('Inmate first name or last name')}
                                    {'*'}
                                </span>
                                <Input
                                    name="inmate_name"
                                    value={form.inmate_name}
                                    placeholder={Translate('Enter inmate first name or last name')}
                                    onChange={onHandleChange}
                                />
                            </div>
                            <div className="widget-options-form__items">
                                <span style={{ color: '#333' }}>
                                    {Translate('Your name')}
                                    {'*'}
                                </span>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Input
                                        name="sender_first_name"
                                        value={form.sender_first_name}
                                        placeholder={Translate('Enter your first name')}
                                        onChange={onHandleChange}
                                        style={{
                                            width: '50%',
                                        }}
                                    />
                                    <Input
                                        name="sender_last_name"
                                        value={form.sender_last_name}
                                        placeholder={Translate('Enter your last name')}
                                        onChange={onHandleChange}
                                        style={{
                                            width: '50%',
                                            marginLeft: '5px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="widget-options-form__items">
                                <span style={{ color: '#333' }}>
                                    {Translate('Phone number')}
                                    {'*'}
                                </span>
                                <Input
                                    addonBefore={countryCode}
                                    name="sender_phone_number"
                                    value={form.sender_phone_number}
                                    placeholder={Translate('78xxxxxxxxx')}
                                    onChange={onHandleChange}
                                />
                            </div>
                            <div className="widget-options-form__items">
                                <span style={{ color: '#333' }}>
                                    {Translate('Amount')}
                                    {'*'}
                                </span>
                                <Input
                                    name="tariffAmount"
                                    value={form.tariffAmount}
                                    placeholder={Translate('Enter amount')}
                                    onChange={onHandleChange}
                                />
                            </div>

                            <div className="widget-options-form__items">
                                <span style={{ color: '#333' }}>
                                    {Translate('Telco')}
                                    {'*'}
                                </span>
                                <Select
                                    allowClear
                                    style={{ marginRight: '5px' }}
                                    placeholder={Translate('Select mobile operator')}
                                    onChange={handleChangeTelco}
                                    options={telcoList}
                                    size="large"
                                />
                            </div>
                            <div className="widget-options-form__items">
                                <Button
                                    disabled={
                                        form.inmate_code === '' ||
                                        form.inmate_name === '' ||
                                        form.sender_first_name === '' ||
                                        form.sender_last_name === '' ||
                                        form.tariffAmount === '' ||
                                        form.tariffAmount === 0 ||
                                        form.sender_phone_number === '' ||
                                        selectedTelco === '' ||
                                        !selectedCountry
                                            ? true
                                            : false
                                    }
                                    loading={loading}
                                    onClick={confirm}
                                    type="primary"
                                >
                                    {Translate('Send')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SendMoney;
